// IMPORTS -------------------------------------------------------
    import React, { useEffect, useRef } from 'react';
    // import RestService from '../services/RestService';
    import { 
        T_FX_VERTICAL_ALIGN, 
        T_FX_HORIZONTAL_ALIGN, 
        T_CSS_HORIZONTAL_ALIGN, 
        T_CSS_VERTICAL_ALIGN, 
        T_FX_ACCORDION_DATA
    } from '../utils/FxTypes';
    import { 
        fxGetCSSVerticalAlign, 
        fxGetCSSHorizontalAlign 
    } from '../utils/FxUtils';

    // From MUI Accordion example
    import { styled } from '@mui/material/styles';
    import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
    import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
    import MuiAccordionSummary, {
    AccordionSummaryProps,
    } from '@mui/material/AccordionSummary';
    import MuiAccordionDetails from '@mui/material/AccordionDetails';
    import Typography from '@mui/material/Typography';
    import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Property } from 'csstype';
// ---------------------------------------------------------------------------

/**
 * Replace 'Accordion' for the 'AccordionName', case sensitive
 */

// TYPES (avoid!!! use FxTypes.ts instead!!!------------------------------------------------------
    // type T_NEW_TYPE = {
    
    // }
// ---------------------------------------------------------------------------

    const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({

        backgroundColor: 'rgba(0,0,0,0)',

        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
    ))(({ theme }) => ({
        backgroundColor: //'rgba(0,0,0,1)',
            theme.palette.mode === 'dark'
            // ? 'rgba(255, 255, 255, .6)'
            ? 'rgba(0, 0, 0, .4)'
            : 'rgba(200, 200, 200, .6)',
        flexDirection: 'row-reverse', // FX: disable this for arrow at the beggining
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    // REF: 
    //  https://stackoverflow.com/questions/59921880/media-queries-in-material-ui-using-styled-components
    //  https://mui.com/system/getting-started/usage/ (check "media queries")
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',

        color: 'lightgray',

        // FX: responsive bullets gutter
        // TODO: use "rem" units?
        [theme.breakpoints.up("xs")]: {
            paddingLeft: '10vw',
        },
        [theme.breakpoints.up("sm")]: {
            paddingLeft: '10vw',
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: '4vw',
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: '2vw',
        },
        [theme.breakpoints.up("xl")]: {
            paddingLeft: '2vw',
        },
    }));

// COMPONENT INTERFACE -------------------------------------------------------
    interface IAccordionProps {
        id?: string,
        children?: React.ReactNode,
        height?: string|number,
        width?: string|number,
        debug?: boolean,
        vAlign?: T_FX_VERTICAL_ALIGN,
        hAlign?: T_FX_HORIZONTAL_ALIGN,
        // event01: ()=>void,

        data?: T_FX_ACCORDION_DATA[],
        snapScrollActive?:(active:boolean)=>void,
    }
// ---------------------------------------------------------------------------

    const TEST_DATA: T_FX_ACCORDION_DATA[] = [
        {
            summary: 'Collapsible Group Item #1',
            details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse \
                        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor \
                        sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, \
                        sit amet blandit leo lobortis eget.'
        },
        {
            summary: 'Collapsible Group Item #2',
            details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse \
                        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor \
                        sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, \
                        sit amet blandit leo lobortis eget.'
        },
        {
            summary: 'Collapsible Group Item #3',
            details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse \
                        malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor \
                        sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, \
                        sit amet blandit leo lobortis eget.'
        },
    ];

// COMPONENT DEFINITION -------------------------------------------------------
    const FxAccordion = (props: IAccordionProps)=>{
        // console.log("FxAccordion: entering: props => ", props);

        // CONSTANTS AND DEFAULTS ------------------------------------------------------------
            // const constant01 = 1010;
        // ---------------------------------------------------------------------------
        
        // PROPERTIES PRE-PROCESSING ---------------------------------------
            // => INITS, DEFAULTS, VALIDATION AND SANITIZATION
            const id_: string=!!props.id?props.id:"fx-accordion"
            const debug_: boolean = !!props.debug?props.debug:false;
            const height_: string|number = !!props.height?props.height:'100%';
            const width_: string|number = !!props.width?props.width:'100%';
            const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
            const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
            const data_ = !!props.data?props.data:TEST_DATA;
        // ---------------------------------------------------------------------------

        // STATE VARIABLES -----------------------------------------------------------
            const [expanded, setExpanded] = React.useState<string | false>(false);
            const [currentPanel, setCurrentPanel] = React.useState<string>('');
            const oldPosition = useRef<number>();
            const newPosition = useRef<number>();
            const lastPanelExpanded = useRef<string>('');
        // ---------------------------------------------------------------------------

        // CONTROL FUNCTIONS ---------------------------------------------------------
            //
                // const scrollToAccordionPanel_1 = (panelId: string)=>{
                //             // const panelEl = document.querySelector(`#${panelId}`);
                //             // const panelEl = document.querySelector(`#fx-accordion`);
                //             const panelEl = document.querySelector(`#about-container`);
                //             // const panelEl = document.querySelector(`#${panelId}-details`);
                //             console.log(`panel => ${panelId}`, panelEl);
                //             if (!!panelEl)
                //                 // panelEl.scrollIntoView(false
                //                 panelEl.scrollIntoView(
                //                     {
                //                         behavior: "smooth",
                //                         block: "start",
                //                         // block: "nearest",
                //                         // block: "end",
                //                         // inline: "start"
                //                         // inline: "nearest"
                //                     }
                //                 ); // initiate scroll to the "AddMock" Accordion component
                // }
                // const scrollToAccordionPanel_2 = (event: React.SyntheticEvent)=>{
                //     //@ts-ignore
                //     event.target.scrollIntoView(
                //         {
                //             behavior: 'smooth', 
                //             block: 'start',
                //             // block: "end",
                //             // block: "nearest",
                //         }
                //     )
                //     // event.target.scrollIntoView()
                // }
                // const scrollToAccordionPanel_3 = (panelId: string)=>{
                //             // const panelEl = document.querySelector(`#${panel}`);
                //             const panelEl = document.querySelector(`#fx-accordion`);
                //             // const panelEl = document.querySelector(`#${panelId}-details`);
                //             console.log(`panel => ${panelId}`, panelEl);
                //             if (!!panelEl)
                //                 // panelEl.scrollIntoView(false
                //                 panelEl.scrollTo(100,100);
                // }
                // const scrollToAccordionPanel_4 = ()=>{

                //     window.scrollTo(200,200);

                // }
                // const scrollToAccordionPanel_5 = ()=>{                    
                //     const panelEl = document.querySelector(`#about-container`);

                //     panelEl?.scrollTo(0,-200);

                // }
        const scrollTo = (position: number)=>{
            const swPageEl = document.querySelector(`#fx-software-page`);
            // const swPageEl = document.querySelector(`#about-text-container-scrollable`);
            swPageEl?.scrollTo//(0,newPosition.current);
                ({
                    top: position,
                    // left: 100,
                    behavior: "smooth",
                })
        }

        const scrollToAccordionPanel_6 = ()=>{    
            // Scroll element and position          
            const swPageEl = document.querySelector(`#fx-software-page`);
            // const swPageEl = document.querySelector(`#about-text-container-scrollable`);
            // console.log(swPageEl)
            const swPageScrollTop = swPageEl?.scrollTop;

            // Accordion panels
            const currentPanelEl = document.querySelector(`#${currentPanel}-details`);
            const currentPanelHeight = currentPanelEl?.getClientRects()[0].height;
            console.log(`currentPanel => ${currentPanel} / ${currentPanelHeight}px`);
            console.log(`lastPanelExpanded.current => ${lastPanelExpanded.current}`);

            // FX: disable snap scroll because it fucks programatic scrolling
            // if (!!props.snapScrollActive) props.snapScrollActive(false);

            // Hah espaco abaixo do accordion para expandir?
            // nao calculado ainda.. Default serah mover.
            if (expanded){
                // Is this a different panel? 
                if ((currentPanel == lastPanelExpanded.current)||(lastPanelExpanded.current == '')){
                    // Reset the previous offset...
                    console.log(`SAME PANEL`);

                    // Go to new scroll offset ------------------------------------------------
                    oldPosition.current = swPageScrollTop;
                    console.log(`oldPosition.current => ${oldPosition.current}`);

                    newPosition.current = swPageScrollTop! + currentPanelHeight!;
                } else {
                    console.log(`DIFFERENT PANEL`);

                    // Return to closed scroll offset
                    scrollTo(oldPosition.current!);

                    // Go to new scroll offset ------------------------------------------------
                    newPosition.current = oldPosition.current! + currentPanelHeight!;
                }
                console.log(`newPosition.current => ${newPosition.current}`);
                scrollTo(newPosition.current);

                lastPanelExpanded.current = currentPanel;
                console.log(`lastPanelExpanded.current => ${lastPanelExpanded.current}`);

                // Re-enable after scrolling
                // if (!!props.snapScrollActive) props.snapScrollActive(true);
            } else {
                console.log(`closed...`);
                scrollTo(oldPosition.current!)
            }
        }

        const handleChange =
            (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
                event.preventDefault();
                console.log(`${panel} is open: ${newExpanded}`);

                setExpanded(newExpanded ? panel : false);
                setCurrentPanel(panel);

                if (newExpanded){
                    // console.log(event);
                    if (!!props.snapScrollActive) props.snapScrollActive(false);
                }
            };
        // ---------------------------------------------------------------------------

        // EVENTS CONTROL -----------------------------------------------------------
            useEffect(() => {
                // console.log("FxAccordion: entering: useEffect");
                scrollToAccordionPanel_6();
                // if (!!props.snapScrollActive) props.snapScrollActive(true);

            }, [expanded]);
        // ---------------------------------------------------------------------------

        // STYLES -------------------------------------------------------------------
        // ---------------------------------------------------------------------------

        // SUB COMPONENTS ---------------------------------------------------------
        // ---------------------------------------------------------------------------

        // RENDER --------------------------------------------------------------------
            return (
                <div 
                    id={id_}
                    // ref={accordElem}
                    style={{
                        display:        "flex", // if "block", justifyContent will not work!! 
                        background:     debug_?'blue':'initial', // apenas para debugging
                        border:         debug_?'dashed red 2px':'initial',
                        height:         height_,
                        width:          width_, 
                        justifyContent: cssHAlign,               // horizontal align
                        alignItems:     cssVAlign,                   // vertical align
                    }}
                >


                    <div>
                        {data_.map((item, index)=>
                            <Accordion id={`panel${index}`}
                                key={`fx-accordion-${index}`}
                                expanded={expanded === `panel${index}`} 
                                onChange={
                                    handleChange(`panel${index}`)
                                }
                                style={{
                                    // background: 'red',
                                }}

                                // ref={(el) => {
                                //     if (el) {
                                //         scrollToRef.current[id] = el
                                //     }
                                // }}
                            >

                                <AccordionSummary id={`panel${index}-header`}
                                    aria-controls={`panel${index}-content`} 
                                    // expandIcon={<ArrowDropDownIcon />} // styled
                                >
                                    {/* <Typography 
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    > */}
                                        {item.summary}
                                    {/* </Typography> */}
                                </AccordionSummary>

                                <AccordionDetails id={`panel${index}-details`}>
                                    {/* <Typography> */}
                                        {item.details}
                                    {/* </Typography> */}
                                </AccordionDetails>

                            </Accordion>
                        )}



                    {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Collapsible Group Item #1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Collapsible Group Item #2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Collapsible Group Item #3</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion> */}
                    </div>


                </div>
            )
        // ---------------------------------------------------------------------------
    }
// ---------------------------------------------------------------------------

// EXPORTS -------------------------------------------------------
    export default FxAccordion;
// ---------------------------------------------------------------------------
