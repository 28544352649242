import React, { useEffect } from 'react';
// import RestService from '../services/RestService';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN } from '../fxlib-react/src/utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../fxlib-react/src/utils/FxUtils';

/**
 * Replace 'FxNoMatchPage' for the 'FxNoMatchPageName', case sensitive
 */

interface IFxNoMatchPageProps {
    id?: string,
    children?: React.ReactNode,
    height?: string|number,
    width?: string|number,
    debug?: boolean,
    vAlign?: T_FX_VERTICAL_ALIGN;
    hAlign?: T_FX_HORIZONTAL_ALIGN;
    // event01: ()=>void,
}

const FxNoMatchPage = (props: IFxNoMatchPageProps)=>{
    console.log("FxNoMatchPage: entering: props => ", props);
    const debug_: boolean = !!props.debug?props.debug:false;
    const height_: string|number = !!props.height?props.height:'100%';
    const width_: string|number = !!props.width?props.width:'100%';
    const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
    const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';

    // const [state01, setState01] = useState<string>('');
    // const [someData, setSomeData] = useState<string>('');

    // useEffect(() => {
    //     console.log("FxNoMatchPage: entering: useEffect");
    // }, []);

    // const getSomeData = () => {
        // RestService.getSomeData()
        // .then(response => {
        //     setSomeData(response.data);
        //     console.log(response.data);
        // })
        // .catch(e => {
        //     console.log(e);
        // });
    // };

    return (
        <div 
            id={props.id}
            style={{
                background:     debug_?'blue':'initial', // apenas para debugging
                border:         debug_?'dashed red 2px':'initial',
                height:         height_,
                width:          width_, 
                justifyContent: cssHAlign,               // horizontal align
                alignItems:     cssVAlign,                   // vertical align
                textAlign:      'center',
                display:        'flex',
            }}
        >
            <h1>FxNoMatchPage!!</h1>
            {props.children}
        </div>
    )
}

export default FxNoMatchPage;
