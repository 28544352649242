import { FX_HTTP } from "./FxDbConnection";
//** The Axios response object consists of:
//** => data: the payload returned from the server
//** => status: the HTTP code returned from the server
//** => statusText: the HTTP status message returned by the server
//** => headers: headers sent by server
//** => config: the original request configuration
//** => request: the request object


// FX_HTTP => axios instance for the API
const getBarterTable = () => {
  return FX_HTTP.get("/barter/getTable");
};

const login = (email_:string, password_: string) => {
    const data = {
        email: email_,
        password: password_
    }
    return FX_HTTP.post("/user/login", data);
};

const register = (registerData_: any) => {
    return FX_HTTP.post("/user/register", registerData_);
};


// const getAll = () => {
//   return FX_HTTP.get("/tutorials");
// };

// const get = (id:string) => {
//   return FX_HTTP.get(`/tutorials/${id}`);
// };

// const create = (data:any) => {
//   return FX_HTTP.post("/tutorials", data);
// };

// const update = (id:string, data:any) => {
//   return FX_HTTP.put(`/tutorials/${id}`, data);
// };

// const remove = (id:string) => {
//   return FX_HTTP.delete(`/tutorials/${id}`);
// };

// const removeAll = () => {
//   return FX_HTTP.delete(`/tutorials`);
// };

// const findByTitle = (title:string) => {
//   return FX_HTTP.get(`/tutorials?title=${title}`);
// };

export default {
    getBarterTable,
    login,
    register,
    // getAll,
    // get,
    // create,
    // update,
    // remove,
    // removeAll,
    // findByTitle
};