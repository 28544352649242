import { fxRecall } from "../utils/FxUtils";
import locales from "./assets/data/fx-locales.json";

// type T_KEY_VALUE = {[key: string] : string}
// type T_KEY_VALUE = {[key: string] : Object}
//     var condition: {[key: string] : {}} = {};

export const getCurrentLangCode = ()=>{
    const lang = fxRecall("language");
    return lang;
}

const textObj: {[key: string] : {}} = locales;
const translate = (textId: string, langCode: string)=>{
    const translations: {[key: string] : string} = textObj[textId];
    const translation = !!langCode?translations[langCode]:"";

    return translation;
}

export const fxtr = (textId: string)=>{

    //Get current language from storage
    const lang = getCurrentLangCode();
    const translation = !!lang?translate(textId, lang):textId;

    return translation;
}

// FX: prefix: common text IDs radical to filter.
    // [ "fx-autocomplete-opt01", 
    //   {
    //     "en-us": "Develop some software...",
    //     "pt-br": "Desenvolver software..."
    //   }
    // ],
    // "fx-autocomplete-opt02":{
    //     "en-us": "Develop a web site...",
    //     "pt-br": "Desenvolver software..."
    // },
    // "fx-autocomplete-opt03":{
    //     "en-us": "Get some tips about business...",
    //     "pt-br": "Obter uns pitacos sobre negócios..."
    // },
export const fxtrList = (prefix: string)=>{
    const lang = getCurrentLangCode();
    const textList: string[] = [];
    if (!!!lang) return textList;

    for (const prop in textObj) {
        if (prop.startsWith(prefix)) textList.push(translate(prop, lang));
        //console.log(`obj.${prop} = ${textObj[prop]}`);
    }
    // console.log(`textList => `, textList);
    return textList;
}

export const getLanguageNames = ()=>{
    const langs = locales.languages;
    const names = langs.map((lang)=>lang.name);

    return names;
}

export const getLanguageNameFromCode = (code: string)=>{
    const langs = locales.languages;
    const langFromCode = langs.filter((lang)=>lang.code==code);

    return langFromCode[0].name;
}

export const getOtherLanguageNames = (code?: string)=>{
    const allLangNames = getLanguageNames();
    // console.log(`langs => `, langs);

    // const currentLangCode = fxRecall("language");
    // const currentLangCode = code;
    // console.log(`currentLang => `, currentLangCode); // pt-br

    if (!!code){
        const currentLangName = getLanguageNameFromCode(code);
        // console.log(`currentLangName => `, currentLangName); // 
    
        const otherLanguageNames = allLangNames.filter(lang=>lang!=currentLangName);
        // console.log(`otherLanguages => `, otherLanguages);

        return otherLanguageNames;
        // const index = langs.indexOf(currentLang);
        // if (index>=0)
        // langs.splice(index, 1);
        // if (langs.splice())
    }
    return [];
}
