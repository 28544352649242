// import { BorderAll } from '@mui/icons-material';
import { 
    Autocomplete, 
    createFilterOptions, 
    // Box, 
    // Container, 
    // Stack, 
    styled, 
    TextField, 
    useTheme
} from '@mui/material';
// import { borderColor } from '@mui/system';
import React, { useEffect } from 'react';
// import RestService from '../services/RestService';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN } from '../utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../utils/FxUtils';
import { useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';
import FxTyped from './FxTyped';
import { fxtr } from '../services/FxPolyglot.service';

/**
 * Replace 'Autocomplete' for the 'ComponentName', case sensitive
 */

interface IAutocompleteProps {
    id?: string;
    vAlign?: T_FX_VERTICAL_ALIGN;
    hAlign?: T_FX_HORIZONTAL_ALIGN;
    width?: string|number;
    height?: string|number;
    debug?: boolean;
    padding?: string | number;
    paddingTop?: string | number;
    paddingBottom?: string | number;
    paddingLeft?: string | number;
    paddingRight?: string | number;
    margin?: string | number;
    marginTop?: string | number;
    marginBottom?: string | number;
    marginLeft?: string | number;
    marginRight?: string | number;
    // event01: ()=>void;
    options?: string[];
    shadow?: string;
    shadowOffsetX?: number;
    shadowoffsetY?: number;
    shadowBlur?: number;
    shadowSpread?: number;
    shadowColor?: string;
    borderRadius?: string;
    borderWidth?: string;
    style?: {};
    onChange?: (selectedValue:string)=>void;
    // defaultInputValue?: string;
    defaultSelectedValue?: string;
    clearOnChange?: boolean;
    cssRef?: React.MutableRefObject<null>;
    visibility?: "visible" | "hidden" | "collapse" | undefined;
    position?: any;
}

const FxAutocomplete = (props: IAutocompleteProps) => {
    // console.log('FxAutocomplete: entering: props => ', props);
    // const theme = useTheme();

    const debug_:boolean                    = !!props.debug?props.debug:false;
    const cssVAlign:T_CSS_VERTICAL_ALIGN    = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
    const cssHAlign:T_CSS_HORIZONTAL_ALIGN  = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
    const width_: string | number           = !!props.width?props.width:'100%';
    const height_: string | number          = !!props.height?props.height:'100%';
    const options_: string[]                = !!props.options?props.options:['Opção 01', 'Opção 02'];
    const clearOnChange_: boolean           = !!props.clearOnChange?props.clearOnChange:false;
    const defaultSelectedValue_: string     = !!props.defaultSelectedValue?props.defaultSelectedValue:'';
    // const options = [
    //     { title: 'The Shawshank Redemption', year: 1994 },
    //     { title: 'The Godfather', year: 1972 },
    //     { title: 'The Godfather: Part II', year: 1974 },
    //     { title: 'The Dark Knight', year: 2008 },
    // ];


  const [selectedValue, setSelectedValue] = React.useState<string | undefined>('');
  const [inputValue, setInputValue] = React.useState('');

    const OPTIONS_LIMIT = 5;
    const defaultFilterOptions = createFilterOptions<string>();

    const filterOptions = (options:any, state:any) => {
        return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    };

    // useEffect(() => {
    //     if ((props.inputValue!=null)&&(props.inputValue!=undefined)) setInputValue(props.inputValue);
    // }, [inputValue]);

    return (
        <div
            id={`${props.id}-mui-autocomplete-container`}
            style={{
                display:        'flex',
                // position:       'static',
                // position:       'absolute',
                position: props.position,
                background:     debug_ ? 'red' : 'inherit', // apenas para debugging
                // padding:        debug_? '10px' : props.padding, // apenas para debugging
                // border:         debug_?'dashed black 1px':'initial',
                // border:         'solid black 1px',
                borderRadius:   '15px',
                height:         height_, 
                // height: '100%',
                width:          width_,
                // justifyContent: cssHAlign, // horizontal align
                // alignItems:     cssVAlign, // vertical align
                padding: '0px', 
                margin: '0px', 
                justifyContent: 'center',
                alignItems:     'center',
                // textAlign:      'center',
                // paddingTop:     props.paddingTop,
                // paddingBottom:  props.paddingBottom,
                // paddingLeft:    props.paddingLeft,
                // paddingRight:   props.paddingRight,
                // margin:         props.margin,
                // marginTop:      props.marginTop,
                // marginBottom:   props.marginBottom,
                // marginLeft:     props.marginLeft,
                // marginRight:    props.marginRight,
                // maxWidth:       '600px',
                visibility:     props.visibility,
            } 
            // && props.style
            } 
        >
            <Autocomplete
                // id={props.id}
                id={`${props.id}-mui-autocomplete`}
                ref={props.cssRef}
                freeSolo
                disableClearable
                fullWidth={true}
                filterOptions={filterOptions}
                // options={options.map((option) => option.title)}
                options={options_}
                // autoHighlight={true}

                // Value SELECTED by the user, for instance when pressing [Enter].
                value={selectedValue}
                onChange={(event: any, newValue: string | undefined) => {
                    // // setSelectedValue(newValue);
                    // console.log(`FxAutocomplete: Autocomplete: onChange: new selectedValue => ${newValue}`);
                    if(clearOnChange_) setInputValue('');
                    // FxSite: causes route update
                    if ((!!props.onChange)&&(!!newValue)) props.onChange(newValue);

                }}
                // Value TYPED by the user in the textbox.
                inputValue={inputValue}
                blurOnSelect={true} // xxxxxx
                onInputChange={(event: any, newInputValue: string) => {
                    setInputValue(newInputValue);
                    console.log(`FxAutocomplete: Autocomplete: onInputChange: newInputValue => ${newInputValue}`);
                    // if (!!props.onChange) props.onChange(newInputValue);
                }}

                style={{
                    display:        'flex',
                    // background:     debug_?'magenta':'initial', // apenas para debugging
                    // padding:        debug_?'10px':'initial',       // apenas para debugging
                    // border:         debug_?'dashed black 1px':undefined,
                    // boxShadow:      props.shadow?props.shadow:'none',
                    // borderRadius:   props.borderRadius?props.borderRadius:'0px',
                    // borderWidth:    props.borderWidth?props.borderWidth:'0px',
                    // height:         height_,
                    height:         '100%',
                    // width:          '100%',
                    transformOrigin: 'top left',
                    // border: 'solid black 1px',
                    // borderRadius: '15px',
                    // borderWidth: '0px',
                    // marginTop: '3%',
                    // marginBottom: '3%',
                    margin: props.margin,

                    justifyContent: 'center',
                    alignItems:     'center',
                    // visibility:     props.visibility,
                    // visibility:     'var(--body-autocomplete-visibility)',
                    // height: '60%'
                }}  
                renderInput={(params) => (
                    // <ValidationTextField
                    //     label='Como posso ser útil?'
                    //     // required
                    //     // variant='outlined'
                    //     // defaultValue='Success'
                    //     id='validation-outlined-input'
                    //     InputProps={{
                    //         // ...params.InputProps,
                    //         type: 'search',
                    //     }}
                    //     fullWidth
                    //     variant="outlined"
                    //     //           required

                    // />


                    // !started?<FxTyped />:

                    <TextField
                        {...params}
                        variant="standard"
                        // maxRows={5}
                        // rows={5}
                        // label='Busque o tema aqui...'
                        label={fxtr('fx-autocomplete-label')}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            style: {
                                // background: 'green',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                            },
                            // sx: {
                            //     color: 'red',
                            //     '& .MuiFormLabel.MuiInputLabel.Mui-focused': {
                            //                 color: 'green',
                            //             },
                            // },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            style: {
                                // ::Box de busca fechado
                                background: debug_?'blue':'transparent', // apenas para debugging
                                // borderRadius: '15px',
                                // border: theme.palette.mode=='light'?'none':'solid white 1px',
                                // boxShadow: theme.palette.mode=='light'?'5px 10px 14px 0px rgba(0, 0, 0, 0.5)':'none',
                            },
                            // sx: {
                            //     '&focus': {
                            //         // borderColor: 'green',
                            //         // borderWidth: '0px',
                            //         // border: 'none',
                            //     border: 'solid white 1px',
                            //     },
                            // },
                        }}
                        sx={{
                            // TODO: manter sem borda, mesmo quando ganha foco
                            // :: Caixa interna da busca
                            // FX: opcao de border/shadow (INTERNO)
                            '& input': {
                                // borderRadius: '15px',
                                // border: theme.palette.mode=='light'?'none':'solid white 1px',
                                // border: 'solid black 1px',
                                // boxShadow: theme.palette.mode=='light'?'5px 10px 14px 0px rgba(0, 0, 0, 0.5)':'none',
                                // boxShadow: '5px 10px 14px 0px rgba(255, 255, 255, 0.5)',
                                // background: 'white',
                                // background: 'rgb(255 255 255 / 85%)',
                            },
                            '& input:valid + fieldset': {
                                border: 'none',
                            },
                            '& input:invalid + fieldset': {
                                border: 'none',
                            },
                            '& input:focus': {
                                // border: theme.palette.mode=='light'?'none':'solid white 1px',
                            },
                            '& fieldset': {
                                // ::Box de busca fechado
                                // borderRadius: '20px',
                                // border: 'solid black 1px',
                                // background: 'Gainsboro',
                                // boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                            },
                            // '&$focused': {
                            //     borderWidth: '0px',
                            // },
                            // borderWidth: '10px',
                        }}
                    />
                )}
            />
        </div>
    );
};

export default FxAutocomplete;
