// IMPORTS -------------------------------------------------------
    import { Box, Button, Collapse, Grid, TextField } from '@mui/material';
    import React, { useEffect, useRef, useState } from 'react';
    import FxFileUpload from '../fxlib-react/src/components/FxFileUpload';
    // import FxPageContainer from '../fxlib-react/src/components/FxOutletContainer';
    import FxRating from '../fxlib-react/src/components/FxRating';
    import FxTagList from '../fxlib-react/src/components/FxTagList';
    // import RestService from '../services/RestService';
    import { 
        T_FX_VERTICAL_ALIGN, 
        T_FX_HORIZONTAL_ALIGN, 
        T_CSS_HORIZONTAL_ALIGN, 
        T_CSS_VERTICAL_ALIGN, 
        T_IMAGE_UPLOAD,
        T_IMAGE_METADATA,
        T_FILE_METADATA,
        T_FILTER_KEY,
        T_DATA_FILTER
    } from '../fxlib-react/src/utils/FxTypes';
    import { 
        fxGetCSSVerticalAlign, 
        fxGetCSSHorizontalAlign, 
    } from '../fxlib-react/src/utils/FxUtils';
    import FxFileUploadDefaultImage from './boring01.jpg';
    import FxMasonry from '../fxlib-react/src/components/FxMasonry';
    import { FX_HTTP, fxFetchData } from '../fxlib-react/src/services/FxDbConnection';
    import { T_SPEEDDIAL_ACTION } from '../fxlib-react/src/components/FxSpeedDial';
    import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
    import SaveIcon from '@mui/icons-material/Save';
    import PrintIcon from '@mui/icons-material/Print';
    import ShareIcon from '@mui/icons-material/Share';
    import AddIcon from '@mui/icons-material/Add';
    import SearchIcon from '@mui/icons-material/Search';
    import FxGrid from '../fxlib-react/src/components/FxGrid';
    import FxColumn from '../fxlib-react/src/components/FxGridColumn';
    import FxRow from '../fxlib-react/src/components/FxRow';
    import { usePageContext } from './FxOutletContainer';
    import FxDataFilterGrid from '../fxlib-react/src/components/FxDataFilterGrid';
    import { IDataFilter } from '../fxlib-react/src/components/FxDataFilter';
// ---------------------------------------------------------------------------

/**
 * 
 */

// COMPONENT INTERFACE -------------------------------------------------------
    interface IGalleryPageProps {
        id?: string,
        children?: React.ReactNode,
        height?: string|number,
        width?: string|number,
        debug?: boolean,
        vAlign?: T_FX_VERTICAL_ALIGN;
        hAlign?: T_FX_HORIZONTAL_ALIGN;
        // event01: ()=>void,
        imagesUrl?: string
        apiUrl?: string
    }
// ---------------------------------------------------------------------------

// COMPONENT DEFINITION -------------------------------------------------------
    const FxGalleryPage = (props: IGalleryPageProps)=>{
        // console.log("FxGalleryPage: entering: props => ", props);

        // CONSTANTS AND DEFAULTS ------------------------------------------------------------
            // const formDefaultValues = {
            //     title: "fxfxfx",
            //     description: "fx_descr",
            //     author: "fx_auth",
            //     placeName: "fx_place",
            //     tags: [],
            //     rating: 5
            // }
        // ---------------------------------------------------------------------------

        const debug_: boolean = !!props.debug?props.debug:false;
        const height_: string|number = !!props.height?props.height:'100%';
        const width_: string|number = !!props.width?props.width:'100%';
        const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
        const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
        const imagesUrl_ = props.imagesUrl?props.imagesUrl:"lixo";
        // console.log(`FxGalleryPage: imagesUrl_ => `, imagesUrl_);
        const apiUrl_ = props.apiUrl?props.apiUrl:"lixo";
        // console.log(`FxGalleryPage: apiUrl_ => `, apiUrl_);

        const labels: { [index: string]: string } = {
            1: 'Save???',
            2: 'Archive',
            3: 'Ok',
            4: 'Selection',
            5: 'Pro Selection',
        };
        const context = usePageContext();

        // CONTROL FUNCTIONS ---------------------------------------------------------
            const toggleOpenImageUploadArea = ()=>{
                // console.log(`FxGalleryPage: toggleOpenImageUploadArea: setting openImageUploadAreaRef.current => `, !openImageUploadAreaRef.current);
                openImageUploadAreaRef.current = !openImageUploadAreaRef.current;
                setOpenImageUploadArea(openImageUploadAreaRef.current);
                // Other areas should be hidden, if upload area is open (one function a time)
                if (openImageUploadAreaRef.current) {
                    openImageSearchAreaRef.current = false;
                    setOpenImageSearchArea(false);
                }
            }
            const toggleOpenImageSearchArea = ()=>{
                // console.log(`FxGalleryPage: toggleOpenImageSearchArea: setting imageSearchAreaRef.current => `, !openImageSearchAreaRef.current);
                openImageSearchAreaRef.current = !openImageSearchAreaRef.current;
                setOpenImageSearchArea(openImageSearchAreaRef.current);
                // Other areas should be hidden, if upload area is open (one function a time)
                if (openImageSearchAreaRef.current) {
                    openImageUploadAreaRef.current = false;
                    setOpenImageUploadArea(false);
                }
            }
            
            const galleryActions: T_SPEEDDIAL_ACTION[] = [
                { name: 'Add image', icon: <AddIcon />,    tooltipVisibility: 'hover', action: toggleOpenImageUploadArea},
                { name: 'Filter',    icon: <SearchIcon />, tooltipVisibility: 'hover', action: toggleOpenImageSearchArea},
            ];

            // https://fxon.work/images/klipartz01.png
            const fxGetImageData2 = async (params?: T_IMAGE_METADATA)=>{
                // console.log(`FxGallery: fxGetImageData => params => `, params);
                const endpoint = "/images/fetch";
                // const params: T_IMAGE_METADATA = {
                //   uuid: "fx01010101"
                // }
                const params_ = params?params:{};
                // const imageData: T_IMAGE_METADATA[] = await fxFetchData(endpoint, params);
                const imageData: T_IMAGE_METADATA[] = await fxFetchData(endpoint, params_);
                // console.log(`fxGetImageData => imageData => `, imageData);

                // Example
                    // [
                    //     {
                    //         "uuid": "056639b8-f8c3-4a31-9432-183980e637ed",
                    //         "url": null,
                    //         "title": "img01_title",
                    //         "description": "img01_descr",
                    //         "encoding": null,
                    //         "place_name": "img01_place",
                    //         "place_coordinates": null,
                    //         "rating": 5,
                    //         "author": "img01_auth",
                    //         "width": null,
                    //         "height": null,
                    //         "shot_date": null,
                    //         "insertion_date": null
                    //     },
                    //     {
                    //         "uuid": "76a6a129-1165-40a5-b091-4d1b2fea842e",
                    //         "url": null,
                    //         "title": "img02_title",
                    //         "description": "img02_descr",
                    //         "encoding": null,
                    //         "place_name": "img02_place",
                    //         "place_coordinates": null,
                    //         "rating": 5,
                    //         "author": "img02_auth",
                    //         "width": null,
                    //         "height": null,
                    //         "shot_date": null,
                    //         "insertion_date": null
                    //     }
                    // ]
                //

                setImageList(imageData);
                // return imageData;
            }   

            // {
            //     id: number;              => ignore
            //     count: number,           => ignore
            //     keyList: T_FILTER_KEY[]; => ignore
            //     key: string,             => db column | sequelize attribute
            //     operation: string,       => Op
            //     value: string,
            // }
            const fxGetImageData = async (filters?: IDataFilter[])=>{
                // console.log(`FxGallery: fxGetImageData => params => `, params);
                const endpoint = "/images/fetch";


                // xxx
                const queryParams = filters?.map((filter)=>{
                    return {
                        attribute: filter.key,
                        operator: filter.operation,
                        value: filter.value
                    }
                })

                const imageData: T_IMAGE_METADATA[] = await fxFetchData(endpoint, queryParams);

                // Example
                    // [
                    //     {
                    //         "uuid": "056639b8-f8c3-4a31-9432-183980e637ed",
                    //         "url": null,
                    //         "title": "img01_title",
                    //         "description": "img01_descr",
                    //         "encoding": null,
                    //         "place_name": "img01_place",
                    //         "place_coordinates": null,
                    //         "rating": 5,
                    //         "author": "img01_auth",
                    //         "width": null,
                    //         "height": null,
                    //         "shot_date": null,
                    //         "insertion_date": null
                    //     },
                    //     {
                    //         "uuid": "76a6a129-1165-40a5-b091-4d1b2fea842e",
                    //         "url": null,
                    //         "title": "img02_title",
                    //         "description": "img02_descr",
                    //         "encoding": null,
                    //         "place_name": "img02_place",
                    //         "place_coordinates": null,
                    //         "rating": 5,
                    //         "author": "img02_auth",
                    //         "width": null,
                    //         "height": null,
                    //         "shot_date": null,
                    //         "insertion_date": null
                    //     }
                    // ]
                //

                setImageList(imageData);
                // return imageData;
            }   

            const getTableFields = async ()=>{
                const endpoint = "/images/fields";
                const imageFields:  T_FILTER_KEY[] = await fxFetchData(endpoint); // T_IMAGE_METADATA
                // console.log(`FxGalleryPage: getTableFields: imageFields => `, imageFields);

                return imageFields;
            };
            

            const handleOnCancel = ()=>{
                // Close upload area
                openImageUploadAreaRef.current = false;
                setOpenImageUploadArea(false);
                // Clear form
                setResetKey(!resetKey);
                pageContext.showMessage(`Upload cancelled.`);
            }
            const onNewImageUploaded = (filesMetadata: T_FILE_METADATA[])=>{
                // Close upload area
                openImageUploadAreaRef.current = false;
                setOpenImageUploadArea(false);
                // Clear form
                setResetKey(!resetKey);
                // ??
                setUploadedFilesMetadata(filesMetadata);
                // Refresh the gallery
                fxGetImageData();
                // Give feedback
                console.log(`FxGalleryPage: onNewImageUploaded: setting pageContext message `);
                pageContext.showMessage(`File ${filesMetadata[0].originalName} successfully uploaded!!!`);
            }
            const onFilterApply = (filters:IDataFilter[])=>{
                console.log(`FxGalleryPage: onFilterApply: filters => `, filters);

                // const params = {
                //     title: filters[0].value,
                // }
                // console.log(`FxGalleryPage: onFilterApply: params => `, params);

                // fxGetImageData(params);
                fxGetImageData(filters);
            }

            useEffect(() => {
                // console.log("FxList: Entering: useEffect");
                fxGetImageData();

                getTableFields()
                    .then((imageFields)=>{
                        setFilterFields(imageFields);
                        // console.log("FxGalleryPage: useEffect: getTableFields: imageFields => ", imageFields);
                    })
                    .catch((e)=>{
                        console.log("FxGalleryPage: useEffect: catch: e => ", e);
                    });

                pageContext.speedDialActions(galleryActions);
            }, []);
        // ---------------------------------------------------------------------------

        // STATE VARIABLES -----------------------------------------------------------
            // FX: TODO: check if theres better way to toggle show state (w/o both useRef AND useState)
                // ISSUE: as the toggle function is delegated to usePageContext(), the state is NOT 
                // being correctly updated.
            const openImageUploadAreaRef = useRef(false);
            const [openImageUploadArea, setOpenImageUploadArea] = useState(openImageUploadAreaRef.current);
            const openImageSearchAreaRef = useRef(false);
            const [openImageSearchArea, setOpenImageSearchArea] = useState(openImageSearchAreaRef.current);

            const [imageList, setImageList] = useState<T_IMAGE_METADATA[]>([]);
            const [uploadedFilesMetadata, setUploadedFilesMetadata] = useState<T_FILE_METADATA[]>();
            const pageContext = usePageContext();
            const [resetKey, setResetKey] = useState(false);
            const [filterFields, setFilterFields] = useState<T_FILTER_KEY[]>([]);
        // ---------------------------------------------------------------------------

        // SUB COMPONENTS ---------------------------------------------------------
            const imagePropsForm = <>
                <div 
                    id="image-props-form"
                    style={{
                        textAlign: "center",
                    }}
                    key={`${resetKey}`}
                >
                    <Grid 
                        id="image-props-grid"
                        container
                        // spacing={2}
                            // margin="dense"
                    >
                        <Grid 
                            id="img-title-container"
                            item 
                            xs={12} 
                            sm={12}
                            // margin="dense"
                            sx={{ mb: 2 }}
                        >
                            <TextField name="title"
                                id="imgTitle"
                                label="Title"
                                required
                                fullWidth
                                autoFocus
                                // margin="dense"
                                // sx={{ mb: 2 }}
                                size="small"

                                // autoComplete="given-name"
                                // value={formState.title}
                                // onChange={imageTitle_onChange}
                                // onInput={imageTitle_onInput}
                                // onSubmit={imageTitle_onSubmit}
                            />
                        </Grid>

                        <Grid 
                            id="img-description-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <TextField name="description"
                                id="imgDescription"
                                label="Image Description"
                                // required
                                fullWidth
                                // sx={{ mb: 2 }}
                                size="small"

                                // autoComplete="family-name"
                                // value={formState.description}
                            />
                        </Grid>

                        <Grid 
                            id="img-author-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <TextField name="author"
                                id="imgAuthor"
                                label="Image Author"
                                // required
                                fullWidth
                                // sx={{ mb: 2 }}
                                size="small"

                                // autoComplete="family-name"
                                // value={formState.description}
                            />
                        </Grid>

                        <Grid 
                            id="img-placename-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <TextField name="place_name"
                                id="imgPlaceName"
                                label="Image Place Name"
                                // required
                                fullWidth
                                // sx={{ mb: 2 }}
                                size="small"

                                // autoComplete="family-name"
                                // value={formState.description}
                            />
                        </Grid>

                        <Grid 
                            id="img-tags-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <FxTagList name="tags"
                                id="imgTags"
                                vSpacing="dense"
                                // tagList={formState.tags}
                            />
                        </Grid>

                        <Grid 
                            id="img-rating-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <FxRating name="rating"
                                id="gallery-image-rating"
                                labels={labels}
                                width="100%"
                                // defaultValue={formState.rating}
                            />
                        </Grid>

                    </Grid>
                </div>
            </>

            // Object.keys(foo) // list type properties
            // TAGS, title, description, place_name, place_coordinates, rating, auhor, shot_date, insertion_date
            const imageSearchForm = <>
                <div 
                    id="image-search-form"
                    style={{
                        textAlign: "center",
                        // height: "100px",
                        // background: "green",
                        paddingBottom: "20px",
                    }}
                    key={`${resetKey}`}
                >

                    <FxDataFilterGrid 
                        keys={filterFields}
                        onFilterApply={onFilterApply}
                    />

                    {/* <Grid 
                        // id="image-props-form"
                        container
                        // spacing={2}
                    >
                        <Grid 
                            id="img-search-title-container"
                            item 
                            xs={12} 
                            sm={12}
                            // margin="dense"
                            sx={{ mb: 2 }}
                        >
                            <TextField name="title"
                                id="imgTitle"
                                label="Title"
                                required
                                fullWidth
                                autoFocus
                                // margin="dense"
                                // sx={{ mb: 2 }}
                                size="small"

                                // autoComplete="given-name"
                                // value={formState.title}
                                // onChange={imageTitle_onChange}
                                // onInput={imageTitle_onInput}
                                // onSubmit={imageTitle_onSubmit}
                            />
                        </Grid>

                        <Grid 
                            id="img-search-description-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <TextField name="description"
                                id="imgDescription"
                                label="Image Description"
                                // required
                                fullWidth
                                // sx={{ mb: 1 }}
                                size="small"

                                // autoComplete="family-name"
                                // value={formState.description}
                            />
                        </Grid>

                        <Grid 
                            id="img-search-author-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <TextField name="author"
                                id="imgAuthor"
                                label="Image Author"
                                // required
                                fullWidth
                                // sx={{ mb: 2 }}
                                size="small"

                                // autoComplete="family-name"
                                // value={formState.description}
                            />
                        </Grid>

                        <Grid 
                            id="img-search-placename-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <TextField name="place_name"
                                id="imgPlaceName"
                                label="Image Place Name"
                                // required
                                fullWidth
                                // sx={{ mb: 2 }}
                                size="small"

                                // autoComplete="family-name"
                                // value={formState.description}
                            />
                        </Grid>

                        <Grid 
                            id="img-search-tags-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <FxTagList name="tags"
                                id="imgTags"
                                vSpacing="dense"
                                // tagList={formState.tags}
                            />
                        </Grid>

                        <Grid 
                            id="img-search-rating-container"
                            item 
                            xs={12} 
                            sm={12}
                            sx={{ mb: 2 }}
                        >
                            <FxRating name="rating"
                                id="gallery-image-rating"
                                labels={labels}
                                width="100%"
                                // defaultValue={formState.rating}
                            />
                        </Grid>

                    </Grid> */}



                </div>
            </>

            const imageUploadButton = <>
                <div 
                    id="image-upload-container"
                    style={{
                        textAlign: "center",
                    }}
                >
                    <FxFileUpload
                        id="image-upload-component"
                        // {...fileUploadProp} 

                        height="20vh"
                        width="100%"
                        accept="image/*"
                        // uploadTrigger="immediate" // on-click

                        baseUrl={apiUrl_}
                        endpoint='/images/upload'
                        filePropsForm={imagePropsForm}
                        // filePropsForm={imagePropsForm()}
                        // filePropsForm={formElement}

                        // Use line below to set background image
                        // buttonImage={defaultImageProp}

                        onUploaded={onNewImageUploaded }
                        onCancel={handleOnCancel}
                    />
                </div>
            </>

            const imageUploadArea = <>
                <Collapse 
                    orientation="vertical" 
                    in={openImageUploadArea}
                >
                    <Box 
                        id="image-upload-area-box"
                        sx={{ 
                            width: "100%", 
                            // background: "blue",
                            paddingBottom: "20px",
                        }}
                    >
                        {imageUploadButton}
                    </Box>
                </Collapse>
            </>

            const imageSearchArea = <>
                <Collapse 
                    orientation="vertical" 
                    in={openImageSearchArea}
                >
                    <Box 
                        id="image-search-area-box"
                        sx={{ 
                            width: "100%", 
                            // background: "blue",
                        }}
                    >
                        {/* {imageUploadButton} */}
                        {imageSearchForm}
                    </Box>
                </Collapse>
            </>

            const imageGallery = <div
                style={{
                    height: context.maxHeight,        // Scroll only works fine with defined height (not % values)
                    overflowY: 'scroll' 
                }}
            >
                {/* <FxMasonry imageList={imgData_TEST}/> */}
                <FxMasonry imageList={imageList} baseUrl={imagesUrl_!}/> 
            </div>
        // ---------------------------------------------------------------------------

        return (
            <div id='fx-gallery-page'>
                {imageUploadArea}
                {/* FX: render filters only after proper database querying  */}
                {filterFields.length && imageSearchArea}
                {imageGallery}
            </div>

        )
    }
// ---------------------------------------------------------------------------

export default FxGalleryPage;
