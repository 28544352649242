import * as React from 'react';
import { 
    Box, 
    styled, 
} from '@mui/material';
import { 
    T_CSS_HORIZONTAL_ALIGN, 
    T_CSS_TEXT_ALIGN, 
    T_CSS_VERTICAL_ALIGN, 
    T_FX_HORIZONTAL_ALIGN, 
    T_FX_VERTICAL_ALIGN, 
} from "./FxTypes";
// import { fxtrList } from '../services/FxPolyglot.service';

// const theme = useTheme();
// const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
export const FxPageLeftPadding = '20%';
export const FxPageRightPadding = '10%';
// export const FX_PAGE_MAX_HEIGHT = isBigScreen?'90vh':'86vh'; // one simplistic way to solve mobile viewport variable height.
export const FX_PAGE_MAX_HEIGHT = '90vh';



export const fxGetLanguage = ()=>{
    // const langs = navigator.languages;
    const lang = navigator.language;
    // const location = navigator.geolocation;
    // console.log(`langs => `, langs);
    console.log(`lang => `, lang);
    // console.log(`location => `, location);

    return lang;
}

export const str2html = (str: string)=>{
    return <div dangerouslySetInnerHTML={{__html: str}}></div>
}
// export function isInViewport(element: any) {
//     const rect = element.getBoundingClientRect();
//     console.log(`rect => `, rect);
//     console.log(`window.innerHeight => `, window.innerHeight);
//     console.log(`document.documentElement.clientHeight => `, document.documentElement.clientHeight);
//     console.log(`window.innerWidth => `, window.innerWidth);
//     console.log(`document.documentElement.clientWidth => `, document.documentElement.clientWidth);
    
//     return (
//         rect.top >= 0 &&
//         rect.left >= 0 &&
//         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//     );
// }

export function checkVisible(elm: any) {
  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

// const customElements = [    createCustomElement(1, "element", debug_, 'center', 'center'), 
//                             createCustomElement(2, "element", debug_), 
//                             createCustomElement(3, "element", debug_)
//                         ];
// const proportions = [25, 50, 25];



// const options = [
//     { title: 'The Shawshank Redemption', year: 1994 },
//     { title: 'The Godfather', year: 1972 },
//     { title: 'The Godfather: Part II', year: 1974 },
//     { title: 'The Dark Knight', year: 2008 },
// ];

// const svgLogo = ()=>{
    //     return (
    //         <a href="https://github.com/bchiang7" 
    //             aria-label="GitHub" 
    //             target="_blank" 
    //             rel="noreferrer">
    //             <svg xmlns="http://www.w3.org/2000/svg" 
    //                 role="img" 
    //                 viewBox="0 0 24 24" 
    //                 fill="none" 
    //                 stroke="currentColor" 
    //                 strokeWidth="2" 
    //                 strokeLinecap="round" 
    //                 strokeLinejoin="round" 
    //                 className="svg-logo feather feather-github">
    //                 <title>GitHub</title>
    //                 <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
    //             </svg>
    //         </a>        
    //     );
// }

// export const autocompleteOptions = getTextList("fx-autocomplete-opt");
// export const autocompleteOptions = [
//     'Desenvolver software...' ,
//     'Desenvolver web site...',
//     'Obter uns pitacos sobre negócios...' ,
//     'Adotar uma das nossas idéias de negócios!',
//     'Obter consultoria sobre negócios...' ,
//     'Obter consultoria sobre startups...' ,
//     'Criar protótipos MVP...' ,
//     'Conhecer o programa START.WHERE' ,
//     'Dar UP na sua marca...' ,
//     'Trocar ideia sobre filosofia e a vida...' ,
//     'Ir para área de saúde e biohacking.' ,
//     'Ir para o blog!' ,
//     'Ir para o lodjinha do brimo!' ,
//     'Ver minha galeria de fotos...' ,
//     'Conhecer nosso trabalho de light design' ,
//     'Conhecer nosso MANIFESTO!!! :-D' ,
// ];


/* ------------------------------------------------------------------------------ */
// PERSISTENCE
export const fxSave = (name:string, value: string)=>{
    localStorage.setItem(name, value);
    return value; // FX: specific use 
}
export const fxRecall = (name:string)=>{
    return localStorage.getItem(name);
}



// Exemplo CSS variables. https://mui.com/material-ui/customization/how-to-customize/#3-global-theme-overrides
const successVars = {
  '--color': '#4caf50',
  '--box-shadow': 'rgb(76, 175, 80, .16)',
} as React.CSSProperties;

// TODO: melhorar essa funcao de testes
export const reportWindowSize = ()=>{
    // fxAttachEvent(window, 'resize', reportWindowSize);
    try{
        window.addEventListener('resize', reportWindowSize);
    } catch(e: any) {
        console.log(e);
    }
    // getEventListeners(document.querySelector("your-element-selector"),)
  console.log(window.innerHeight);
  console.log(window.innerWidth);
}

export const fxAttachEvent = (
  element: Element,
  eventName: string,
  callback: () => void
) => {
  if (element && eventName && element.getAttribute("listener") !== "true") {
    element.setAttribute("listener", "true");
    element.addEventListener(eventName, () => {
      callback();
    });
  }
};


export const showInfo = (elementId: string)=>{
    const element = document.getElementById(elementId);
    if (!!!element) {
        console.log(`NOT FOUND: element => ${element}`)
        return;
    }

    console.log(`|-----------------------------------------------------------|`);
    console.log(`ELEMENT ID INFO: ${elementId}`)
    const height = element.clientHeight
    const width = element.clientWidth
    console.log(`height => ${height}`);
    console.log(`width => ${width}`);

    const offsetHeight = element.offsetHeight
    const offsetWidth = element.offsetWidth
    console.log(`offsetHeight => ${offsetHeight}`);
    console.log(`offsetWidth => ${offsetWidth}`);

    // Get coordinates
    const mainBoundingRect = element.getBoundingClientRect();
    console.log(`mainBoundingRect.JSON =>`);
    console.log(mainBoundingRect.toJSON());

    const mainRects = element.getClientRects();
    console.log(`mainRects =>`);
    console.log(mainRects);

    const mainOffsetTop = element.offsetTop;
    const mainOffsetLeft = element.offsetLeft;
    const mainOffsetParent = element.offsetParent;
    console.log(`mainOffsetTop => ${mainOffsetTop}`);
    console.log(`mainOffsetLeft => ${mainOffsetLeft}`);
    console.log(`mainOffsetParent =>`);
    console.log(mainOffsetParent);

    const mainClientTop = element.clientTop;
    const mainClientLeft = element.clientLeft;
    console.log(`mainClientTop => ${mainClientTop}`);
    console.log(`mainClientLeft => ${mainClientLeft}`);

    const mainScrollTop = element.scrollTop;
    const mainScrollLeft = element.scrollLeft;
    console.log(`mainScrollTop => ${mainScrollTop}`);
    console.log(`mainScrollLeft => ${mainScrollLeft}`);

    const mainTransformOrigin_ori = window.getComputedStyle(element, null).getPropertyValue('transform-origin'); 
    console.log(`mainTransformOrigin_ori => ${mainTransformOrigin_ori}`);
    element.style.transformOrigin = 'top left';
    const mainTransformOrigin_new = window.getComputedStyle(element, null).getPropertyValue('transform-origin'); 
    console.log(`mainTransformOrigin_new => ${mainTransformOrigin_new}`);

    const paddingTop = window.getComputedStyle(element, null).getPropertyValue('padding-top'); 
    const paddingLeft = window.getComputedStyle(element, null).getPropertyValue('padding-left'); 
    console.log(`paddingTop => ${paddingTop}`);
    console.log(`paddingLeft => ${paddingLeft}`);

    const marginTop = window.getComputedStyle(element, null).getPropertyValue('margin-top'); 
    const marginLeft = window.getComputedStyle(element, null).getPropertyValue('margin-left'); 
    console.log(`marginTop => ${marginTop}`);
    console.log(`marginLeft => ${marginLeft}`);

    const borderTop = window.getComputedStyle(element, null).getPropertyValue('border-top-width'); 
    const borderLeft = window.getComputedStyle(element, null).getPropertyValue('border-left-width'); 
    console.log(`borderTop => ${borderTop}`);
    console.log(`borderLeft => ${borderLeft}`);

    console.log(`|-----------------------------------------------------------|`);
}


// https://jsfiddle.net/n1pujm7x/
// Use:
// var css = CssClassBuilder();
// css.addRule(".rule","color:green;background:red");
class CssClassBuilder {
        // FX:
        // var styleSheet;
        styleSheet:any;
        // var pub = {};
        pub:any = ()=>{        // pub.addRule = function(selector,style) {
            const addRule = (selector: any, style: any, styleSheetDefined_: ()=>{})=>{
                if (styleSheetDefined_())
                    this.styleSheet.addRule(selector,style);
            }

            const removeRule = (selector: any, styleSheetDefined_: ()=>{})=>{
                if (styleSheetDefined_())
                    this.styleSheet.removeRule(selector);
            }
            //   constructor();
        };


        // var constructor = function () {
        constructor() {
            if (!this.styleSheetsExist() || !this.headElementExists())
                return;

                this.getStyleSheet();
                if (typeof this.styleSheet === 'undefined') {
                    this.makeStyleSheet();
                }
        }
        
        // var MediaType = function (styleSheet,media) {
        MediaType(styleSheet__:any, media:any) {
            const styleSheet_ = styleSheet__;
            const media_ = media;

            // this.mediaType = function () {
            const mediaType = ()=>{
                return media_;
            }
            // this.styleSheet = function () {
            const styleSheet = ()=>{
                return styleSheet_;
            }
        }

        // var StringMediaType = function (styleSheet) {
        StringMediaType(styleSheet_:any) {
            // var pub = {};
            var pub___: any = {};
            this.MediaType.call(pub___,styleSheet_,"string");

            pub___.addRule =(selector:any, style:any)=>{
                for (var i = 0, l = styleSheet_.rules.length; i < l; i++) {
                    if(styleSheet_.rules[i].selectorText && styleSheet_.rules[i].selectorText.toLowerCase()==selector.toLowerCase()) {
                      styleSheet_.rules[i].style.cssText = style;
                      return;
                    }
                  }
                  styleSheet_.addRule(selector,style);
            }

            pub___.removeRule = (selector:any)=>{
                for (var i=0; i<styleSheet_.rules.length; i++) {
                    if (styleSheet_.rules[i].selectorText.toLowerCase() === selector.toLowerCase()) {       
                        styleSheet_.removeRule (i);
                    }
                }
            }

            return pub___;
        }

        // StringMediaType.isString = function (styleSheet) {
        isString = (styleSheet_: any)=>{
            var media = styleSheet_.media;
            var mediaType = typeof media;
            if (mediaType === 'string') {
                if (media === '' || (media.indexOf('screen') !== -1)) {
                    return true;
                }
            }
            return false;
        }

        // var ObjectMediaType = function (styleSheet) {
        ObjectMediaType = (styleSheet_: any)=>{
            // var pub = {};
            var pub__: any = {};
            this.MediaType.call(pub__,styleSheet_,"object");
            // var pub = {};

            pub__.addRule = (selector: any, style: any)=>{
                const styleSheetLength = (styleSheet_.cssRules) ? styleSheet_.cssRules.length : 0;
                for (var i = 0; i < styleSheetLength; i++) {
                    if (styleSheet_.cssRules[i].selectorText && styleSheet_.cssRules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
                        styleSheet_.cssRules[i].style.cssText = style;
                        return;
                    }
                }
                styleSheet_.insertRule(selector + '{' + style + '}', styleSheetLength);
            }

            pub__.removeRule = (selector: any)=>{
                for (var i=0; i<styleSheet_.cssRules.length; i++) {
                    if (styleSheet_.cssRules[i].selectorText.toLowerCase() === selector.toLowerCase()) {        
                        styleSheet_.deleteRule (i);
                    }
                }  
            }
            return pub__;
        }

        // ObjectMediaType.isObject = function (styleSheet) {
        isObject = (styleSheet_: any)=>{
            var media = styleSheet_.media;
            var mediaType = typeof media;
            if (mediaType=='object') {
                if (media.mediaText === '' || (media.mediaText.indexOf('screen') !== -1)) {
                    return true;
                }
            }
            return false;
        }
        
        // var makeMediaType = function (styleSheet) {
        makeMediaType = (styleSheet_: any)=>{
            if (this.isString(styleSheet_)) {
                return this.StringMediaType(styleSheet_);
            } else if (this.isObject(styleSheet_)) {
                return this.ObjectMediaType(styleSheet_);
            } else {
                return undefined;
            }
        }

        // var styleSheetsExist = function () {
        styleSheetsExist = ()=>{
            return document.styleSheets;
        }

        // var headElementExists = function () {
        headElementExists = ()=>{
            return document.getElementsByTagName('head').length !== 0;
        }

        // var makeStyleSheet = function () {
        makeStyleSheet = ()=>{
            const styleSheetElement = document.createElement('style');
            styleSheetElement.type = 'text/css';
            document.getElementsByTagName('head')[0].appendChild(styleSheetElement);
        
            for (var i = 0; i < document.styleSheets.length; i++) {
              if (document.styleSheets[i].disabled) {
                continue;
              }
              this.styleSheet = this.makeMediaType(document.styleSheets[i]);
            }
            
        }
        // var getStyleSheet = function () {
        getStyleSheet = ()=>{
            if (document.styleSheets.length > 0) {
                for (var i = 0, l = document.styleSheets.length; i < l; i++) {
                    if (document.styleSheets[i].disabled) 
                    continue;
                    
                    this.styleSheet = this.makeMediaType(document.styleSheets[i]);

                    if (typeof this.styleSheet !== 'undefined') 
                    break;
                }
            }
        }
        // var styleSheetDefined = function () {
        styleSheetDefined = ()=>{
            return this.styleSheet !== undefined;
        }


        getPub = ()=>{
            return this.pub;
        }
      }
// var css = new CssClassBuilder();
// css.getPub();//.addRule(".rule","color:green;background:red");
  



// // https://stackoverflow.com/questions/1720320/how-to-dynamically-create-css-class-in-javascript-and-apply
export const fxSetCssClass = ()=>{
    var style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = '.cssClass { color: #F00; }';
    document.getElementsByTagName('head')[0].appendChild(style);
    const element = document.getElementById('someElementId');
    if (!!!element) return;
    element.className = 'cssClass';
}

export const fxAddCssClassList = (elementId: string, cssClassList: string[])=>{
    const element = document.getElementById(elementId)
    if (!!!element) return;

    element.classList.add('MyClass');
}

export const getBorder = (elementId: string)=>{
    const element = document.getElementById(elementId);
    if (!!!element) return;
    const borderTopStr = window.getComputedStyle(element, null).getPropertyValue('border-top-width'); 
    const borderBottomStr = window.getComputedStyle(element, null).getPropertyValue('border-bottom-width'); 
    const borderLeftStr = window.getComputedStyle(element, null).getPropertyValue('border-left-width'); 
    const borderRightStr = window.getComputedStyle(element, null).getPropertyValue('border-right-width'); 
    const borderTop= +borderTopStr.replace('px', '');
    const borderBottom= +borderBottomStr.replace('px', '');
    const borderLeft= +borderLeftStr.replace('px', '');
    const borderRight= +borderRightStr.replace('px', '');
    // console.log(`borderTop => ${borderTop}`);
    // console.log(`borderLeft => ${borderLeft}`);

    return {
        top:    borderTop,
        bottom: borderBottom,
        left:   borderLeft,
        right:  borderRight
    }
}
export const getMargin = (elementId: string)=>{
    const element = document.getElementById(elementId);
    if (!!!element) return;
    const topStr = window.getComputedStyle(element, null).getPropertyValue('margin-top'); 
    const bottomStr = window.getComputedStyle(element, null).getPropertyValue('margin-bottom'); 
    const leftStr = window.getComputedStyle(element, null).getPropertyValue('margin-left'); 
    const rightStr = window.getComputedStyle(element, null).getPropertyValue('margin-right'); 
    const top= +topStr.replace('px', '');
    const bottom= +bottomStr.replace('px', '');
    const left= +leftStr.replace('px', '');
    const right= +rightStr.replace('px', '');
    // console.log(`margin-top => ${top}`);
    // console.log(`border-left => ${left}`);

    return {
        top: top,
        bottom: bottom,
        left: left,
        right: right
    }
}
export const setPositionCssVar = (  originElementId: string, 
                                    targetElementId: string, 
                                    originCssId: string,
                                    targetCssId: string,
                                    topCssSuffix: string,
                                    leftCssSuffix: string,
                                    includeMargin: boolean=false
    )=>{
        const originElement = document.getElementById(originElementId);
        const targetElement = document.getElementById(targetElementId);
        // console.log(`originElement =>`);
        // console.log(originElement)
        // console.log(`targetElement =>`);
        // console.log(targetElement);
        if ((!!!originElement)||(!!!targetElement)) {
            return;
        }

        // Get the top, left coordinates of two elements
        const originRect = originElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();
        // console.log(`originRect.JSON =>\n${JSON.stringify(originRect)}`);
        // console.log(`originRect.JSON =>`);
        // console.log(originRect.toJSON());
        // console.log(`targetRect.JSON =>`);
        // console.log(targetRect.toJSON());

        const originTop = window.getComputedStyle(originElement, null).getPropertyValue('top'); 
        const originLeft = window.getComputedStyle(originElement, null).getPropertyValue('left'); 

        // Margin is not inside the Bounding Client Rectangle. If needed, calculate it.
        var originMargin = {top:0, bottom:0, left:0, right:0};
        if (includeMargin){
            const margin = getMargin(originElementId);
            console.log(`margin => `);
            console.log(margin);
            if (!!margin) originMargin = margin;
        }

        const targetBorder = getBorder(targetElementId);
        if (!!!targetBorder) return;

        // Set the top and left positions
        // const newTop = targetElement.clientTop;
        // const newTop = targetRect.top;
        // const newTop = targetRect.top - originRect.top + targetBorderTop + originMarginTop;
        const newTop = targetRect.top - originRect.top + targetBorder.top + originMargin.top;
        // const newTop = targetRect.top - originRect.top + targetBorderTop;
        // const newLeft = targetRect.left - originRect.left + targetBorder.left + originMargin.left;
        const newLeft = targetRect.left - originRect.left;
        // console.log(`newTop => ${newTop}`);
        // console.log(`newLeft => ${newLeft}`);
        // header-logo-top-origin
        // document.documentElement.style.setProperty(`--${cssVarId}-top-origin`, originTop);
        // document.documentElement.style.setProperty(`--${cssVarId}-left-origin`, originLeft);
        // document.documentElement.style.setProperty(`--${cssVarId}-top-target`, newTop + 'px');
        // document.documentElement.style.setProperty(`--${cssVarId}-left-target`, newLeft + 'px');
        document.documentElement.style.setProperty(`--${originCssId}-${topCssSuffix}`, originTop);
        document.documentElement.style.setProperty(`--${originCssId}-${leftCssSuffix}`, originLeft);
        document.documentElement.style.setProperty(`--${targetCssId}-${topCssSuffix}`, newTop + 'px');
        document.documentElement.style.setProperty(`--${targetCssId}-${leftCssSuffix}`, newLeft + 'px');
}

export const setTranslateCssVar = (cssVarId :string, originElementId: string, targetElementId: string)=>{
        console.log(`Entering setTranslateCssVar\ncssVarId => ${cssVarId}\noriginElementId => ${originElementId}\ntargetElementId=> ${targetElementId}` );

        const originElement = document.getElementById(originElementId);
        const targetElement = document.getElementById(targetElementId);
        if ((!!!originElement)||(!!!targetElement)) {
            // console.log(`originElement => ${originElement}`)
            // console.log(`targetElement => ${targetElement}`)
            return;
        }

        // Get the top, left coordinates of two elements
        const originRect = originElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();
        // console.log(`originRect.JSON =>`);
        // console.log(originRect.toJSON());
        // console.log(`targetRect.JSON =>`);
        // console.log(targetRect.toJSON());

        const targetBorder = getBorder(targetElementId);
        if (!!!targetBorder) return;

        // Calculate the top and left positions
        const top = targetRect.top - originRect.top + targetBorder.top;
        const left = targetRect.left - originRect.left + targetBorder.left;
        // console.log(`new top => ${top}\nnew left => ${left}`);

        document.documentElement.style.setProperty(`--${cssVarId}-top`, top + 'px');
        document.documentElement.style.setProperty(`--${cssVarId}-left`, left + 'px');
}

export const fxSetCssVar = (cssVarId :string, cssVarValue: string)=>{
    document.documentElement.style.setProperty(cssVarId, cssVarValue);
}

export const setTargetScale = (cssVarId :string, originElementId: string, targetElementId: string, mode?: 'width'|'height'|'max'|'min'|'stretch'|'auto') => {
    const originElement = document.getElementById(originElementId);
    const targetElement = document.getElementById(targetElementId);
    if ((!!!originElement)||(!!!targetElement)) return;

    // Get the top, left coordinates of two elements
    // const originRect = originElement.getBoundingClientRect();
    // const targetRect = targetElement.getBoundingClientRect();

    // Get height and width
    const originHeight = originElement.clientHeight;
    const originWidth = originElement.clientWidth;
    const targetHeight = targetElement.clientHeight;
    const targetWidth = targetElement.clientWidth;
    console.log(`originHeight => ${originHeight}`);
    console.log(`originWidth => ${originWidth}`);
    // console.log(`targetHeight => ${targetHeight}`);
    // console.log(`targetWidth => ${targetWidth}`);
    const mode_ = mode?mode:'auto';
    console.log(`mode_ => ${mode_}`);

    var scaleX=1, scaleY=1;

        switch (mode_){
            case 'auto':
                if (targetWidth == originWidth){
                    if (targetHeight != originHeight){
                        scaleX = 1+Math.abs((targetHeight - originHeight)/originHeight);
                        scaleY = scaleX;
                    } else break;
                } else {
                    scaleX = 1+Math.abs((targetWidth - originWidth)/originWidth);
                    scaleY = scaleX;
                }
            break;
            case 'width':
                scaleX = 1+Math.abs((targetWidth - originWidth)/originWidth);
                scaleY = scaleX;
            break;
            case 'height':
                scaleY = 1+Math.abs((targetHeight - originHeight)/originHeight);
                scaleX = scaleY;
            break;
            case 'stretch':
                scaleX = 1+Math.abs((targetWidth - originWidth)/originWidth);
                scaleY = 1+Math.abs((targetHeight - originHeight)/originHeight);
            break;
            case 'max':
                scaleX = Math.max(targetHeight, targetWidth);
                scaleY = scaleX;
            break;
            case 'min':
                scaleX = Math.min(targetHeight, targetWidth);
                scaleY = scaleX;
            break;
        }

    console.log(`--${cssVarId}-scaleX => ${scaleX.toString()}`);
    console.log(`--${cssVarId}-scaleY => ${scaleY.toString()}`);
    document.documentElement.style.setProperty(`--${cssVarId}-scaleX`, scaleX.toString());
    document.documentElement.style.setProperty(`--${cssVarId}-scaleY`, scaleY.toString());
    return {
        sx: scaleX,
        sy: scaleY
    }




    // // Get origin and target height and width
    // const oh = fxGetElementHeight(originId);
    // const ow = fxGetElementWidth(originId);
    // const th = fxGetElementHeight(targetId);
    // const tw = fxGetElementWidth(targetId);
    // if (!!!oh||!!!ow||!!!th||!!!tw) return;
    // // Calc proportions
    // const scaleX = tw / ow;
    // const scaleY = th / oh;
    // // if (scaleX == scaleY)
    // //     document.documentElement.style.setProperty('--logo-scale', top);
    // // else {
    // // }
    // document.documentElement.style.setProperty('--logo-scaleX', scaleX.toString());
    // document.documentElement.style.setProperty('--logo-scaleY', scaleY.toString());
    // return {
    //     sx: scaleX,
    //     sy: scaleY
    // }
}


export const getPosition = (elementId: string)=>{
    // console.log("ksjdhgcdk")
    //      0,0 __________ x = left
    //         |
    //         |
    // y = top |
    //
    // Origin: element <FxLogo> position (top, left) of <FxPageHeader>  (id='FxPageHeader-logo'), origin=document body
    // Target: element <FxLogo> position (top, left) of <FxAppPage>     (id='FxAppPage-Logo'),    origin=document body
    // 
    // translateX => targetLeft - originLeft => CSSTransitions '--logo-left'
    // translateY => targetTop  - originTop  => CSSTransitions '--logo-top'

    // let oElem = document.getElementById('FxPageHeader-logo'); 
    let oElem = document.getElementById(elementId); 
    if (!!!oElem) return;
    console.log(`elementId => ${oElem.id}`);

    // let tElem = document.getElementById('FxAppPage-Logo'); 
    // if (!!!tElem) return;
    // console.log(`targetElem => ${tElem.id}`);

    const oElemTopStr = window.getComputedStyle(oElem, null).getPropertyValue('transform-origin'); 
    // const oElemTopArr = oElemTopStr.match(/(\d*(?:\.\d+)?)(px|rem)/g);
    // const oElemTopMatches = oElemTopStr.match(/(\d*(?:\.\d+)?)(px|rem)/g);
    const oElemTopArrIterator = oElemTopStr.matchAll(/(\d*(?:\.\d+)?)(?:px|rem)/g);
    const oElemTopArray = Array.from(oElemTopArrIterator);
    const oElemX = oElemTopArray[0][1];
    const oElemY = oElemTopArray[1][1];

    // Type 'IterableIterator<RegExpMatchArray>' can only be iterated through when using 
    // the '--downlevelIteration' flag or with a '--target' of 'es2015' or higher.
    // const array = [...str.matchAll(regexp)];
    // return array.map(m => m[1]);
    // OR
    // console.log([...oElemTopArrIterator]);

    if (!!!oElemTopArray) return;
    console.log(
    `
        oElemX => ${oElemX}
        oElemY => ${oElemY}
        oElem.clientTop => ${oElem.clientTop}
        oElem.offsetTop => ${oElem.offsetTop}
        oElem.scrollTop => ${oElem.scrollTop}`);

    return {
        x: Number(oElemX),
        y: Number(oElemY)
    }

}

export const setTargetPosition = (targetId: string, relativeTo:'document'|'self'|'parent') => {
        console.log(`FxUtils: entering: setTargetPosition:\ntargetId => ${targetId}\nrelativeTo => ${relativeTo}`);
        // const style = { "--my-css-var": 10 } as React.CSSProperties;
        document.documentElement.style.setProperty('--test-color', 'red');
        const target = fxGetPosition(targetId, relativeTo);
        var top:string;
        var left:string;
        if (!!target) {
            top = target.top;
            left = target.left;
        } else return;
        console.log(`top => ${top}`);
        console.log(`left => ${left}`);
        document.documentElement.style.setProperty('--logo-top', top);
        document.documentElement.style.setProperty('--logo-left', left);
};

export const showPosition = (id: string)=>{
    // let elem = document.querySelector('div');
    var elem = document.getElementById(id);
    if (!!!elem) return;
    console.log(id);
    let rect:any = elem.getBoundingClientRect();
    for (var key in rect) {
        if(typeof rect[key] !== 'function') {
            let para = document.createElement('p');
            para.textContent  = `${ key } : ${ rect[key] }`;
            // document.body.appendChild(para);

            console.log(para);
        }
    }
}

    // elem?.compareDocumentPosition()
    // elem?.contains()
    // elem?.getClientRects()
    // elem?.offsetHeight
    // elem?.scrollHeight
    // elem?.clientLeft
    // elem?.getBoundingClientRect

export const fxGetElementHeight = (elementId:string)=>{
    let elem = document.getElementById(elementId);
    const h = elem?.clientHeight;

    return h;
}

export const fxGetElementWidth = (elementId:string)=>{
    let elem = document.getElementById(elementId);
    const w = elem?.clientWidth;

    return w;
}

// get  coordinates of the element
export const fxGetPosition = (elementId:string, reference?: 'document'|'self'|'parent') =>{
    let elem = document.getElementById(elementId);
    if (!!!elem) return;
    // let container = document.getElementById('page-header');

    // let container = document.querySelector(`#${elementId}`);
    // let container = document.querySelector('#page-header');
    let container = elem.parentElement;
    if (!!!container) return;

    const reference_ = reference?reference:'document';


    const paddingLeftAttr = window.getComputedStyle(container, null).getPropertyValue('padding-left'); 
    const paddingLeftStr = paddingLeftAttr.match(/(\d*(?:\.\d+)?)(px|rem)/);
    if (!!!paddingLeftStr) return;
    // console.log(`paddingLeftStr => ${paddingLeftStr}`);
    // console.log(`paddingLeftStr[1] => ${paddingLeftStr[1]}`);
    const paddingLeft = Number(paddingLeftStr[1]);
    // if (!!!left) return;
    //console.log(`paddingLeft => ${paddingLeft}`);


    const paddingTopAttr = window.getComputedStyle(container, null).getPropertyValue('padding-top')  ;
    const paddingTopStr = paddingTopAttr.match(/(\d*(?:\.\d+)?)(px|rem)/);
    if (!!!paddingTopStr) return;
    // console.log(`paddingTopStr => ${paddingTopStr}`);
    // console.log(`paddingTopStr[1] => ${paddingTopStr[1]}`);
    const paddingTop = Number(paddingTopStr[1]);
    // if (!!!top) return;
    //console.log(`paddingTop => ${paddingTop}`);


    const marginLeftAttr = window.getComputedStyle(container, null).getPropertyValue('padding-left'); 
    const marginLeftStr = marginLeftAttr.match(/(\d*(?:\.\d+)?)(px|rem)/);
    if (!!!marginLeftStr) return;
    // console.log(`marginLeftStr => ${marginLeftStr}`);
    // console.log(`marginLeftStr[1] => ${marginLeftStr[1]}`);
    const marginLeft = Number(marginLeftStr[1]);
    // if (!!!left) return;
    //console.log(`marginLeft => ${marginLeft}`);


    var box;
    if (!!elem) box = elem.getBoundingClientRect();
    else return;
    //console.log(box.toJSON());

    const documentLeft = box.left + window.scrollX - paddingLeft - marginLeft;
    const documentTop = box.top + window.scrollY - paddingTop;

    // const documentLeft = box.left + window.scrollX;
    // const documentTop = box.top + window.scrollY;




    switch(reference_){
        case 'self':
            return {
                top: `${box.top}`,
                right: box.right,
                bottom: box.bottom,
                left: `${box.left} - ${paddingLeft}`,
                x: box.x,
                y: box.y,

                centerX: null,
                centerY: null
            };
        break;

        case 'document':
            return {
                // top: `${box.top + window.scrollY} - ${paddingTop}`,
                top: documentTop + 'px',

                right: null,
                bottom: null,

                // left: `${box.left + window.scrollX} - ${paddingLeft}`,
                left: documentLeft + 'px',

                x: box.x,
                y: box.y,

                centerX: null,
                centerY: null
            };
        break;

        case 'parent':
            return {
                top:        `${box.top}`     + window.pageYOffset,
                bottom:     box.bottom  + window.pageYOffset,

                right:      box.right   + window.pageXOffset,
                left:       `${box.left}`    + window.pageXOffset,
                x: box.x,
                y: box.y,

                centerX:    box.left    + (box.width / 2),
                centerY:    box.top     + (box.height / 2)
            };
        break;
    }
}

export function isMobile() {
    // return window.innerWidth <= 600
    return window.innerWidth <= 900;
}

// export const isMobile = ()=>{
//     var result:boolean = false;
//     const width = window.innerWidth;
//     if (width<900){
//         result = true;
//     }

//     return result;
// }
// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

export const createCustomElement = (    displayNr?: number, 
                                        displayText?:string,
                                        debug: boolean=false, 
                                        hAlign:T_FX_HORIZONTAL_ALIGN="center", 
                                        vAlign:T_FX_VERTICAL_ALIGN="center"
    ) => {

    if (!!!displayNr) displayNr=0;

  return (
    <div
          key={displayNr}

      style={{
        display: 'flex',
        background: debug ? 'red' : 'inherit', // apenas para debugging
        padding: debug ? '10px' : 'inherit', // apenas para debugging
        border:  debug ? 'solid': 'inherit',
        height: '100%', // full viewport size height
        // width: '100px',
        justifyContent: fxGetCSSHorizontalAlign(hAlign),               // horizontal align
        alignItems: fxGetCSSVerticalAlign(vAlign),                   // vertical align
      }}
    >
      {displayNr} {displayText}
    </div>
  );
};


export const fxGetCSSVerticalAlign = (vAlign?:T_FX_VERTICAL_ALIGN)=>{
    var vAlign_:T_CSS_VERTICAL_ALIGN;
    if (!!!vAlign) return 'center';

    switch (vAlign){
        case 'top':
            vAlign_ = 'flex-start';
        break;
        case 'center':
            vAlign_ = 'center';
        break;
        case 'bottom':
            vAlign_ = 'flex-end';
        break;
        case 'stretch':
            vAlign_ = 'stretch';
        break;
        case 'baseline':
            vAlign_ = 'baseline';
        break;
        default: // TODO
    }
    return vAlign_;
};

export const fxGetCSSHorizontalAlign = (hAlign?:T_FX_HORIZONTAL_ALIGN)=>{
    var hAlign_:T_CSS_HORIZONTAL_ALIGN;
    if (!!!hAlign) return 'center';

    switch (hAlign){
        case 'left':
            hAlign_ = 'flex-start';
        break;
        case 'center':
            hAlign_ = 'center';
        break;
        case 'right':
            hAlign_ = 'flex-end';
        break;
        case 'space-between':
            hAlign_ = 'space-between';
        break;
        case 'space-around':
            hAlign_ = 'space-around';
        break;
        default: // TODO
    }
    return hAlign_;
};

export const convertProportionsFx2Mui = ( 
                                            // itemsQty: number,
                                            proportion: number,
                                            muiMaxValue: number =12
    ) => {
    // const muiMaxValue = 12;
    var result = Math.floor(proportion * muiMaxValue / 100);

    return result;
};


export const convertCssToFxProportions = ( itemsQty: number,
                                    proportions?: number[]
    ) => {
    const itemsNr = itemsQty; // TODO: allow undefined items with remainder space
    const fx_scale = 100;
    const mui_grid_scale = 12;
    var proportions_;
    if (!!proportions) {
        proportions_ = proportions.map((proportion) => {
        const mui_grid_value = (mui_grid_scale * proportion) / fx_scale;
        console.log(`mui_grid_value => ${mui_grid_value}`);
        return mui_grid_value;
        });
    } else {
        // array com proporcoes iguais
        const fraction = mui_grid_scale / itemsNr;
        // proportions_ = [...Array(itemsNr)].map((item) => {
        proportions_ = [...Array(itemsNr)].map(() => {
        return fraction;
        });
    }
    return proportions_;
};

export const fxGetCssTextAlign = (hAlign?:T_FX_HORIZONTAL_ALIGN)=>{

    var textAlign:T_CSS_TEXT_ALIGN;
    if (!!!hAlign) return 'center';

    switch (hAlign){
        case 'left':
            textAlign = 'left';
        break;
        case 'center':
            textAlign = 'center';
        break;
        case 'right':
            textAlign = 'right';
        break;
        case 'stretch':
        case 'space-between':
        case 'space-around':
            textAlign = 'justify';
        break;
        
        default: console.log("ERRO fxGetCssTextAlign")// TODO
    }
    return textAlign;
};

/* ------------------------------------------------------------------------------ */
// Cuida de renderizar um ROW com os elementos e as caracteristicas dos "slots" indicadas
// Reference: https://stackoverflow.com/questions/60440617/create-multiple-react-elements-using-one-function
// export const insertGridItems = (    elements?: JSX.Element[],
//                                     proportions?: number[],
//                                     vAlignments?: T_FX_VERTICAL_ALIGN[],
//                                     hAlignments?: T_FX_HORIZONTAL_ALIGN[],
//                                     debug?: boolean
// ) => {

//     if ((!!!elements)||(elements.length == 0)) {
//         console.log("insertGridItems: no elements, returning null.")
//         return null;
//     }
//     const itemsNr = elements.length;
//     console.log(`insertGridItems: itemsNr => ${itemsNr}`)
//     // TODO: resolve proportions lenght & itemsNr in convertCssToFxProportions
//     const proportions_ = convertCssToFxProportions(itemsNr, proportions); 

//     if (!!!vAlignments) vAlignments=elements.map(()=>'center');
//     if (!!!hAlignments) hAlignments=elements.map(()=>'center');

//     var debug_: boolean;
//     if (!!debug)    debug_ = debug;
//     else            debug_ = false;

//     return elements.map((element, i) => (
//         <Grid
//             item
//             xs={proportions_[i]}
//             key={i}
//             style={{
//                 background:     debug_ ? 'green' : 'inherit',               // apenas para debugging
//                 padding:        debug_ ? '10px' : 'initial',                  // apenas para debugging
//                 justifyContent: fxGetCSSHorizontalAlign(hAlignments![i]),   // horizontal align
//                 alignItems:     fxGetCSSVerticalAlign(vAlignments![i]),     // vertical align
//                 textAlign:      fxGetCssTextAlign(hAlignments![i]),         // usa hAligns para gerar text aligns
//                 width: '100%',
//                 height: '100%',
//             }}
//         >
//             {element}
//         </Grid>
//     ));
// };
/* ------------------------------------------------------------------------------ */

export const RobotoMono = styled('div')({
    // color: 'white',
    // backgroundColor: 'aliceblue',
    // padding: 8,
    // borderRadius: 4,
    fontFamily: 'Roboto Mono'
});

export const setRobotoMono = (text:string)=>{
  return <RobotoMono>{text}</RobotoMono>;
}

export const someText = ()=>{
        return (<>
            <RobotoMono>Lorem ipsum dolor sit amet</RobotoMono>, consectetur adipiscing elit, 
            sed do eiusmod <Box fontWeight={600} display='inline' color='red'>tempor&nbsp;</Box> 
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
            <strong>&nbsp;tempor</strong> incididunt ut labore et dolore magna aliqua. Ut enim ad 
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea 
            commodo consequat. 
        </>)
    }

/* ------------------------------------------------------------------------------ */





// export const insertGridNode = (    node?: React.ReactNode,
//                                     proportions?: number[],
//                                     vAlignments?: T_FX_VERTICAL_ALIGN[],
//                                     hAlignments?: T_FX_HORIZONTAL_ALIGN[],
//                                     debug?: boolean
// ) => {

//     return (
//         <Grid
//             item
//             xs={proportions_[i]}
//             key={i}
//             style={{
//                 background:     debug_ ? 'green' : 'inherit',               // apenas para debugging
//                 padding:        debug_ ? '1%' : 'inherit',                  // apenas para debugging
//                 justifyContent: fxGetCSSHorizontalAlign(hAlignments![i]),   // horizontal align
//                 alignItems:     fxGetCSSVerticalAlign(vAlignments![i]),     // vertical align
//             }}
//         >
//             {element}
//         </Grid>
//     )
// };

// export const insertDebugGridItems = (
//   debugItemsQty?: number,
//   proportions?: number[],
//   vAlignments?: T_FX_VERTICAL_ALIGN[],
//   hAlignments?: T_FX_HORIZONTAL_ALIGN[],
//   debug_?: boolean
//   // counter?: number,
// ) => {
//   var itemsNr: number;
//   var counter_: number = 0;
//   if (!!debugItemsQty) itemsNr = debugItemsQty;
//   else itemsNr = 1;
//   // if (!!counter) counter_ = counter;
//   // else counter_ = 1;

//   const proportions_ = convertCssToFxProportions(itemsNr, proportions); // TODO: resolve proportions lenght & itemsNr in convertCssToFxProportions

//   return [...Array(itemsNr)].map((_x, i) => (
//     <Grid
//       item
//       xs={proportions_[i]}
//       key={i}
//       style={{
//         //   background: debug_ ? 'green' : 'inherit', // apenas para debugging
//         //   padding: debug_? '1%' : 'initial', // apenas para debugging
//         background: 'green',
//         padding: '1%',
//       }}
//     >
//       {/* {createCustomElement((customElementCounter.current += 1))} */}
//       {createCustomElement((counter_ += 1), true)}
//     </Grid>
//   ));
// };




// function drag_start(event: any) {
//     var style = window.getComputedStyle(event.target, null);
//     event.dataTransfer.setData("text/plain",
//     (parseInt(style.getPropertyValue("left"),10) - event.clientX) + ',' + (parseInt(style.getPropertyValue("top"),10) - event.clientY));
// } 
// function drag_over(event: any) { 
//     event.preventDefault(); 
//     return false; 
// } 
// function drop(event: any) { 
//     var offset = event.dataTransfer.getData("text/plain").split(',');
//     var dm = document.getElementById('dragme');
//     if (!!!dm) return;
//     dm.style.left = (event.clientX + parseInt(offset[0],10)) + 'px';
//     dm.style.top = (event.clientY + parseInt(offset[1],10)) + 'px';
//     event.preventDefault();
//     return false;
// } 
// var dm = document.getElementById('dragme'); 
// if (!!dm) dm.addEventListener('dragstart',drag_start,false)
// else console.log("ERRO!!!!!!!!");
// document.body.addEventListener('dragover',drag_over,false); 
// document.body.addEventListener('drop',drop,false); 

