import { Paper, ListItem, Chip, Autocomplete, TextField } from '@mui/material';
import React, { useEffect } from 'react';
// import RestService from '../services/RestService';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN } from '../utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../utils/FxUtils';
import { styled } from '@mui/material/styles';
import TagFacesIcon from '@mui/icons-material/TagFaces';

/**
 * 
 */

// COMPONENT INTERFACE -------------------------------------------------------
    interface ITagListProps {
        id?: string,
        name?: string,
        children?: React.ReactNode,
        height?: string|number,
        width?: string|number,
        debug?: boolean,
        vAlign?: T_FX_VERTICAL_ALIGN;
        hAlign?: T_FX_HORIZONTAL_ALIGN;
        // event01: ()=>void,

        tagList?:string[],
        defaultItemIndex?:number,

        vSpacing?: string
    }
// ---------------------------------------------------------------------------

const FxTagList = (props: ITagListProps)=>{
    // console.log("FxTagList: entering: props => ", props);

    // PROPERTIES PRE-PROCESSING ---------------------------------------
        const id_: string = !!props.id?props.id:'fx-tag-list';
        const name_: string|number = !!props.name?props.name:'fx-tag-list';
        const debug_: boolean = !!props.debug?props.debug:false;
        const height_: string|number = !!props.height?props.height:'100%';
        const width_: string|number = !!props.width?props.width:'100%';
        const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
        const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';

        const tagList_: string[] = !!props.tagList?props.tagList:[];
        const defaultItemIndex_: number = !!props.defaultItemIndex?props.defaultItemIndex:0;

        const vSpacing_: "small" | "medium" | undefined = props.vSpacing=="dense"?"small":undefined;
    // ---------------------------------------------------------------------------

    // const [state01, setState01] = useState<string>('');
    // const [someData, setSomeData] = useState<string>('');
    // const [value, setValue] = React.useState<string | null>(options[0]);
    const [value, setValue] = React.useState<string[] | null>([tagList_[defaultItemIndex_]]);
    const [inputValue, setInputValue] = React.useState('');

    // useEffect(() => {
    //     console.log("FxTagList: entering: useEffect");
    // }, []);

    // const getSomeData = () => {
        // RestService.getSomeData()
        // .then(response => {
        //     setSomeData(response.data);
        //     console.log(response.data);
        // })
        // .catch(e => {
        //     console.log(e);
        // });
    // };



    return (
        <div 
            id={`${id_}-container`}
            style={{
                background:     debug_?'blue':'initial', // apenas para debugging
                border:         debug_?'dashed red 2px':'initial',
                height:         height_,
                width:          width_, 
                justifyContent: cssHAlign,               // horizontal align
                alignItems:     cssVAlign,                   // vertical align
            }}
        >
            {/* <h1>TagList!!</h1>
            {props.children} */}

            <Autocomplete 
                multiple
                freeSolo
                size={vSpacing_}
                id={`${props.id}`}
                // options={top100Films.map((option) => option.title)}
                options={tagList_}
                // defaultValue={[top100Films[13].title]}
                // defaultValue={[tagList_[defaultItemIndex_]]}
                renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                        <Chip 
                            // variant="outlined" 
                            variant="filled" 
                            size={vSpacing_}
                            label={option} 
                            {...getTagProps({ index })} 
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        // variant="standard"
                        // variant="filled"
                        variant='outlined'
                        size={vSpacing_}
                        // label="freeSolo"
                        // label=" "
                        label="Tags"
                        placeholder="Enter new tag..."
                        // FX: this is needed to allow android keyboard 'enter'
                        // https://github.com/mui/material-ui/issues/29429
                        multiline rows={1}
                    />
                )}
                // onChange={(event: any, newValue: string | null) => {
                onChange={(event: any, value: string[], reason: any, details:any) => {
                    setValue(value);
                    console.log(`FxTagList: Autocomplete: onChange: value => `, value);
                }}
                // FX: to capture each char entered:
                // onInputChange={(event: any, value: string, reason: any) => {
                //     // setInputValue(value);
                //     console.log(value);
                // }}
            />

            {/* 
              *  FX: for the sake of this TagList to have a name and
              *  stringified content to conform the way FormData operates,
              *  I added this hidden element:
             */}
            <TextField name={name_}
                id={name_}
                // hidden={true}
                value={JSON.stringify(value)}
                // Event onChenge only occurs when user makes some direct input in the component.
                // Changing state variables will NOT trigger this event!!
                // onChange={(e) => {
                //     // console.log(`FxTagList: TextField: e.target.value => `, e.target.value);
                //     console.log(`FxTagList: TextField: e.target.value => `);
                // }}
                // onInput={(e) => {
                //     console.log(`FxTagList: TextField: e.target.value => `, e.target);
                // }}
                style={{
                    // display: 'none'
                }}
            />
            
        </div>
    )
}

export default FxTagList;
