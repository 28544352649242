// IMPORTS -------------------------------------------------------
    import { Box, Button, Collapse, Typography } from '@mui/material';
    import React, { useEffect, useState } from 'react';
    // import RestService from '../services/RestService';
    import { 
        T_FX_VERTICAL_ALIGN, 
        T_FX_HORIZONTAL_ALIGN, 
        T_CSS_HORIZONTAL_ALIGN, 
        T_CSS_VERTICAL_ALIGN, 
        T_IMAGE_UPLOAD,
        T_UPLOAD_RESPONSE_DATA,
        IFile,
        T_FILE_METADATA
    } from '../utils/FxTypes';
    import { 
        fxGetCSSVerticalAlign, 
        fxGetCSSHorizontalAlign,
    } from '../utils/FxUtils';
    import FxFileUploadDefaultImage from './boring01.jpg';
    import CloudUploadIcon from '@mui/icons-material/CloudUpload';
    // import UploadService from "../services/FileUploadService";
    import axios from "axios";
    import UploadService from "../services/FileUploadService";
// ---------------------------------------------------------------------------

/**
 * Click to open file system ide, or drag file into.
 * File metadata, ff provided, will be inserted into the DB.
 */

// COMPONENT INTERFACE -------------------------------------------------------
    interface IFileUploadProps {
        id?: string,
        children?: React.ReactNode,
        height?: string|number,
        width?: string|number,
        debug?: boolean,
        vAlign?: T_FX_VERTICAL_ALIGN;
        hAlign?: T_FX_HORIZONTAL_ALIGN;
        // event01: ()=>void,

        hoverLabel?: string
        dropLabel?: string
        backgroundColor?: string

        // allowed mime type
        accept?: string  

        // specific REST funtion to deal with this upload, specially if file props given.                   
        baseUrl?: string                   
        endpoint?: string                   
        
        // Start upload right after selection or wait for button click?
        uploadTrigger?: "immediate" | "on-click"

        // If file has metadata to be saved on the DB, inform the props, or
        // provide the form to gather that info.
        fileProps?: {}
        filePropsForm?: React.ReactElement

        // Inform parent when new file is selected (file nav or dragging)
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
        onDrop?: (event: React.DragEvent<HTMLElement>) => void

        // Optional image for drop area background
        isImageButton?: boolean
        buttonImage?: T_IMAGE_UPLOAD

        // Inform parent the response file metadata
        onUploaded?: (filesMetadata: T_FILE_METADATA[])=>void
        onCancel?: ()=>void

    }
// ---------------------------------------------------------------------------

// COMPONENT DEFINITION -------------------------------------------------------
    const FxFileUpload = (props: IFileUploadProps)=>{
        // console.log("FxFileUpload: entering: props => ", props);

        // CONSTANTS AND DEFAULTS ------------------------------------------------------------
            const defaultImageProp: T_IMAGE_UPLOAD = {
                url: FxFileUploadDefaultImage,
                // file: FxFileUploadDefaultImage,
                imageStyle: {
                    height: 'inherit',
                }
            }
            const uploadHeaders = {
                "Content-Type": "multipart/form-data",
            };


            const defaultBaseURL = props.baseUrl?props.baseUrl:"lixo";
        // ---------------------------------------------------------------------------

        // PROPERTIES PRE-PROCESSING ---------------------------------------
            // => INITS, DEFAULTS, VALIDATION AND SANITIZATION
            const id_: string = !!props.id?props.id:"file-upload-component";
            const debug_: boolean = !!props.debug?props.debug:false;
            const height_: string|number = !!props.height?props.height:'10vh';
            const width_: string|number = !!props.width?props.width:'100%';
            const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
            const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';

            const accept_: string = props.accept? props.accept: '*/*';
            const image_: T_IMAGE_UPLOAD = props.buttonImage? props.buttonImage: defaultImageProp;
            const useBackgroundImage: boolean = !!props.buttonImage? true:false;
            const hoverLabel_: string = props.hoverLabel? props.hoverLabel: 'Click or drag to upload file';
            const dropLabel_: string = props.dropLabel? props.dropLabel: 'Drop file here';
            const backgroundColor_: string = props.backgroundColor? props.backgroundColor: 'inherit';
            const endpoint_ = props.endpoint? props.endpoint: "/files/upload";
            // const endpoint_ = props.endpoint? props.endpoint: "/stats";

            // HEIGHTS
                // const containerH    = "1000px";
                // const uploadBoxH   = "200px";
                // const formH         = "500px";
            //
        // ---------------------------------------------------------------------------

        // STATE VARIABLES -----------------------------------------------------------
            const [currentFile,     setCurrentFile      ] = useState<File>();
            const [thumbnail,       setThumbnail        ] = useState<string>("");
            const [progress,        setProgress         ] = useState<number>(0);
            const [message,         setMessage          ] = useState<string>("");

            // Multiple files upload metadata
            // const [fileInfos,       setFileInfos        ] = useState<Array<IFile>>([]);
            const [fileInfos,       setFileInfos        ] = useState<Array<T_FILE_METADATA>>([]);
            const [formData,        setFormData         ] = useState<FormData>();

            // Sub-components show/hide
            const [showUploadBox,   setShowUploadBox      ] = useState<boolean>(true);
            const [showFileFormBox, setShowFileFormBox    ] = useState<boolean>(false);
            const [showThumbnailBox, setShowThumbnailBox  ] = useState<boolean>(false);

            const [isDragOver,      setIsDragOver       ] = useState<boolean>(false);
            const [isMouseOver,     setIsMouseOver      ] = useState<boolean>(false);
            const [labelText,       setLabelText        ] = useState<string>(hoverLabel_);

            // Background image
            const [imageUrl,        setImageUrl         ] = useState<string>(image_.url);
            // const [imageUrl,        setImageUrl         ] = useState<string>(image_.file);
        // ---------------------------------------------------------------------------

        // CONTROL FUNCTIONS ---------------------------------------------------------
            // useEffect(() => {
            //     const container = document.getElementById("image-upload-component");
            //     console.log(`container => `, container);

            //     const formContainer = document.getElementById("file-metadata-form-box");
            //     console.log(`formContainer => `, formContainer);

            //     if ((!!container)&&(!!formContainer)) {
            //         const totalH = container.scrollHeight;
            //         // const totalH = container.scrollHeight + formContainer.scrollHeight;
            //         container.style.height = totalH+"px";
            //         console.log(`container.scrollHeight => ${container.scrollHeight}`);
            //         console.log(`formContainer.scrollHeight => ${formContainer.scrollHeight}`);
            //         console.log(`totalH => ${totalH}`);
            //     }

            // }, [showFileFormBox]);
            // // }, []);

            const stopDefaults = (e: React.DragEvent) => {
                e.stopPropagation()
                e.preventDefault()
            }

            // Load a file into memory (original purpose: show image thumbnail)
            const loadFile = async (fileMetadata: File)=>{
                const fileReader = new FileReader();
                fileReader.onload = async (e: ProgressEvent<FileReader>) => {
                    // return fileReader.result;

                    if (typeof fileReader.result == "string") {
                        // console.log(`setting thumbnail...`);
                        setThumbnail(fileReader.result); // valid for readAsDataUrl

                        // 
                        // console.log(`loadFile: uploadTrigger_ => ${uploadTrigger_}`);
                        // console.log(`loadFile: currentFile => ${!!!currentFile}`);
                        // if ((uploadTrigger_ == "immediate")&&(!!!currentFile)) {
                        //     upload();
                        // }
                    }
                };

                fileReader.readAsDataURL(fileMetadata); 
            }
        

            const onFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
                console.log(`FxFileUpload: onFileSelected: entering: event => `, event);

                const { files } = event.target;
                const selectedFiles = files as FileList;

                // Update file infos
                setCurrentFile(selectedFiles?.[0]);

                // Update items to show
                setShowUploadBox(false);
                setShowThumbnailBox(true);
                setShowFileFormBox(true);
                setProgress(0);

                // Show file thumbnail
                loadFile(selectedFiles?.[0]); 

                // // Hide Upload Box when file was selected
                // setShowUploadBox(false);

                // // Automatically start upload (with useEffect!)
            }

            const getFormDataEntries = (fd: FormData) => {
                const formDataEntries = fd.entries();
                const formDataArray = [];
                let result = formDataEntries.next();
                formDataArray.push(result);

                while (!result.done) {
                    console.log(result.value);
                    result = formDataEntries.next();
                    formDataArray.push(result);
                }

                return formDataArray;
            }

            // const upload = (
                //     file_: File, 
                //     fileProps_?: {},
                //     endpoint_?: string,
                //     onUploadProgress_?: any
                // ) => {
                    
                //     setProgress(0);

                //     if (!!!currentFile) return console.log(`currentFile unavailable`) ;
                //     console.log(`FxFileUpload: upload(): currentFile.name => ${currentFile.name}`);
                //     console.log(`FxFileUpload: upload(): endpoint_ => ${endpoint_}`);
                //     // console.log(`fileProps_ => `);
                //     // console.log(fileProps_);

                //     const onUploadProgress = (event: any) => {
                //         setProgress(Math.round((100 * event.loaded) / event.total));
                //     };

                //     UploadService.upload(
                //         currentFile, 
                //         fileProps_,
                //         endpoint_,
                //         onUploadProgress
                //     )
                //     .then((response) => {
                //         console.log(`FxFileUpload: upload(): response received...`)
                //     //     // Update the message field with the server's response message
                //     //     setMessage(response.data.message);

                //     //     // TODO:
                //     //     // (optional) Get the file UUID from the server's response message
                //     //     // const uuid = response.data.uuid;
                //     //     const fxTest = response.data.fxTest;
                //     //     console.log(`${fxTest}`);

                //     //     // (optional) Update the uploaded files list
                //     //     return UploadService.getFiles();
                //     })
                //     // .then((files) => {
                //     //     setFileInfos(files.data);
                //     // })
                //     // .catch((err) => {

                //     //     console.log(`FxFileUpload: upload(): ERROR received...`)
                //     //     setProgress(0);

                //     //     if (err.response && err.response.data && err.response.data.message) {
                //     //         setMessage(err.response.data.message);
                //     //     } else {
                //     //         setMessage("Could not upload the File!");
                //     //     }

                //     //     setCurrentFile(undefined);
                //     // });
            // };

            const submitHandler_TEMP = async (event: any)=>{
                // console.log(`FxFileUpload: submitHandler_TEMP(): event => ${event}`);

                // IMPORTANT: This prevents the browser to redirect to the result of form submission.            
                event.preventDefault(); 

                // Get the fullfilled form element.
                const form = event.target;
                const formData_ = new FormData(form);

                const formDataEntries = getFormDataEntries(formData_);
                // name                 value
                // ['title',           'dghgh']
                // ['description',     'dghdhg']
                // ['author',          'fsdadf']
                // ['place_name',      'dhtth']
                // ['tags',            '["dgr"]']
                // ['rating',          '5']
                // ['uploaded_file',   File]



                // DEBUG:
                    // Not working in typescript
                    // for (var key in formData) {
                    //     console.log(key, formData[key]);
                    //     // fd.append(key, formData[key]);
                    // }

                    // Option 1:
                    // REF: https://stackoverflow.com/questions/71384018/typing-object-fromentriesnew-formdataform
                    // const result = Object.fromEntries([...formData].map(([key, value]) => [
                    //     key,
                    //     key === 'age' ? +value : value
                    // ]))
                    // console.log(result);

                    // Option 2:
                    // console.log(...formData);
                // ------------

                // formData.append("tags_X", JSON.stringify())

                // Debug:
                    // const x = formData.entries();
                    // const y = Object.values(formData);

                    // for (let key in formData) {
                    //     console.log(key, formData[key]);
                    // }

                    const formFields = form.elements;
                    const imgTitle = formFields.imgTitle.value;
                    const imgDescription = formFields[3].value;
                // ------------

                // console.log(`defaultBaseURL => `, defaultBaseURL);
                // console.log(`endpoint_ => `, endpoint_);
                // console.log(`formData => `, formData_);
                // Send form via axios
                axios({
                    baseURL:            defaultBaseURL,
                    url:                endpoint_,
                    method:             "POST",
                    headers:            uploadHeaders,
                    onUploadProgress:   onUploadProgress,
                    data:               formData_,
                    // params:          fileProps_
                })
                .then((response) => {
                    // console.log(`FxFileUpload: upload(): response => `, response);

                    const customFields: T_UPLOAD_RESPONSE_DATA  = response.data;
                    const status                                = response.status;
                    const statusText                            = response.statusText;

                    // customFields.result => {
                    //     "uuid": "960c36ac-0296-4585-aa3f-ebab6414ea28",
                    //     "name": "960c36ac-0296-4585-aa3f-ebab6414ea28.png",
                    //     "originalName": "oracle.png",
                    //     "path": "/Users/fx/WORK/FXon/Work/Software/FX/fx-site-backend/assets/images",
                    //     "mime": "image/png",
                    //     "size": 8695,
                    //     "updatedAt": "2023-05-20T16:26:02.615Z",
                    //     "createdAt": "2023-05-20T16:26:02.615Z"
                    // }
                    const result    = customFields.result;
                    const message   = customFields.message;
                    const fxTest    = customFields.fxTest;

                    // Update the message field with the server's response message
                    // setMessage(message); // FX: message gone thru snackbar
                    setShowFileFormBox(false);
                    setShowUploadBox(true); // FX: reset to initial state
                    setProgress(0);
                    setShowThumbnailBox(false);

                    // FX: first, trying with a single file (TODO: multiple files)
                    const files = [result];

                    // (optional) Update the uploaded files list
                    // return UploadService.getFiles();
                // })
                // .then((files) => {
                    // console.log(`Files list => `, files.data);
                    // setFileInfos(files.data);
                    console.log(`Files => `, files);
                    setFileInfos(files);
                    if (!!props.onUploaded) props.onUploaded(files);
                    // fileInfos => [{
                    //     "uuid": "9e0500c8-c8e6-4fcd-8508-fc03374cc224",
                    //     "name": "9e0500c8-c8e6-4fcd-8508-fc03374cc224.png",
                    //     "originalName": "niobe.png",
                    //     "path": "/Users/fx/WORK/FXon/Work/Software/FX/fx-site-backend/assets/images",
                    //     "mime": "image/png",
                    //     "size": 30724,
                    //     "updatedAt": "2023-05-23T20:05:37.253Z",
                    //     "createdAt": "2023-05-23T20:05:37.253Z"
                    // }]
                })
                .catch((err) => {

                    console.log(`FxFileUpload: upload(): ERROR received...`, err);
                    setProgress(0);

                    if (err.response && err.response.data && err.response.data.message) {
                        setMessage(err.response.data.message);
                    } else {
                        setMessage("Could not upload the File!");
                    }

                    setCurrentFile(undefined);
                });

                // try {
                    //     const uploadResponse = await axios({
                    //         baseURL: defaultBaseURL,
                    //         url: endpoint_,
                    //         method: "POST",
                    //         headers: uploadHeaders,
                    //         // onUploadProgress: onUploadProgress_,
                    //         data: formData,
                    //         // params: fileProps_
                    //     });
                    //     console.log(uploadResponse);

                    //     const status = uploadResponse.status;
                    //     const statusText = uploadResponse.statusText;
                    //     const customFields: T_UPLOAD_RESPONSE_DATA = uploadResponse.data;
                    //     const message = customFields.message;
                    //     const fxTest = customFields.fxTest;

                    //     // Update the message field with the server's response message
                    //     setMessage(message);

                    // } catch (err){
                    //     console.log(err);
                // }
            }

        // ---------------------------------------------------------------------------

        // EVENTS CONTROL -----------------------------------------------------------
            const dragEvents = {
                onMouseEnter: () => {
                    setIsMouseOver(true)
                },
                onMouseLeave: () => {
                    setIsMouseOver(false)
                },
                onDragEnter: (e: React.DragEvent) => {
                    stopDefaults(e)
                    setIsDragOver(true)
                    setLabelText(dropLabel_)
                },
                onDragLeave: (e: React.DragEvent) => {
                    stopDefaults(e)
                    setIsDragOver(false)
                    setLabelText(dropLabel_)
                },
                onDragOver: stopDefaults,
                onDrop: (e: React.DragEvent<HTMLElement>) => {
                    stopDefaults(e)
                    setLabelText(dropLabel_)
                    setIsDragOver(false)
                    if (useBackgroundImage && e.dataTransfer.files[0]) {
                        setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]))
                    }

                    // If any additional actions from parent, execute now.
                    if (!!props.onDrop) props.onDrop(e);
                },
            }

            const noMouseEvent = {
                pointerEvents: 'none',
            };

            const iconText = {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
            };

            const hidden = {
                display: 'none',
            };

            const onUploadProgress = (event: any) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            };
        // ---------------------------------------------------------------------------

        // STYLES -------------------------------------------------------------------
            const uploadBoxStyle: React.CSSProperties = {
                cursor: 'pointer',
                textAlign: 'center',
                display: 'flex',
                // background: isMouseOver?'green':'inherit',
                background: "darkgrey",
                opacity: isMouseOver? 1:0.5,
                height: "100%",
                // --------
                    // @ts-ignore
                    // '&:hover p,&:hover svg,& img': {
                    //     opacity: 1,
                    //     background: 'blue',
                    // },
                    // @ts-ignore
                    // '& p, svg': {
                    //     opacity: 0.4,
                    //     backgroundColor: 'green',
                    // },
                    // @ts-ignore
                    // '&:hover img': {
                    //     opacity: 0.3,
                    //     backgroundColor: 'red',
                    // },

                    // onDragOver:
                    // '& img': {
                    //     opacity: 0.3,
                    //     backgroundColor: 'blue',
                    //     background: "blue",
                    // },
                    // '& p, svg': {
                    //     opacity: 1,
                    // },
                // --------
            }
        // ---------------------------------------------------------------------------

        // SUB COMPONENTS ---------------------------------------------------------
            const uploadBox = (show: boolean)=>{
                const show_ = show?'inherit':"none";

                return (
                    <div 
                        id="upload-box"
                        style={{
                            display: show_,
                            height: height_,
                        }}
                    >
                        <input
                            id="file-upload-input"
                            onChange={onFileSelected}
                            accept={accept_}
                            style={hidden}
                            type="file"
                            name="uploaded_file"
                        />

                        <label
                            id="file-upload-label"
                            htmlFor="file-upload-input"
                            style={uploadBoxStyle}
                            {...dragEvents}
                        >
                            <Box
                                width={width_}
                                height="100%"
                                bgcolor={backgroundColor_}
                                sx={noMouseEvent}
                            >
                                {useBackgroundImage && (
                                    <Box 
                                        position="absolute" 
                                        height="100%"
                                        width={width_}
                                    >
                                        <img
                                            alt="file upload"
                                            src={imageUrl}
                                            style={image_.imageStyle}
                                        />
                                    </Box>
                                )}

                                {(!useBackgroundImage || isDragOver || isMouseOver) && (
                                    <Box
                                        id="file-upload-label-text"
                                        height={height_}
                                        width={width_}
                                        // className={classes.iconText}
                                        sx={iconText}
                                    >
                                        <CloudUploadIcon fontSize="large" />
                                        <Typography>{labelText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </label>
                    </div>
                );
            }

            const formBox =  (show?: boolean)=>{
                // // const container = document.getElementById(id_);
                // const container = document.getElementById("image-upload-component");
                // console.log(`container => `, container);
                // // container?.setAttribute("height", "1000px");
                // // container?.style.height = content.scrollHeight + "px";
                // if (!!container) container.style.height = "1000px";

                // const x: JSX.Element = <Collapse 
                return <div
                    id="form-container"
                    style={{
                        height: 'auto',
                        maxHeight: '20vh',
                        overflowY: 'scroll',
                    }}
                >
                    <div
                        style={{
                            overflow: 'auto',
                        }}
                    >
                        <Collapse 
                            id = "file-metadata-form-box"
                            orientation="vertical" 
                            in={showFileFormBox}
                        >
                            {props.filePropsForm}
                        </Collapse>
                    </div>
                </div>
                
                
                
                // if (show) return props.filePropsForm
                // else return;        
            }

            const thumbnailBox =  (show: boolean)=>{
                if (show) return (
                    <img 
                        src={thumbnail} 
                        // FX: IMPORTANTE: efeito de height aqui e em style sao completamente diferentes!!!
                        // height="50vh" 
                        style={{
                            margin: "auto",
                            textAlign: "center",
                            verticalAlign: "center",
                            height: "30vh", // scroll issue
                            maxHeight: "20vh",
                        }}
                        alt="File-upload preview..."
                    />); 
                else return;
            }

            const handleOnCancel = ()=>{
                setThumbnail("");
                setShowThumbnailBox(false);
                setShowUploadBox(true); // FX: reset to initial state
                setProgress(0);
                setShowFileFormBox(false);

                // Ask parent component to hide this.
                if (!!props.onCancel) props.onCancel();
            }

            const submitButtonBox = (show: boolean)=>{
                const show_ = show?'inherit':"none";

                return (<>
                    <div style={{display: show_}}>
                        <Button type="submit" >
                            SUBMIT
                        </Button>            
                    </div>
                    <div style={{display: show_}}>
                        <Button type="button" onClick={handleOnCancel}>
                            CANCEL
                        </Button>            
                    </div>
                    </>
                );
            }

            const messageBox = (show: boolean)=>{
                if (show) return(
                    <div className="alert alert-secondary mt-3" role="alert">
                        {message}
                    </div>)
                else return;
            }

            const progressBox = (show: boolean)=>{
                if (show) return(
                    <div className="progress my-3">
                        <div
                            className="progress-bar progress-bar-info"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ width: progress + "%" }}
                        >
                            {progress}%
                        </div>
                    </div>)
                else return;
            }

            const fileInfosBox = (show: boolean)=>{
                if (show) return(
                    <div className="card mt-3">
                        <div className="card-header">List of Files</div>
                        <ul className="list-group list-group-flush">
                        {fileInfos &&
                            fileInfos.map((file, index) => (
                            <li className="list-group-item" key={index}>
                                <a href={file.originalName}>{file.name}</a>
                            </li>
                            ))}
                        </ul>
                    </div>
                )
                else return;
            }

            
        // ---------------------------------------------------------------------------

        // RENDER --------------------------------------------------------------------
            return (
                <div 
                    id={id_}
                    style={{
                        background:     debug_?'blue':'initial',    // apenas para debugging
                        border:         debug_?'dashed red 2px':'initial',
                        height:         "auto",
                        width:          width_, 
                        justifyContent: cssHAlign,                  // horizontal align
                        alignItems:     cssVAlign,                  // vertical align
                    }}
                >

                    <Box 
                        id="file-upload-box-container"
                        component="form" 
                        onSubmit={submitHandler_TEMP} 
                        // action="http://localhost:8090/api/stats"
                        // https://pt.stackoverflow.com/questions/184732/para-que-serve-o-em-form-action-method-post
                        // action="."
                        encType="multipart/form-data"
                        method="post"
                        height="100%"
                    >
                
                        {/* Show before a file is selected. 
                            The upload box MUST be kept inside the form, 
                            so the multipart file can be correctly attached */}
                        { uploadBox(showUploadBox) }

                        {/* Show thumbnail only after a file is selected. */}
                        { thumbnailBox(showThumbnailBox) }    

                        { formBox(showFileFormBox) }       

                        { submitButtonBox(showFileFormBox) }

                    </Box>     

                    { progressBox(progress>0) }

                    {/* { messageBox(message!="") }  */}

                    {/* { fileInfosBox(false) } */}

                    {/* { submitButtonBox(showFileFormBox) } */}
                      
                </div>
            )
        // ---------------------------------------------------------------------------
    }
// ---------------------------------------------------------------------------

// EXPORTS -------------------------------------------------------
    export default FxFileUpload;
// ---------------------------------------------------------------------------
