//* Ref:    https://mattboldt.github.io/typed.js/
//*         https://jsfiddle.net/mattboldt/60h9an7y/

import { Typography } from '@mui/material';
import * as React from 'react';
// import { MutableRefObject } from 'react';
import Typed from 'typed.js';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN } from '../utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../utils/FxUtils';


interface ITypedProps {
    id?: string;
    vAlign?: T_FX_VERTICAL_ALIGN;
    hAlign?: T_FX_HORIZONTAL_ALIGN;
    debug?: boolean;
    phrases?: string[];
    typeSpeed?: number;
    backSpeed?: number;
    showCursor?: boolean;
    cursorChar?: string;

    fontSize?: number;
    // event01: ()=>void
    height?: string|number;
    width?: string|number;
    padding?: string | number;
    paddingTop?: string | number;
    paddingBottom?: string | number;
    paddingLeft?: string | number;
    paddingRight?: string | number;
    margin?: string | number;
    marginTop?: string | number;
    marginBottom?: string | number;
    marginLeft?: string | number;
    marginRight?: string | number;

    cmd?: string;
    // https://hamidrezanazemi.medium.com/calling-childs-function-from-parent-with-react-hooks-f3209193657d
    // control?: (cmd: string)=>boolean;
    // control?: React.MutableRefObject<typeof FxTyped|null>;
}


const FxTyped = (props: ITypedProps) => {
    // console.log(`typed props =>`,  props);
    // Create reference to store the DOM element containing the animation
    const containerRef = React.useRef(null);
    // Create reference to store the Typed instance itself
    const typed = React.useRef<any>(null);

    var debug_:boolean = false;
    if (!!props.debug) debug_ = props.debug;

    var cssVAlign:T_CSS_VERTICAL_ALIGN = 'center';
    if (!!props.vAlign) cssVAlign = fxGetCSSVerticalAlign(props.vAlign);
    
    var cssHAlign:T_CSS_HORIZONTAL_ALIGN = 'center';
    if (!!props.hAlign) cssHAlign = fxGetCSSHorizontalAlign(props.hAlign);

    var fontSize_:number = 24;
    if (!!props.fontSize) fontSize_ = props.fontSize;

    var width_: string | number = '100%';
    if (!!props.width) width_ = props.width;

    var height_: string | number = '100%';
    if (!!props.height) height_ = props.height;



    var phrases_: string[] = [
                'Causas nobres ==> efeitos incríveis',
                'Seja bem-vindo!'
            ];
    if (!!props.phrases) phrases_ = props.phrases;
    // console.log(`FxTyped: phrases_ => `, phrases_);


    var typeSpeed_:number = 20;
    if (!!props.typeSpeed) typeSpeed_ = props.typeSpeed;

    var backSpeed_:number = !!props.backSpeed? props.backSpeed: 20;
    var showCursor_:boolean = !!props.showCursor? props.showCursor: true;
    var cursorChar_:string = !!props.cursorChar? props.cursorChar: '_';



    React.useEffect(() => {
        const options = {
            // stringsElement: '#typed-strings',
            // strings: [
            //     'Some <i>strings</i> are slanted',
            //     'Some <strong>strings</strong> are bold',
            //     'HTML characters &times; &copy;'
            // ],
            strings: phrases_,
            // attr
            // contentType
            typeSpeed: typeSpeed_,
            backSpeed: backSpeed_,
            showCursor: showCursor_,
            cursorChar: cursorChar_,
            // shuffle: true,
            // smartBackspace: false, //only backspace what doesn't match the previous string
            // loop: true,
            // loopCount: number : amount of loops
            // startDelay: number, time before backspacing in milliseconds
            // backDelay: number 
            // fadeOut: Fade out instead of backspace
            // fadeOutClass (string) : css class for fade animation
            // fadeOutDelay (boolean) : Fade out delay in milliseconds
            // bindInputFocusEvents(boolean) : bind to focus and blur if el is text input
            // onBegin: function(self) { prettyLog('onBegin ' + self) },
            // onComplete: function(self) { prettyLog('onCmplete ' + self) },
            // preStringTyped: function(pos, self) { prettyLog('preStringTyped ' + pos + ' ' + self); },
            // onStringTyped: function(pos, self) { prettyLog('onStringTyped ' + pos + ' ' + self) },
            // onLastStringBackspaced: function(self) { prettyLog('onLastStringBackspaced ' + self) },
            // onTypingPaused: function(pos, self) { prettyLog('onTypingPaused ' + pos + ' ' + self) },
            // onTypingResumed: function(pos, self) { prettyLog('onTypingResumed ' + pos + ' ' + self) },
            // onReset: function(self) { prettyLog('onReset ' + self) },
            // onStop: function(pos, self) { prettyLog('onStop ' + pos + ' ' + self) },
            // onStart: function(pos, self) { prettyLog('onStart ' + pos + ' ' + self) },
            // onDestroy: function(self) { prettyLog('onDestroy ' + self) }
        };

        // console.log(`FxTyped: options => `, options);

        // elRef refers to the <span> rendered below
        if (!!containerRef.current) typed.current = new Typed(containerRef.current, options);

        // if (!!props.control) props.control.current = control_;

        return () => {
            // Make sure to destroy Typed instance during cleanup
            // to prevent memory leaks
            typed.current.destroy();
        }
    }, [phrases_]);
    
    // Invert start/stop typing
    // const control_ = (cmd:string)=>{
    //     typed.current.toggle();
    //     typed.current.start();
    //     typed.current.stop();
    //     typed.current.reset();
    //     typed.current.destroy();
    // }

    return (

    <div
        id = {`${props.id}-container`}
        style={{
            background: debug_? '#cacaca':'initial', // apenas para debugging
            // padding:      debug_ ? '10px' : props.padding, // apenas para debugging
            border:debug_?'dashed black 2px':'initial',
            height: height_,                         // full viewport size height
            width: width_,
            justifyContent: cssHAlign,               // horizontal align
            alignItems: cssVAlign,                   // vertical align
            // padding: props.padding,
            // padding: '10% !important',
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom,
            paddingLeft: props.paddingLeft,
            paddingRight: props.paddingRight,
            margin: props.margin,
            marginTop: props.marginTop,
            marginBottom: props.marginBottom,
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
        }}
    >
        <span 
            id={props.id}
            style={{ 
                // whiteSpace: 'pre' 
                whiteSpace: 'pre-line' 
                // whiteSpace: 'normal' 
            }} 
            ref={containerRef} 
        />
    </div>

    );
}

export default FxTyped;
