import React, { useRef } from 'react';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN } from '../utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../utils/FxUtils';
import "./portfolio.css";



interface IPortfolio {
    id:string;
    // key:number|string;
    debug?: boolean;
    height?: string|number;
    width?: string|number;
    vAlign?: T_FX_VERTICAL_ALIGN;
    hAlign?: T_FX_HORIZONTAL_ALIGN;
    
    overline?: string;
    title?: string;
    synopsis?: JSX.Element;
    keywords?: string[];
    links?: JSX.Element[];
    imageSrc?: string;
    imageHeight?: string|number;
    imageWidth?: string|number;
}

const FxPortfolio = (props: IPortfolio)=>{
    // console.log("FxPortfolio: entering: props => ", props);

    const debug_: boolean = !!props.debug?props.debug:false;
    const height_: string|number = !!props.height?props.height:'100%';
    const width_: string|number = !!props.width?props.width:'100%';
    const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
    const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
    const vAlign_: T_FX_VERTICAL_ALIGN = !!props.vAlign?props.vAlign:'center';
    const hAlign_: T_FX_HORIZONTAL_ALIGN = !!props.hAlign?props.hAlign:'center';

    const overline_: string = !!props.overline?props.overline:'Missing overline';
    const title_: string = !!props.title?props.title:'Missing title';
    const synopsis_: JSX.Element = !!props.synopsis?props.synopsis:<div>Missing synopsis</div>;
    const keywords_: string[] = !!props.keywords?props.keywords:['Missing keywords'];
    const links_: JSX.Element[] = !!props.links?props.links:[<div>Missing links</div>];
    const imageSrc_: string = !!props.imageSrc?props.imageSrc:'./assets/images/logo_fx_alpha_black_no_margin.svg';
    const imageHeight_: string|number = !!props.imageHeight?props.imageHeight:'700';
    const imageWidth_: string|number = !!props.imageWidth?props.imageWidth:'900';

    // const projectUrl = `https://fxon.work/portfolio/demos/${props.id}`;
    const projectUrl = `${process.env.PUBLIC_URL}/portfolio/demos/${props.id}`;

    const counter = useRef<number>(1);
    counter.current = counter.current + 1;

    return (<>
            <div className="portfolio-content" key={`${props.id}-portfolio-content`}>
                <div className="project-overline">
                    {props.overline}
                </div>
                
                <div className="project-title">
                    <a 
                        href={projectUrl} 
                        rel="noopener noreferrer" 
                        target="_blank"
                    >
                        {title_}
                    </a>
                </div>
                
                <div className="project-synopsis" key={`${props.id}-project-synopsis`}>
                    {synopsis_}
                </div>

                <ul 
                    className="project-tech-list" 
                >
                    {keywords_.map((item:string)=> <li key={item}>{item}</li>)}
                </ul>

                <div className="project-links">
                    {links_}
                </div>
            </div>

            <div className="portfolio-image">
                {/* Make the image clickable, loading the specific project page */}
                <a href={projectUrl} rel="noopener noreferrer" target="_blank">
                    <div 
                        id='fx-portfolio-img-wrapper'
                        className="portfolio-image-wrapper portfolio-image-wrapper-constrained img"
                    >
                            <img 
                                id="project_image"
                                alt="" 
                                role="presentation" 
                                aria-hidden="true" 
                                // src="data:image/svg+xml;charset=utf-8,%3Csvg height='438' width='700' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" 
                                src={`data:image/svg+xml;charset=utf-8,%3Csvg height='${imageHeight_}' width='${imageWidth_}' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E`}
                                style={{
                                    maxWidth: '100%',
                                    display: 'block',
                                    position: 'static',
                                    // borderRadius: '10px',
                                    // border: 'black 1px solid',
                                }}
                            />
                        {/* </div> */}
                        {/* FX: thumbnail????
                        <img 
                            aria-hidden="true" 
                            data-placeholder-image="" 
                            decoding="async" 
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBklEQVQoz23O7W6bMBQGYO5iSoEY7GBjY2MwH/6AJk3SrStVlQbabJq23f9lTCRR1x+VHllH57zHtie76VOZmbidhDuf9vOMl7vxgyl3k7Bj2b/uH093D6dm89rfv3X7N2Gny/Rj3hP2+J+Zcf2iuvHv7x/jdHo5vv359fM4nrL2Ov2Y94R5eSfdUXZj4UbpRqaP3BxzN3Izk+4qn9+45j2uD1wfhDlwc5BuLLqp6Maz6XLLpX4vcnvk5zA3B4+3z0I/0XpgzROrh7Sa0Xq4dLL2Oa0er+qBzc2B1XOeNoPH6iHhOiblTSQT0SNuAFE+Uj4sfVjG1CTCkWIdEwNQuYjLm7j0oQqQiqjziHpIWJPr9ZI6IDa0vaftGkoXi36ZuiXRMe9w+ZVXD7zdFlUPM+OjClJDilsvVd8xazBvF7EE1LF6l5s9re6R3GK5BakNsUGpw/ldVN5JZRKpfVQBXENmPVJ+A6hEpFlEwk8aLG+pcii3ATbBqvGhilKbFDuidqxaA96F2IH5RyYkxlvJPYhFKjdLogOssVpz2ydNH3MN0yZMKjAvb0W9E/WGlBvIbudNrENqvVW+A7Egol/AMsQaFWskWsRNIjrITLCqQGri1BLek9zBrLmBlT9Ty6T1VmIbRDxaqS9hFiRNgFsf1T6q3oHUQNbHqQXMwsxA2kbUAKLDpP0H4Daf056mKVMAAAAASUVORK5CYII=" 
                            alt="" 
                            style={{
                                opacity: 0,
                                transition: 'opacity 500ms linear 0s',
                                objectFit: 'cover',
                            }}
                        /> */}
                        {/* <picture> */}
                            {/* <source 
                                type="image/avif" 
                                // srcset="/static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/9aa63/halcyon.avif 175w, /static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/e6db6/halcyon.avif 350w, /static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/f47db/halcyon.avif 700w" 
                                sizes="(min-width: 700px) 700px, 100vw"
                            />
                            <source 
                                type="image/webp" 
                                // srcset="/static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/240e7/halcyon.webp 175w, /static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/faefe/halcyon.webp 350w, /static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/d5dc4/halcyon.webp 700w" 
                                sizes="(min-width: 700px) 700px, 100vw"
                            /> */}
                            <img 
                                width={width_} 
                                height={height_} 
                                data-main-image="" 
                                sizes="(min-width: 700px) 700px, 100vw" 
                                decoding="async" 
                                src={imageSrc_}
                                // src="/static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/d2194/halcyon.png" 
                                // srcset="/static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/cebcc/halcyon.png 175w, /static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/272cf/halcyon.png 350w, /static/3b4d6e8f44baf7e6d7a0ed4b3e4d8d39/d2194/halcyon.png 700w" 
                                alt={title_} 
                                style={{
                                    objectFit: 'cover',
                                    opacity: 1,
                                    overflow: 'hidden',
                                    borderRadius: '10px',
                                    // border: 'black 1px solid',
                                }}
                            />
                        {/* </picture> */}
                    </div>
                </a>
            </div>
     </>)
}

export default FxPortfolio;

