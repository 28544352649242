import { PeopleSharp, TurnLeftTwoTone } from '@mui/icons-material';
import { Opacity, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import RestService from '../services/RestService';
import { T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_FX_VERTICAL_ALIGN } from '../utils/FxTypes';
import { fxGetPosition, fxGetCSSHorizontalAlign, fxGetCSSVerticalAlign } from '../utils/FxUtils';
// import logo from './assets/images/code02.png';
// const logo2 = require("./assets/images/logo_fx_alpha_black_no_margin.svg")
import logoBlack from './assets/images/logo_fx_alpha_black_no_margin.svg';
import logoWhite from './assets/images/logo_fx_alpha_white_no_margin.svg';

/**
 * Description
 */


// Header
// Rendered size:	159 × 75 px
// Intrinsic size:	288 × 150 px
// Body
// Rendered size:	295 × 153 px
// Intrinsic size:	288 × 150 px
interface ILogoProps {
    id?: string,
    vAlign?: T_FX_VERTICAL_ALIGN,
    hAlign?: T_FX_HORIZONTAL_ALIGN,
    debug?: boolean,
    // height?: string|number,
    scale?: string|number,
    minWidth?: string|number,
    padding?: string | number,
    paddingTop?: string | number,
    paddingBottom?: string | number,
    paddingLeft?: string | number,
    paddingRight?: string | number,
    margin?: string | number,
    marginTop?: string | number,
    marginBottom?: string | number,
    marginLeft?: string | number,
    marginRight?: string | number,
    // event01: ()=>void
    animation?: string,
    visible?: 'visible' | 'hidden' | 'collapse',
    // frame?: 'visible' | 'hidden',
    showPosition?: boolean,
    morphs?: any,
    logoRef?: React.MutableRefObject<null>,
    opacity?: string,
    position?: any,
    link?: string,
    onClick?: ()=>void,
    onHover?: ()=>void,
    cursor?: string,
    // theme?: string;
    // ready?: (value:boolean)=>void,
    ready?: ()=>void,
}

// const FxLogo = (props: ILogoProps)=>{
const FxLogo = (props: ILogoProps)=>{
    // console.log("FxLogo: entering: props => ", props);
    const theme = useTheme();

    // console.log(theme.palette.mode);
    const debug_:boolean = !!props.debug?props.debug:false;
    const cssVAlign:T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
    const cssHAlign:T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
    // const height_: string | number = !!props.height?props.height:"100%";
    const scale_: string | number = !!props.scale?props.scale:"100%";
    const animation_: string = !!props.animation?props.animation:"animated tada";
    // const visible_: 'visible' | 'hidden' | 'collapse' = !!props.visible?props.visible:'visible';
    const showPosition_:boolean = !!props.showPosition?props.showPosition:false;


    // https://www.kindacode.com/article/react-get-the-width-height-of-a-dynamic-element/
    // https://stackoverflow.com/questions/61864491/reactjs-get-height-of-a-div-image-using-react-hooks
    const handleImageLoad = (event:any) => {
        // Do whatever you want here
        // const imageHeight = event.target.clientHeight;
        // if (!!props.ready) props.ready(true);
        if (!!props.ready) props.ready();
    }

    // const logoImg = theme.palette.mode=='light'?'./assets/images/logo_fx_alpha_black_no_margin.svg':'./assets/images/logo_fx_alpha_white_no_margin.svg'
    // const logoImg = theme.palette.mode=='light'?'/assets/images/logo_fx_alpha_black_no_margin.svg':'/assets/images/logo_fx_alpha_white_no_margin.svg'
    const logoImg = theme.palette.mode=='light'?logoBlack:logoWhite;

    return (
        <div
            id={`${props.id}-container`}
            // id={`${props.id}`}
            // ref={props.logoRef}

            className={animation_}
            // {...props.morphs.container}
            style={{
                display:     'flex',
                // position:       'static',
                // position:       'relative', 
                // position:       'absolute', 
 
                // FX temp:
                background:     debug_?'red':'initial', // apenas para debugging
                // border:         debug_?'dashed black 2px':'initial',

                // padding:     debug_?'10px' : props.padding, // apenas para debugging
                height:         '100%',
                // minHeight: '200px',
                // height:         height_,
                // height:         scale_,
                width: '100%',
                // minWidth: '200px',
                minWidth: props.minWidth,
                justifyContent: cssHAlign,               // horizontal align
                alignItems:     cssVAlign,   
                // padding: '2%', 
                padding: '0px', 
                margin: '0px', 
                // paddingTop:     props.paddingTop,
                // paddingBottom:  props.paddingBottom,
                // paddingLeft:    props.paddingLeft,
                // paddingRight:   props.paddingRight,
                // margin:         props.margin?props.margin:'auto',
                // margin:         'auto',
                // marginTop:      props.marginTop,
                // marginBottom:   props.marginBottom,
                // marginLeft:     props.marginLeft,
                // marginRight:    props.marginRight,
                // opacity:        opacity_
                // visibility: visible_

                // border:     'dashed green 1px',
                // transformOrigin: 'top left',
                // transform: `scale(${scale_})`,
                opacity: props.opacity,
            }}
        >


            {/* {showPosition_&&props.id?fxGetPosition(props.id, 'document').top + 'px':null}
            {showPosition_&&props.id?fxGetPosition(props.id, 'parent').top + 'px':null} */}

            {/* <a href={props.link} rel="noopener noreferrer" target="_blank"> */}

                <img
                    id={props.id}
                    ref={props.logoRef}
                    src={logoImg}
                    // height={height_}
                    height='100%'
                    width='100%'
                    // width={props.scale}
                    onLoad={handleImageLoad}
                    // onLoad={refreshAfterImgLoad()}
                    onClick={props.onClick}
                    onMouseOver={props.onHover}
                    style={{
                        // position:       'relative',
                        // position:       'absolute', 
                        position:       props.position, 
                        // display: 'block',

                        background: debug_?'brown':'initial', // apenas para debugging
                        // border:     debug_?'dashed red 1px':'initial',
                        // border: 'dashed red 1px',

                        // padding: debug_? '10px' : props.padding, // apenas para debugging
                        // visibility: visible_,
                        visibility: props.visible,

                        // justifyContent: cssHAlign,               // horizontal align
                        // alignItems:     cssVAlign,                   // vertical align

                        // transform: `scale(${scale_})`,
                        // transform: `scale(.5)`,
                        // transformOrigin: 'top left',
                        // padding: '1px', 
                        // margin: '10%',
                        // padding: '10%', 
                        // margin: !!props.margin?props.margin:'10%',
                        // padding: !!props.padding?props.padding:'10%', 
                        objectFit: 'contain',
                        overflow: 'hidden',
                        cursor: props.cursor,
                        // default | none | context-menu | help | pointer | progress | wait | cell | crosshair | text | vertical-text | alias | copy | move | no-drop | not-allowed | e-resize | n-resize | ne-resize | nw-resize | s-resize | se-resize | sw-resize | w-resize | ew-resize | ns-resize | nesw-resize | nwse-resize | col-resize | row-resize | all-scroll | zoom-in | zoom-out | grab | grabbing
                        maxHeight: '100%',
                        maxWidth: '100%',
                    }}
                    // {...props.morphs.image}
                />

            {/* </a> */}
        </div>
    )
}

export default FxLogo;
