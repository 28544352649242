// IMPORTS -------------------------------------------------------
    import React, { forwardRef } from 'react';
    // import RestService from '../services/RestService';
    import {
        T_FX_VERTICAL_ALIGN,
        T_FX_HORIZONTAL_ALIGN,
        T_CSS_HORIZONTAL_ALIGN,
        T_CSS_VERTICAL_ALIGN,
        T_FILTER_KEY,
        T_DATA_FILTER
    } from '../utils/FxTypes';
    import {
        fxGetCSSVerticalAlign,
        fxGetCSSHorizontalAlign,
        isMobile
    } from '../utils/FxUtils';
    import { FormControl, Grid, IconButton, ListItem, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
    import DeleteIcon from '@mui/icons-material/Delete';
// ---------------------------------------------------------------------------

/**
 * Replace 'DataFilter' for the 'DataFilterName', case sensitive
 */

// TYPES (avoid!!! use FxTypes.ts instead!!!------------------------------------------------------
    // Reference to be used by Filter Grid to read this filter's values.
    // export interface IDataFilterRef {
    //     key: string,
    //     operation: string,
    //     value: string,
    // }
    // 
    export interface IDataFilter {
        id: number;
        count: number,

        keyList: T_FILTER_KEY[];

        key: string,
        operation: string,
        value: string,
    }
// ---------------------------------------------------------------------------

// COMPONENT INTERFACE -------------------------------------------------------
    interface IDataFilterMemoProps {
        id?: string,
        children?: React.ReactNode,
        height?: string | number,
        width?: string | number,
        debug?: boolean,
        vAlign?: T_FX_VERTICAL_ALIGN,
        hAlign?: T_FX_HORIZONTAL_ALIGN,
        // event01: ()=>void,

        filter: IDataFilter;
        // increase?: any;
        updateFilter: (id: number, key: string, operation: string, value: string) => void,
        deleteFilter: (id: number)=>void,
    }
// ---------------------------------------------------------------------------

// COMPONENT DEFINITION -------------------------------------------------------
// const FxDataFilter = React.memo<IDataFilterMemoProps>(function FxDataFilter(props: IDataFilterMemoProps) {
const FxDataFilter = (props: IDataFilterMemoProps)=>{
    // console.log("FxDataFilter: entering: props => ", props);

    // CONSTANTS AND DEFAULTS ------------------------------------------------------------
        // TODO: between, in, ...
        const STRING_OPERATORS = [
            'isEqual',
            'notEqual',
            'contains',
            'startsWith',
            'endsWith',
            'like',
            'regex',
        ];
        const NUMBER_OPERATORS = [
            'isEqual',
            'greaterThan',
            'greaterThanEqual',
            'lessThan',
            'lessThanEqual',
        ];
        const DATE_OPERATORS = [
            'before',
            'after',
        ];
        // const DEFAULT_FILTER_VALUES: T_DATA_FILTER = {
        //     key: {
        //         value: '',
        //         type: undefined
        //     },
        //     operator: '',
        //     value: ''
        // };
    // ---------------------------------------------------------------------------

    // PROPERTIES PRE-PROCESSING ---------------------------------------
        // => INITS, DEFAULTS, VALIDATION AND SANITIZATION
        const debug_: boolean = !!props.debug?props.debug:false;
        const height_: string|number = !!props.height?props.height:'100%';
        const width_: string|number = !!props.width?props.width:'100%';
        const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
        const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
    // ---------------------------------------------------------------------------

    // STATE VARIABLES -----------------------------------------------------------
        const rendered = React.useRef(0);

        const [key, setKey] = React.useState('');
        // const [keyType, setKeyType] = React.useState('');
        const [operator, setOperator] = React.useState('');
        const [value, setValue] = React.useState('');
    // ---------------------------------------------------------------------------

    // CONTROL FUNCTIONS ---------------------------------------------------------
        rendered.current++;

        // useImperativeHandle(ref, () => ({ value: value }));

        const getType = (key: string|undefined)=>{
            const keyObj = props.filter.keyList.find((propsKey)=>propsKey.field == key);
            const keyType = keyObj?.type;

            return keyType;
        }

        const getOperators = (key?: string)=>{
            const keyType = getType(key);
            if (!!!keyType) return [''];

            switch (keyType){
                case 'STRING':  
                case 'string':  return STRING_OPERATORS;

                case 'INTEGER':  
                case 'TINYINT':  
                case 'number':  return NUMBER_OPERATORS;

                case 'DATEONLY':  
                case 'date':    return DATE_OPERATORS;
                
                default:        return  [""]; //console.log(`ERROR: FxDataFilter: getOperators: unknown key type`);
            }
        }
    // ---------------------------------------------------------------------------

    // EVENTS CONTROL -----------------------------------------------------------
        // useEffect(() => {
        //     console.log("FxDataFilter: entering: useEffect");
        // }, []);
    // ---------------------------------------------------------------------------

    // STYLES -------------------------------------------------------------------
    const getStyle = ()=>{
        if (isMobile()) return { m: 0, minWidth: 80 };
        else return { m: 1, minWidth: 120 };
    }
    // ---------------------------------------------------------------------------

    // SUB COMPONENTS ---------------------------------------------------------
        const createSelect = (
            options: string[], 
            selectedOption_: string, 
            label_: string, 
            // filterDataIndex: number,
            // selectType: string,
            // handleChange_: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined
            handleChange_: (event: SelectChangeEvent<any>) => void | undefined
        )=>{
            // console.log(`typeof options[0] => `, typeof options[0]);

            // if (typeof options[0]  )
            
            return (
                <FormControl 
                    variant="standard" 
                    // fullWidth 
                    sx={getStyle()} 
                    size="small"
                >
                
                    {/* <InputLabel id="fxdatafilter-select-label">{label_}</InputLabel> */}
                    <Select
                        labelId="fxdatafilter-select-label"
                        id="fxdatafilter-simple-select"
                        value={selectedOption_}
                        label={label_}
                        // onChange={handleChange_}
                        // onChange={(event)=>handleSelectChange(filterDataIndex, selectType, event)}
                        onChange={handleChange_}
                        // SelectDisplayProps={}
                    >
                        {options.map((value_) => (
                                <MenuItem 
                                    key={value_}
                                    dense={true}
                                    // sx={{
                                    //     fontSize:'1rem',
                                    // }}
                                    value={value_}>{value_}
                                </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        }
    // ---------------------------------------------------------------------------

    // RENDER --------------------------------------------------------------------

    return (<>
        <ListItem
            // key={props.index}
            key={props.filter.id}
            // alignItems='flex-start'
            // alignItems='center'
            secondaryAction={
                <IconButton 
                    edge="end" 
                    aria-label="delete" 
                    onClick={()=>props.deleteFilter(props.filter.id)}
                >
                    <DeleteIcon />
                </IconButton>
            }
            sx={{pt:0, pb:0}}
            // disableGutters
        >
            <Grid container spacing={2}>

                {/* <ListItemAvatar>
                    <Avatar>
                        <FolderIcon />
                    </Avatar>
                </ListItemAvatar> */}

                {/* KEY SELECT */}
                <Grid 
                    id="filter-element-key" 
                    item 
                    xs={4} md={4} 
                    // sx={{pt:100}} // FX: does NOT work
                    style={{paddingTop: 8}}
                >
                    {createSelect(
                        props.filter.keyList.map(key=>key.field),     // options
                        key,                                // selected
                        "Key",                                 // label
                        (event: SelectChangeEvent<any>)=>{
                            setKey(event.target.value);
                            props.updateFilter(props.filter.id, event.target.value, operator, value);
                        }
                    )}
                </Grid>

                {/* OPERATOR SELECT */}
                <Grid 
                    id="filter-element-operator" 
                    item 
                    xs={4} md={4} 
                    // sx={{pt:100}} // FX: does NOT work
                    style={{paddingTop: 8}}
                >
                    {createSelect(
                        getOperators(key),
                        operator?operator:'',
                        "Operator",
                        (event: SelectChangeEvent<any>)=>{
                            setOperator(event.target.value);
                            props.updateFilter(props.filter.id, key, event.target.value, value);
                        }
                    )}
                </Grid>

                {/* VALUE SELECT */}
                <Grid 
                    item id="filter-element-value"
                    xs={4} md={4} 
                    // sx={{pt:100}} // FX: does NOT work
                    style={{paddingTop: 13}}
                >
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            value={value}
                            onChange={(event)=>{
                                setValue(event.target.value);
                                props.updateFilter(props.filter.id, key, operator, event.target.value);
                            }}
                            // style={{paddingTop: 8}}
                        />
                </Grid>
            </Grid>

        </ListItem>
        {/* <Divider /> */}
    </>)



        // return (
        //     <div 
        //         id={props.id}
        //         style={{
        //             display:        "flex", // if "block", justifyContent will not work!! 
        //             background:     debug_?'blue':'initial', // apenas para debugging
        //             border:         debug_?'dashed red 2px':'initial',
        //             height:         height_,
        //             width:          width_, 
        //             justifyContent: cssHAlign,               // horizontal align
        //             alignItems:     cssVAlign,                   // vertical align
        //         }}
        //     >
        //         {/* <h1>DataFilter!!</h1>
        //         {props.children} */}
        //         <li>
        //             {props.filter.id} - rendred {rendered.current} count:{' '}{props.filter.count}
        //             <button onClick={() => props.increase(props.filter.id)}>
        //                 increase count
        //             </button>
        //         </li>
        //     </div>
        // )
    // ---------------------------------------------------------------------------
}
// );


// EXPORTS -------------------------------------------------------
// export default forwardRef<IDataFilterRef, IDataFilterProps>((props, ref) => FxDataFilter);
// export default FxDataFilter;
export default React.memo<IDataFilterMemoProps>(FxDataFilter);
// ---------------------------------------------------------------------------
