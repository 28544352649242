// IMPORTS -------------------------------------------------------
    import React, { useEffect, useState } from 'react';
    // import RestService from '../services/RestService';
    import { 
        T_FX_VERTICAL_ALIGN, 
        T_FX_HORIZONTAL_ALIGN, 
        T_CSS_HORIZONTAL_ALIGN, 
        T_CSS_VERTICAL_ALIGN 
    } from '../utils/FxTypes';
    import { 
        fxGetCSSVerticalAlign, 
        fxGetCSSHorizontalAlign 
    } from '../utils/FxUtils';
    import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
// ---------------------------------------------------------------------------

/**
 * 
 */

// COMPONENT INTERFACE -------------------------------------------------------
    interface ISnackbarProps {
        id?: string,
        children?: React.ReactNode,
        height?: string|number,
        width?: string|number,
        debug?: boolean,
        vAlign?: T_FX_VERTICAL_ALIGN;
        hAlign?: T_FX_HORIZONTAL_ALIGN;
        // event01: ()=>void,

        vertical?: "top" | "bottom";
        horizontal?: "center" | "left" | "right";

        open: boolean;
        onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
        message?: string;
        onMessageChange?: React.Dispatch<React.SetStateAction<string | undefined>>;

        duration?: number;
        // state: State;
    }

    // interface State extends SnackbarOrigin {
    //     open: boolean;
    //     message?: string;
    // }
// ---------------------------------------------------------------------------

// COMPONENT DEFINITION -------------------------------------------------------
    const FxSnackbar = (props: ISnackbarProps)=>{
        // console.log("FxSnackbar: entering: props => ", props);

        // CONSTANTS AND DEFAULTS ------------------------------------------------------------
            // const constant01 = 1010;
        // ---------------------------------------------------------------------------
        
        // PROPERTIES PRE-PROCESSING ---------------------------------------
            // => INITS, DEFAULTS, VALIDATION AND SANITIZATION
            const id_: string = !!props.id?props.id:"fx-snackbar";
            const debug_: boolean = !!props.debug?props.debug:false;
            const height_: string|number = !!props.height?props.height:'100%';
            const width_: string|number = !!props.width?props.width:'100%';
            const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
            const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';

            const duration_: number = !!props.duration?props.duration:3000;
        // ---------------------------------------------------------------------------

        // STATE VARIABLES -----------------------------------------------------------
            // const [state, setState] = React.useState<State>({
            //     open:       !!props.open?props.open:false,
            //     vertical:   !!props.vertical?props.vertical:'top',
            //     horizontal: !!props.horizontal?props.horizontal:'center',
            // });
        // ---------------------------------------------------------------------------

        // CONTROL FUNCTIONS ---------------------------------------------------------
            // 
            // const handleClick = (newState: SnackbarOrigin) => () => {
            //     setState({ ...newState, open: true });
            // };

            const handleClose = () => {
                // setState({ ...state, open: false });
                props.onOpenChange(false);
            };
        // ---------------------------------------------------------------------------

        // EVENTS CONTROL -----------------------------------------------------------
            // useEffect(() => {
            //     console.log("FxSnackbar: entering: useEffect");
            // }, []);
        // ---------------------------------------------------------------------------

        // STYLES -------------------------------------------------------------------
        // ---------------------------------------------------------------------------

        // SUB COMPONENTS ---------------------------------------------------------
        // ---------------------------------------------------------------------------

        // RENDER --------------------------------------------------------------------
            return (
                <div 
                    id={id_}
                    style={{
                        display:        "flex", // if "block", justifyContent will not work!! 
                        background:     debug_?'blue':'initial', // apenas para debugging
                        border:         debug_?'dashed red 2px':'initial',
                        height:         height_,
                        width:          width_, 
                        justifyContent: cssHAlign,               // horizontal align
                        alignItems:     cssVAlign,               // vertical align
                    }}
                >
                    <Snackbar
                        // anchorOrigin={{ vertical, horizontal }}
                        anchorOrigin={{ vertical: props.vertical!, horizontal: props.horizontal! }}
                        open={debug_?true:props.open}
                        onClose={handleClose}
                        message={debug_?"DEBUG MESSAGE!!":props.message}
                        autoHideDuration={duration_}
                        // key={vertical + horizontal}
                        key={props.vertical! + props.horizontal!}
                    />
                    {/* {props.children} */}
                </div>
            )
        // ---------------------------------------------------------------------------
    }
// ---------------------------------------------------------------------------

// EXPORTS -------------------------------------------------------
    export default FxSnackbar;
// ---------------------------------------------------------------------------
