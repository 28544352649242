// Relative path to fxlib-react code: ../fxlib-react/src
// Relative path to fxlib-react assets: ../../fxlib-react/assets
import React, { useEffect, useState } from 'react';
import { 
    T_FX_VERTICAL_ALIGN, 
    T_FX_HORIZONTAL_ALIGN, 
    T_CSS_HORIZONTAL_ALIGN, 
    T_CSS_VERTICAL_ALIGN, 
    T_GRAPHIC_CONTEXT
} from '../fxlib-react/src/utils/FxTypes';
import { 
    fxGetCSSVerticalAlign, 
    fxGetCSSHorizontalAlign,
    str2html, 
} from '../fxlib-react/src/utils/FxUtils';
import FxPortfolio from '../fxlib-react/src/components/FxPortfolio';
import { createPortal } from 'react-dom';
import { usePageContext } from './FxOutletContainer';
// import FxPageContainer, { usePageContext } from '../fxlib-react/src/components/FxOutletContainer';
// import oxyImg from './assets/images/test.jpg'; // Tell webpack this JS file uses this image
import oxyImg from './assets/images/oxy01.jpeg'; // Tell webpack this JS file uses this image
import imobImg from './assets/images/imob02cropped.jpg'; // Tell webpack this JS file uses this image
import dlsImg from './assets/images/dls01.png'; // Tell webpack this JS file uses this image
import nubeImg from './assets/images/nube01.png'; // Tell webpack this JS file uses this image
import { Box, Typography } from '@mui/material';
import { fxtr } from '../fxlib-react/src/services/FxPolyglot.service';

/**
 * 
 */

//  Reference:
// https://bepatrickdavid.com/

export interface IPortfolioItem {
    id: string;
    overline: string;
    title: string;
    synopsis: JSX.Element;
    keywords: string[];
    links: string[];
    imageSrc: any;
}

interface IPortfolioPageProps {
    id?: string,
    vAlign?: T_FX_VERTICAL_ALIGN,
    hAlign?: T_FX_HORIZONTAL_ALIGN,
    debug?: boolean,
    height?: string,
    // event01: ()=>void,
    items?:IPortfolioItem[],
    setGraphicContext?: (options: T_GRAPHIC_CONTEXT)=>void,
}

const FxPortfolioPage = (props: IPortfolioPageProps)=>{
    // console.log("FxPortfolioPage: entering: props => ", props);

    const id_:string=props.id?props.id:"portfolio-page";
    const debug_:boolean=props.debug?props.debug:false;
    const cssVAlign:T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
    const cssHAlign:T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
    const height_:string = props.height?props.height:"100%";
    
    const pageContext = usePageContext();

    useEffect(() => {
        // console.log('FxSoftwarePage: entering: useEffect => pageContext');
        if (!!pageContext) {
            const graphicContext:T_GRAPHIC_CONTEXT = {
                name: 'portfolio',
                bgImageLight: undefined,
                bgOverlayLight: undefined,
                bgImageDark: undefined,
                bgOverlayDark: undefined,
            }
            pageContext.changeGraphicContext(graphicContext);
        }
    }, [pageContext]);

    // FX: translations
    useEffect(() => {

    }, [pageContext.lang]);

    // FX: formas de converter string em HTML.
    // Caso de uso: um texto formatado com HTML tags.
        //     return (<>
        //         Re-criação de webapp dentro do conceito <strong>Mobile First&nbsp;</strong> 
        //         em React e uso de técnicas de UI/UX. Acesso a bancos de dados 
        //         ElasticSearch e MongoDB, com queries DSL automáticas, geradas
        //         a partir dos campos do form. Animações, sobreposição 
        //         de layers e inserção de desenhos geométricos e à mão livre em <strong>mapa&nbsp;</strong> 
        //         React Leaflet. Caching, segurança e controle de acesso por funcionalidade.
        //     </>)

        // return <div>{[
        //         'Re-criação de webapp dentro do conceito ', <strong>Mobile First&nbsp;</strong>,
        //         ' em React e uso de técnicas de UI/UX. Acesso a bancos de dados',
        //         ' ElasticSearch e MongoDB, com queries DSL automáticas, geradas',
        //         ' a partir dos campos do form. Animações, sobreposição',
        //         ' de layers e inserção de desenhos geométricos e à mão livre em ', <strong>mapa&nbsp;</strong>,
        //         ' React Leaflet. Caching, segurança e controle de acesso por funcionalidade.'
        //     ]}</div>

        // const html = `
        //     Re-criação de webapp dentro do conceito <strong>Mobile First&nbsp;</strong> 
        //     em React e uso de técnicas de UI/UX. Acesso a bancos de dados 
        //     ElasticSearch e MongoDB, com queries DSL automáticas, geradas
        //     a partir dos campos do form. Animações, sobreposição 
        //     de layers e inserção de desenhos geométricos e à mão livre em <strong>mapa&nbsp;</strong> 
        //     React Leaflet. Caching, segurança e controle de acesso por funcionalidade.
        // `
        // return <div dangerouslySetInnerHTML={{__html: html}}></div>


    const fxCreatePortfolioItem = (
        id: string,
        overline: string,
        title: string,
        synopsis: JSX.Element,
        keywords: string[],
        links: JSX.Element[],
        imageSrc: any,
    )=>{
        return (
            <li
                key={id}
                className="portfolio-item"
                style={{
                    visibility: 'visible',
                    opacity: 1,
                    transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                    transition: 'opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
                    scrollSnapAlign: 'start',
                }}
            >

                    <FxPortfolio
                        id={id}
                        debug={debug_} 
                        overline={overline}
                        title={title}
                        synopsis={synopsis}
                        keywords={keywords}
                        links={links}
                        imageSrc={imageSrc} 
                    />
            </li>
        )
    }

    const items_ = [
        fxCreatePortfolioItem(
            'FxPortfolio-IntImob', 
            fxtr('project'), 
            fxtr('fx-portfolio-page-project-title-01'), 
            str2html(fxtr('fx-portfolio-page-project-synopsis-01')), 
            ["React", "ElasticSearch", "MongoDB", "Prime", "Leaflet", "MobileFirst", "Frontend", "SCSS", "Jenkins", "GitLab"], 
            [], 
            imobImg),

        fxCreatePortfolioItem(
            'FxPortfolio-Dls', 
            fxtr('project'), 
            fxtr('fx-portfolio-page-project-title-02'), 
            str2html(fxtr('fx-portfolio-page-project-synopsis-02')), 
            ["React", "Javascript", "PHP", "XAMPP", "Google-Script", "Google-Workspace-Apps", "MUI", "R", "GoogleCloudRunner", "Telegram-API", "Wordpress"], 
            [], 
            dlsImg),   

        fxCreatePortfolioItem(
            'FxPortfolio-Oxygenes', 
            fxtr('project'), 
            fxtr('fx-portfolio-page-project-title-03'), 
            str2html(fxtr('fx-portfolio-page-project-synopsis-03')), 
            ["PHP", "Javascript", "MariaDB", "Google-Workspace-Apps", "Google-Script"], 
            [], 
            oxyImg),    
                    
        fxCreatePortfolioItem(
            'FxPortfolio-Nube', 
            fxtr('project'), 
            fxtr('fx-portfolio-page-project-title-04'), 
            str2html(fxtr('fx-portfolio-page-project-synopsis-04')), 
            ["React", "MUI", "Javascript", "MySQL", "Typescript", "NodeJS"], 
            [], 
            nubeImg),            
    ]

    return (
        <main id="fx-portfolio-page-box" className="fillHeight"
            // style={{
            //     scrollSnapType: 'none',
            // }}
        >
            <section>

                <ul 
                    className="portfolio-list"
                    style={{
                        position: 'relative',
                        // paddingLeft: '5vh',
                        // paddingRight: '5vh',
                        // scrollSnapAlign: 'start',
                    }}
                >
                    {items_.map((item:any) => item)}
                </ul>

            </section>
        </main>
    );
}

export default FxPortfolioPage;
