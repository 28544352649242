/**
    MAIN APPLICATION
 */


import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {lightTheme, darkTheme } from 'theme';
import FxBlog from 'pages/FxBlogPage';
import FxBarter from 'pages/FxBarterPage';
import FxSoftwarePage from './pages/FxSoftwarePage';
import FxPortfolioPage    from './pages/FxPortfolioPage';
import FxButton from './fxlib-react/src/components/FxButton';
import FxCanvas from './fxlib-react/src/components/FxCanvas';
import { createTheme, IconButton, useTheme } from '@mui/material';
import { fxGetLanguage, fxRecall, fxSave, fxSetCssVar } from './fxlib-react/src/utils/FxUtils';
// import FxVendaApePage from './parallel/VendaApe/FxVendaApePage';
import FxNoMatchPage from './pages/FxNoMatchPage';
import FxList from './fxlib-react/src/components/FxList';
import FxChickensPage from './pages/FxChickensPage';
import FxGalleryPage from './pages/FxGalleryPage';
import FxMulter from './fxlib-react/src/components/test/FxMulter';
// import FxAppPage3 from './pages/FxAppPage3';
// import FxPageContainer2 from './pages/FxOutletContainer';
import FxOutletContainer from './pages/FxOutletContainer';
import FxGrid_TEST from './fxlib-react/src/components/test/FxGrid_TEST';
import { fxtr } from './fxlib-react/src/services/FxPolyglot.service';
// import { RUN_STATUS, API_URL } from './FxConfig';

const dummy = 0;

// const DEBUG = process.env.NODE_ENV=="development";
const DEBUG = false;

// publish
const fx_test = 1;

function FxRoot() {
  return (
    <div>
      <h1>FxRoot</h1>
    </div>
  );
}
function FxHome() {
  return (
    <div>
      <h1>FxHome</h1>
    </div>
  );
}
function FxOut01() {
  return (
    <div>
      <h1>FxOut01</h1>
    </div>
  );
}
function FxOut02() {
  return (
    <div>
      <h1>FxOut02</h1>
    </div>
  );
}

// COMPONENT DEFINITION -------------------------------------------------------
function App2() {

    // CONSTANTS AND DEFAULTS ------------------------------------------------------------
        // Get theme state from browser's local storage.
        const defaultMode = !!fxRecall('theme')?fxRecall('theme'):'dark';
        // const defaultlanguage = !!fxRecall('language')?fxRecall('language')!:fxSave('language','pt-br');
        const defaultlanguage = !!fxRecall('language')?fxRecall('language')!:fxSave('language',fxGetLanguage().toLowerCase());

    // STATE VARIABLES -----------------------------------------------------------
        // Definitions for home screen animations (logo, search bar, ...)
        const [homeScreenActive, setHomeScreenActive] = React.useState(true);
        const [mode, setMode] = React.useState(defaultMode);
        const [language, setLanguage] = React.useState(defaultlanguage);

    // CONTROL FUNCTIONS ---------------------------------------------------------
        const toggleColorMode = () => {
            const newMode = mode === 'light' ? 'dark' : 'light';
            fxSave('theme', newMode); // persist color mode in the client
            setMode(newMode);
        }
        
        const saveLanguage = (lang: string) => {
            console.log(lang);

            fxSave('language', lang);
            setLanguage(lang);
        }

        const setHomeScreen = (active: boolean) => {
            setHomeScreenActive(active);
        }
    
        // console.log(`translation of 'text-01' is ${fxtr("text-01")}`);

        // RENDER --------------------------------------------------------------------
            // https://reactrouter.com/docs/en/v6/getting-started/overview
            // The nested url segments map to nested component trees. This is perfect for creating UI that has 
            // persistent navigation in layouts with an inner section that changes with the URL. If you look 
            // around the web you'll notice many websites (and especially web apps) have multiple levels of 
            // layout nesting.

            return (<>
                    <ThemeProvider theme={mode=='light'?createTheme(lightTheme):createTheme(darkTheme)}>

                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        {/* MUI provides an optional CssBaseline component. It fixes some inconsistencies across 
                        browsers and devices while providing slightly more opinionated resets to common HTML elements. */}
                        <CssBaseline />
                            <BrowserRouter>

                                {/* MAIN APPLICATION */}
                                <Routes>

                                    {/* <Route path="/" element={<FxAppPage2 toggleColorMode={toggleColorMode}/>}> */}
                                    <Route path="/" 
                                        element={<FxOutletContainer 
                                            toggleColorMode={toggleColorMode} 
                                            setHomeScreen={setHomeScreen}
                                            changeLanguage={saveLanguage}
                                            lang={language}
                                        />
                                    
                                    }>
                                    {/* <Route path="/" element={<FxSearchPage />}> */}
                                        {/* <Route index            element={<FxSearchPage debug={DEBUG} />} /> */}
                                        <Route path="software"  element={<FxSoftwarePage debug={DEBUG} />} >
                                        {/* <Route path="software"  element={<><FxSoftwarePage debug={DEBUG} /><FxPortfolio id="portfolio"/></>} > */}
                                            {/* <Route path="portfolio_" element={<FxPortfolio debug={DEBUG} />} /> */}
                                        </Route>
                                        <Route path="software/portfolio" element={<FxPortfolioPage id="portfolio"/>} />
                                        <Route path="list" element={<FxList />} />
                                        <Route path="chickens" element={<FxChickensPage id="chickens" debug={DEBUG} />} />
                                        {/* <Route path="gallery" element={<FxGalleryPage id="gallery-page" debug={DEBUG} />} /> */}
                                        <Route path="gallery" element={<FxGalleryPage id="gallery" imagesUrl={process.env.REACT_APP_FX_IMAGES_URL} apiUrl={process.env.REACT_APP_FX_API_URL}/>} />
                                        {/* <Route path="upload" element={<FileUploadApp />} /> */}
                                        {/* <Route index element={<FxOut01 />} /> */}
                                        {/* <Route path="teams" element={<Teams />}>
                                            <Route path=":teamId" element={<Team />} />
                                            <Route path="new" element={<NewTeamForm />} />
                                            <Route index element={<LeagueStandings />} />
                                        </Route> */}

                                        {/* TEST COMPONENTS ---------------------------------------------------- */}
                                            <Route path="multer" element={<FxMulter />} />
                                            <Route path="play/grid" element={<FxGrid_TEST debug={true}/>} />
                                        {/* -------------------------------------------------------------------- */}

                                        {/* Using path="*"" means "match anything", so this route acts like   
                                            a catch-all for URLs thatwe don't have explicit routes for. */}
                                        <Route path="*" element={<FxNoMatchPage />} />
                                    </Route>
                                    {/* <Route path="upload" element={<FileUploadApp />} /> */}


                                </Routes>


                            </BrowserRouter>
                    </ThemeProvider>    
            </>);
}
export default App2;
// ---------------------------------------------------------------------------



/* ============================================================================= */
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React FX
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
