// IMPORTS -------------------------------------------------------
import React, { useEffect, useRef } from 'react';
// import RestService from '../services/RestService';
import {
    T_FX_VERTICAL_ALIGN,
    T_FX_HORIZONTAL_ALIGN,
    T_CSS_HORIZONTAL_ALIGN,
    T_CSS_VERTICAL_ALIGN,
    T_FILTER_KEY
} from '../utils/FxTypes';
import {
    fxGetCSSVerticalAlign,
    fxGetCSSHorizontalAlign
} from '../utils/FxUtils';
import FxDataFilter, { IDataFilter } from './FxDataFilter';
import { Button, Card, CardActions, CardContent, Divider, Grid, List, ListItem, ListItemText, Typography, styled } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// ---------------------------------------------------------------------------

/**
 * Replace 'DataFilterGrid' for the 'DataFilterGridName', case sensitive
 */

// TYPES (avoid!!! use FxTypes.ts instead!!!------------------------------------------------------

// ---------------------------------------------------------------------------

// COMPONENT INTERFACE -------------------------------------------------------
interface IDataFilterGridProps {
    id?: string,
    children?: React.ReactNode,
    height?: string | number,
    width?: string | number,
    debug?: boolean,
    vAlign?: T_FX_VERTICAL_ALIGN,
    hAlign?: T_FX_HORIZONTAL_ALIGN,
    // event01: ()=>void,

    keys: T_FILTER_KEY[],
    onFilterApply: (filters:IDataFilter[])=>void,
}
// ---------------------------------------------------------------------------
    // const createId = ((id) => () => id++)(1);


// COMPONENT DEFINITION -------------------------------------------------------
const FxDataFilterGrid = (props: IDataFilterGridProps) => {
    // console.log("FxDataFilterGrid: entering: props => ", props);

    // CONSTANTS AND DEFAULTS ------------------------------------------------------------
        // const constant01 = 1010;
        // const createId = ((id) => () => id++)(1);

        const defaultFilter: IDataFilter = { 
            // id: createFilterId(), 
            id: 1, 
            count: 0,
            keyList: props.keys,
            key: '',
            operation: '',
            value: '',
        };
        // console.log(`FxDataFilterGrid: defaultFilter => `, defaultFilter);
    // ---------------------------------------------------------------------------

    // PROPERTIES PRE-PROCESSING ---------------------------------------
        // => INITS, DEFAULTS, VALIDATION AND SANITIZATION
        const debug_: boolean = !!props.debug?props.debug:false;
        const height_: string|number = !!props.height?props.height:'100%';
        const width_: string|number = !!props.width?props.width:'100%';
        const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
        const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
    // ---------------------------------------------------------------------------

    // STATE VARIABLES -----------------------------------------------------------
        // FX: keys list come after fetching in parent component. Thus, it's not feasilble
        // to initiate with defaultFilter. Check status in useEffect and add first empty
        // filter when filter keys finally arrive
        const [filters, setFilters] = React.useState<IDataFilter[]>([defaultFilter]);
        // console.log("FxDataFilterGrid: filters => ", filters);

        const [dense, setDense] = React.useState(false);
        // const childRef = useRef<IDataFilterRef[]>([]);
    // ---------------------------------------------------------------------------

    // CONTROL FUNCTIONS ---------------------------------------------------------
        const createFilterId = ()=>filters.length+1;

        const addFilter = () => {
            // console.log(`FxDataFilterGrid: filters => `, filters);
            // const newFilter: IDataFilter = { 
            //     id: createFilterId(), 
            //     count: 0,
            //     keyList: props.keys,
            //     key: '',
            //     operation: '',
            //     value: '',
            // };
            // Add filter to the end of list
            const newFilter = {...defaultFilter, id: createFilterId()}
            setFilters((items) => [ ...items, newFilter]);
        }

        // use useCallback to create an increase function that
            //  never changes so you'll never pass a new increase function to item
        const updateFilter = React.useCallback(
            //pass callback to state setter so items is not a dependency of the useCallback
            (id: number, key: string, operation: string, value: string) => {
                // console.log(`FxDataFilterGrid: increase: id => `, id);

                setFilters((currentItems) =>
                    currentItems.map((item) =>
                        item.id === id
                            ? { ...item, key: key, operation: operation, value: value }
                            : item
                    )
                )
            },
            []
        );

        // splice mutates the array, so have to use 'filter'.
        // https://stackoverflow.com/questions/36326612/how-to-delete-an-item-from-state-array
        const deleteFilter = (id: number)=>{
            console.log(`FxDataFilter: deleteFilter: id => `, id);
 
            const newFiltersData = filters.filter((filter)=>{return filter.id != id });
            setFilters(newFiltersData);
        }

        const deleteAll = ()=>{
            setFilters([]);
        }

        const execFilter = ()=>{
            // console.log(`FxDataFilter: execFilter: filters => `, filters);
            props.onFilterApply(filters);
        }
    // ---------------------------------------------------------------------------

    // EVENTS CONTROL -----------------------------------------------------------
        // useEffect(() => {
        //     console.log("FxDataFilterGrid: useEffect: filters => ", filters);
        // }, []);
    // ---------------------------------------------------------------------------

    // STYLES -------------------------------------------------------------------
    // ---------------------------------------------------------------------------

    // SUB COMPONENTS ---------------------------------------------------------
        // const Demo = styled('div')(({ theme }) => ({
        //     backgroundColor: theme.palette.background.paper,
        // }));

        const buttons =
            <ListItem
                sx={{pb:0}}
            >
                <Grid container spacing={2}>
                    <Grid item xs={3} md={3}>
                        <Button 
                            variant="text" 
                            // variant="outlined" 
                            // startIcon={<AddIcon />}
                            startIcon={<AddCircleIcon />}
                            onClick={()=>addFilter()}
                            sx={{
                                width:150,
                                p:0
                            }}
                        >
                            ADD
                        </Button>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <Button 
                            variant="text" 
                            startIcon={<DeleteForeverIcon />}
                            onClick={()=>deleteAll()}
                            sx={{
                                width:150,
                                p:0
                            }}
                        >
                            REMOVE ALL
                        </Button>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <Button 
                            variant="text" 
                            startIcon={<FilterAltIcon />}
                            onClick={()=>execFilter()}
                            sx={{
                                width:150,
                                p:0
                            }}
                        >
                            Apply
                        </Button>
                    </Grid>

                    {/* <Grid item xs={3} md={3}> */}
                        {/* <Typography>
                            Filters:
                        </Typography> */}
                    {/* </Grid> */}
                </Grid>
            </ListItem>

        // const header = <ListItem sx={{pb:0, pt:0}}>
        //     <ListItemText
        //         primary="Key"
        //         // primaryTypographyProps={primaryTypographyStyle}
        //     />
        //     <ListItemText
        //         primary="Operator"
        //         // primaryTypographyProps={primaryTypographyStyle}
        //     />
        //     <ListItemText
        //         primary="Value"
        //         // primaryTypographyProps={primaryTypographyStyle}
        //     />
        // </ListItem>
    // ---------------------------------------------------------------------------

    // RENDER --------------------------------------------------------------------
        return (
            <div 
                id={props.id}
                style={{
                    display:        "flex", // if "block", justifyContent will not work!! 
                    background:     debug_?'blue':'initial', // apenas para debugging
                    border:         debug_?'dashed red 2px':'initial',
                    height:         height_,
                    width:          width_, 
                    justifyContent: cssHAlign,               // horizontal align
                    alignItems:     cssVAlign,                   // vertical align
                }}
            >
                {/* <h1>DataFilterGrid!!</h1>
                {props.children} */}

                {/* <div>
                    <button onClick={addFilter}>+</button>
                </div> */}

                {/* <div> */}
                {/* <Demo> */}
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>

                        <List 
                            dense={dense} 
                            // subheader={header}
                        >

                            {/* {header} */}
                            {/* <Divider style={{marginBottom: 10}}/> */}

                            {filters.map((item) => (
                                <FxDataFilter
                                    key={item.id}
                                    filter={item}
                                    updateFilter={updateFilter}
                                    deleteFilter={deleteFilter}
                                    // ref={el => childRef.current[item.id] = el!}
                                />
                            ))}

                            {/* {buttons} */}
                                
                        </List>
                    </CardContent>

                    <CardActions>
                            {buttons}
                    </CardActions>
                </Card>
                {/* </Demo> */}
                {/* </div> */}
            </div>
        )
}

// ---------------------------------------------------------------------------

// EXPORTS -------------------------------------------------------
export default FxDataFilterGrid;
// ---------------------------------------------------------------------------
