import React, { useEffect, useState } from 'react';
import RestService from '../services/RestService';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN } from '../utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../utils/FxUtils';

/**
 * 
 */

// const labels: { [index: string]: string } = {
//   0.5: 'Useless',
//   1: 'Useless+',
//   1.5: 'Poor',
//   2: 'Poor+',
//   2.5: 'Ok',
//   3: 'Ok+',
//   3.5: 'Good',
//   4: 'Good+',
//   4.5: 'Excellent',
//   5: 'Excellent+',
// };

// function getLabelText(value: number) {
//   return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
// }

// COMPONENT INTERFACE -------------------------------------------------------
    interface IRatingProps {
        id?: string,
        name?: string,
        // children?: React.ReactNode,
        height?: string|number,
        width?: string|number,
        debug?: boolean,
        vAlign?: T_FX_VERTICAL_ALIGN;
        hAlign?: T_FX_HORIZONTAL_ALIGN;
        // event01: ()=>void
        
        precision?: number,
        defaultValue?: number,
        readOnly?:boolean,
        labels?:{ [index: string]: string }
    }
// ---------------------------------------------------------------------------

const FxRating = (props: IRatingProps)=>{
    // console.log("FxRating: entering: props => ", props);

    // PROPERTIES PRE-PROCESSING ---------------------------------------
        // => INITS, DEFAULTS, VALIDATION AND SANITIZATION
        const name_: string|number = !!props.name?props.name:'rating';
        const debug_: boolean = !!props.debug?props.debug:false;
        const height_: string|number = !!props.height?props.height:'100%';
        const width_: string|number = !!props.width?props.width:200;
        const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
        const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';

        const precision_: number = !!props.precision?props.precision:1;
        const defaultValue_: number = !!props.defaultValue?props.defaultValue:0;
    // ---------------------------------------------------------------------------

    const [value, setValue] = React.useState<number | null>(defaultValue_);
    const [hover, setHover] = React.useState(-1);
    
    // useEffect(() => {
    //     console.log("FxRating: entering: useEffect");
    // }, []);

    // const getSomeData = () => {
    //     // RestService.getSomeData()
    //     // .then(response => {
    //     //     setSomeData(response.data);
    //     //     console.log(response.data);
    //     // })
    //     // .catch(e => {
    //     //     console.log(e);
    //     // });
    // };

    const getLabelText = (value: number) => {
        if (!!!props.labels) return "";
        return `${value} Star${value !== 1 ? 's' : ''}, ${props.labels[value]}`;
    }

    // RENDER --------------------------------------------------------------------
        return (
            // <div 
            //     id={props.id}
            //     style={{
            //         background:     debug_?'blue':'initial', // apenas para debugging
            //         border:         debug_?'dashed red 2px':'initial',
            //         height:         height_,
            //         width:          width_, 
            //         justifyContent: cssHAlign,               // horizontal align
            //         alignItems:     cssVAlign,                   // vertical align
            //     }}
            // >
                <Box
                    id={`${props.id}-container`}
                    sx={{
                        // width: width_,
                        // display: 'flex', // commenting this out causes stacking of stars and text
                        // alignItems: 'center', // IMPORTANT: centered+inline causes interference in stars selection, due to component size changing!!!
                    }}
                    style={{
                        background:     debug_?'blue':'initial', // apenas para debugging
                        border:         debug_?'dashed red 2px':'initial',
                        height:         height_,
                        width:          width_, 
                        justifyContent: cssHAlign,               // horizontal align
                        alignItems:     cssVAlign,                   // vertical align
                    }}
                >
                    <Rating
                        id={`${props.id}-rating`}
                        name={name_}
                        value={value}
                        precision={precision_}
                        defaultValue={props.defaultValue}
                        readOnly={props.readOnly}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        sx={{ mb: 2 }}
                    />

                    {value !== null && !!props.labels &&(
                        <Box sx={{ ml: 2 }}>{props.labels[hover !== -1 ? hover : value]}</Box>
                    )}

                </Box>
            // </div>
        )
    // ---------------------------------------------------------------------------
}

export default FxRating;
