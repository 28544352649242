import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { useEffect, useState } from 'react';
import RestService from '../services/RestService';
import { TextField } from '@mui/material';

type User = {
    id: number,
    offers: number[],
    needs: number[]
};

type Barter = {
    id: number;
    owner_id: number;
    title: string;
    description: string;
    status: string;
    tags: string[];
};


export default function FxList() {
  const [checked, setChecked] = useState([0]);
  const [barterTable, setBarterTable] = useState<Barter[]>();
  const [value, setValue] = React.useState('Controlled');

  useEffect(() => {
    // console.log("FxList: Entering: useEffect");
    getBarterTable();
  }, []);
    
  const getBarterTable = () => {
    RestService.getBarterTable()
      .then(response => {
        setBarterTable(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event);
    setValue(event.target.value);
  };

  const getText = ()=>{
    if ((!!barterTable)&&(!!barterTable[0])){
        return barterTable[0].description;
    } else {
        return 'No data';
    }
  }

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {[0, 1, 2, 3].map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem
            key={value}
            // secondaryAction={
            //   <IconButton edge="end" aria-label="comments">
            //     <CommentIcon />
            //   </IconButton>
            // }
            disablePadding
          >
            <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
                {/* <ListItemText id={labelId} primary={`Line item ${value + 1}`} /> */}
                {/* <ListItemText 
                    id={labelId} 
                    primary={ getText() } 
                /> */}
                <TextField 
                    id="outlined-basic" 
                    label={ getText() } 
                    // variant="outlined" 
                    variant='standard'
                    onChange={handleChange}
                />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
