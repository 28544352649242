import axios, { AxiosRequestConfig } from "axios";
// import { RUN_STATUS } from "../../../FxConfig";
// import FxLogo from "../components/FxLogo";


// export interface AxiosRequestConfig<D = any> {
//   url?: string;
//   method?: Method;
//   baseURL?: string;
//   transformRequest?: AxiosRequestTransformer | AxiosRequestTransformer[];
//   transformResponse?: AxiosResponseTransformer | AxiosResponseTransformer[];
//   headers?: AxiosRequestHeaders;
//   params?: any;
//   paramsSerializer?: (params: any) => string;
//   data?: D;
//   timeout?: number;
//   timeoutErrorMessage?: string;
//   withCredentials?: boolean;
//   adapter?: AxiosAdapter;
//   auth?: AxiosBasicCredentials;
//   responseType?: ResponseType;
//   responseEncoding?: responseEncoding | string;
//   xsrfCookieName?: string;
//   xsrfHeaderName?: string;
//   onUploadProgress?: (progressEvent: any) => void;
//   onDownloadProgress?: (progressEvent: any) => void;
//   maxContentLength?: number;
//   validateStatus?: ((status: number) => boolean) | null;
//   maxBodyLength?: number;
//   maxRedirects?: number;
//   socketPath?: string | null;
//   httpAgent?: any;
//   httpsAgent?: any;
//   proxy?: AxiosProxyConfig | false;
//   cancelToken?: CancelToken;
//   decompress?: boolean;
//   transitional?: TransitionalOptions;
//   signal?: AbortSignal;
//   insecureHTTPParser?: boolean;
// }

const baseURL_ = process.env.REACT_APP_FX_API_URL;

export const FX_HTTP = axios.create({
    baseURL: baseURL_,
    headers: {
        "Content-type": "application/json"
    }
});

export const FX_UPLOAD = axios.create({
    baseURL: baseURL_,
    headers: {
    "Content-Type": "multipart/form-data",
  }
});


// axios.post(url[, data[, config]])
// .post(`/mails/users/sendVerificationMail`, null, { params: {
//   mail,
//   firstname
// }})
// .then(response => response.status)
// .catch(err => console.warn(err));

// url: string, 
// data?: null | undefined, 
// config?: AxiosRequestConfig<null> | undefined


// ----------------------------------------------------------------------------------------------------
// TUTORIAL
// 
// REF: https://stackabuse.com/axios-multipart-form-data-sending-file-through-a-form-with-javascript/
// Setting the enctype with Axios and HTML
// To send multipart data (files) through form data - you'll have to set the encoding type. 
// There are a couple of ways you can do this, with no clear or distinct "winner" - they're functionally 
// equivalent per request in the end. With Axios - you can set the default global encoding type:
//
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
//
// This enforces all Axios requests to be of multipart/form-data encoding type. 
// Alternatively, you can define the type for each individual request, by altering the headers:
// 
// axios.post("/path/to/api", data, {
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
// });
// 
// Or you can just set the enctype attribute in the <form> tag of a specific form, 
// and Axios will simply adopt that form's encoding type:
// 
// <form action="/some-endpoint" method="HTTP_METHOD" enctype="multipart/form-data">
// </form>
// 


// FX: options:
// NodeJS: [server] framework running code in javascript
// Express: [server] REST API for NodeJS
// Multer: [server] NodeJS middleware for handling multipart/form-data (uploading files)
// Axios: [client] a promise-based (asynchronous) HTTP client. Used instead of HTML.fetch() to GET/POST
//
// NodeJS + Express + Multer
// NodeJS + Express + Axios

// FX Axios CONFIG (simplified to frequent use cases), using alias methods.
// When using the alias methods url, method, and data properties 
// don't need to be specified in config.
// axiosConfig {
//   headers?: AxiosRequestHeaders;
//   params?: any;
//   data?: D;
//   withCredentials?: boolean;
//   auth?: AxiosBasicCredentials;
// }
// FX01 git dummy
export const fxFetchData = async (endpoint_: string, params_?: {}) => {
    // console.log(`FxDbConnection: fxFetchData: endpoint_ => `, endpoint_);
    // console.log(`FxDbConnection: fxFetchData: params_ => `, params_);
    const axiosUrl = endpoint_; // ex.: `/mails/users/sendVerificationMail`
    // const axiosData: null|undefined = null;
    const axiosConfig: AxiosRequestConfig = {
        params: params_
    }
    // { 
    //     params: {
    //         data,
    //         firstname
    //     }
    // }
    const response =  await FX_HTTP.post(
        axiosUrl, 
        // axiosData, 
        axiosConfig
    )
    // .then(response => response.status)
    // .catch(err => console.warn(err));
    // console.log(`response => ${response}`);

    // const response =  await FX_HTTP.get(endpoint);
    if (!!!response) return console.log(`Cannot fetch data from endpoint ${endpoint_}`);

    const data = response.data;
    // console.log(`FxDbConnection: fxFetchData: response.data => `, response.data);
    if (!!!data) return null;
    // console.log(data_);

    return data;
};

export const FX_SONOFF_ON = axios.create({
  baseURL: "http://192.168.1.247:8081/zeroconf/switch",
  headers: {
    "Content-type": "application/json"
  },
  data:{ 
        "deviceid": "", 
        "data": { 
            "switch": "on" 
        }
    }
});
export const FX_SONOFF_OFF = axios.create({
  baseURL: "http://192.168.1.247:8081/zeroconf/switch",
  headers: {
    "Content-type": "application/json"
  },
  data:{ 
        "deviceid": "", 
        "data": { 
            "switch": "off" 
        }
    }
});
