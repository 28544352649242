import React, { ReactNode, useEffect, useState } from 'react';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN, T_CSS_HORIZONTAL_ALIGN } from '../fxlib-react/src/utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../fxlib-react/src/utils/FxUtils';
// import RestService from '../services/RestService';
import FxCatalogItem from '../fxlib-react/src/components/FxCatalogItem';
import FxTable01 from '../fxlib-react/src/components/FxTable01';
import { fxFetchData, FX_HTTP } from '../fxlib-react/src/services/FxDbConnection';
import FxButton from '../fxlib-react/src/components/FxButton';
import FxLogo from '../fxlib-react/src/components/FxLogo';
// import FxPageContainer from '../fxlib-react/src/components/FxOutletContainer';
import FxSpeedDial, { T_SPEEDDIAL_ACTION } from '../fxlib-react/src/components/FxSpeedDial';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

/**
 * Descrive this component
 */

// DATABASE - TABLE CREATION
// CREATE TABLE `fx-site`.chickens (
// 	id INT UNSIGNED auto_increment NOT NULL,
// 	name CHAR(100) NULL,
// 	breed varchar(100) NULL,
// 	gender ENUM('male', 'female') NULL,
// 	age CHAR(10) UNSIGNED NULL,
// 	distinction TEXT NULL,
// 	description TEXT NULL,
// 	cohort CHAR(50) NULL,
// 	CONSTRAINT chickens_PK PRIMARY KEY (id)
// )
// ENGINE=InnoDB
// DEFAULT CHARSET=utf8mb4
// COLLATE=utf8mb4_general_ci;

interface IChickensPageProps {
    id?: string,
    children?: React.ReactNode,
    height?: string|number,
    width?: string|number,
    debug?: boolean,
    vAlign?: T_FX_VERTICAL_ALIGN;
    hAlign?: T_FX_HORIZONTAL_ALIGN;
    // event01: ()=>void,
}

// Must mirror DB: table 'chickens' (server: 'chickens.model.js' )
export type T_CHICKEN_DATA = {
    id: number,
    name: string,
    breed: string,
    gender: 'male'|'female',
    age: string|number,
    distinction: string|string[],
    description: string,
    cohort: string,
    gallery: string[],
    avatar: string,
    image: string,
}

const FxChickensPage = (props: IChickensPageProps)=>{
    console.log("FxChickensPage: entering: props => ", props);
    const debug_: boolean = !!props.debug?props.debug:false;
    const height_: string|number = !!props.height?props.height:'100%';
    const width_: string|number = !!props.width?props.width:'100%';
    const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
    const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';

    const [chickensData, setChickensData] = useState<T_CHICKEN_DATA[]>([]);
    // const paellaImg = require('./assets/images/paella.jpg');
    const [chickenCards, setchickenCards] = useState<React.ReactNode>(<div>DEFAULT</div>);

    const createChickenData = (chickenData?: T_CHICKEN_DATA)=>{
            console.log("createChickenData");

        const chickenDataTest = {
            name: 'Frank',
            breed: 'Red',
            gender: 'male',
            age: '3y',
            distinction: 'Orange/red eyes',
            description: 'Paixão da Sofia',
            cohort: 'Harém do Frank'
        }
        FX_HTTP.post("/chickens/create", chickenDataTest)
            .then(response => {
                const status = response.status;
                console.log(status);
            })
    }

    // Get all chicken data from API
    useEffect(() => {
        console.log("FxChickensPage: entering: useEffect");

        const readChickensData = async () => {
            const chickensData_: T_CHICKEN_DATA[] = await fxFetchData("/chickens");
            // const response =  await FX_HTTP.get("/chickens");
            // const chickensData_: T_CHICKEN_DATA[] = response.data;

            console.log(chickensData_);
            
            // setChickensData(chickensData);
            const chickenCards_ = getChickenCards(chickensData_);
            setchickenCards(chickenCards_);
        };

        readChickensData();
    }, []);

    // Map all records from 'chickensData' into FxCatalogItems
    const getChickenCards = (chickensData_: T_CHICKEN_DATA[])=>{
        var x: ReactNode = <div>NONE</div>;

        const getItemExpandContents = (chickenName: string)=>{

            const data: T_CHICKEN_DATA | undefined = chickensData_.find((chickenData: T_CHICKEN_DATA)=>chickenData.name == chickenName);
            if (!!!data) {
                console.log(`Cannot find local chicken ${chickenName}`);
                return <div>DEU PAU!!</div>
            }

            return <FxTable01 tableData={[data]} width='fit-content'/>
        }

        if (!!chickensData_) {
            // console.log(`============> image => ${chickensData_[0].image}`)
            x = chickensData_.map((recordSet: T_CHICKEN_DATA)=>
                // <FxLogo />
                <FxCatalogItem 
                    title={recordSet.name}//'FX Title'
                    subtitle={recordSet.breed}//'FX Sub-title'
                    synopsisTitle={recordSet.name}//'FX Synopsis Title'
                    synopsis={recordSet.description}//'FX synopsis text....'
                    imageSrc={recordSet.image}//'./assets/images/frank-01_DSC_0300.JPG'
                    expandContents={getItemExpandContents(recordSet.name)}
                />
            )
        } 
        return x;

        // return JSON.stringify(chickensData_);
        // return <FxLogo />;
    }

    const speedDialActions:T_SPEEDDIAL_ACTION[] = [
        { name: 'Copy',   icon: <FileCopyIcon />, tooltipVisibility: 'hover'},
        { name: 'Save',   icon: <SaveIcon />,     tooltipVisibility: 'hover'},
        { name: 'Print',  icon: <PrintIcon />,    tooltipVisibility: 'hover'},
        { name: 'Share',  icon: <ShareIcon />,    tooltipVisibility: 'hover'},
        { name: 'FX_Upload',     icon: <CloudUploadIcon />,    tooltipVisibility: 'hover', action: "upload"},
    ];


    return (

        // <FxPageContainer 
        //     id={props.id}
        //     debug={props.debug}
        //     vAlign={props.vAlign}
        //     hAlign={props.hAlign}
        //     height={props.height}
        //     width={props.width}
        // >
        <>
            {chickenCards}
            {/* <FxButton onClick={createChickenData}/> */}

            <FxSpeedDial 
                actions={speedDialActions}
            />
        </>    

        // </FxPageContainer>
    )
}

export default FxChickensPage;
