//* COMPONENT: FxOutletContainer
//* This page is intended to be the most minimalist version of the FX Site, containing only:
//* => page context definitions
//* =>  page header (logo, intellisearch)
//* =>  page body (lateral rulers, notifications)
//* =>  animated welcome page

// For further improvement:
// https://stackoverflow.com/questions/70172354/material-ui-use-palette-primary-color-based-on-selected-theme-mode/70172883#70172883

// IMPORTS -------------------------------------------------------
    import React, { useEffect, useRef, useState } from 'react';
    // import FxColumn from '../fxlib-react/src/components/FxGridColumn';
    import FxLogo from '../fxlib-react/src/components/FxLogo';
    // import FxRow from '../fxlib-react/src/components/FxRow';
    import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
    // import { CSSTransition } from 'react-transition-group';
    import { FX_PAGE_MAX_HEIGHT, fxAttachEvent, fxGetPosition, fxRecall, fxSetCssVar, reportWindowSize, setPositionCssVar, setTargetScale, setTranslateCssVar, showInfo } from '../fxlib-react/src/utils/FxUtils';
    import FxAutocomplete from '../fxlib-react/src/components/FxAutocomplete';
    // import FxGrid from '../fxlib-react/src/components/FxGrid';
    import { 
        Box, 
        Breadcrumbs, 
        Button, 
        ButtonProps, 
        Card, 
        IconButton, 
        Typography, 
        TypographyProps, 
        styled, 
        useTheme
    } from '@mui/material';
    import Brightness4Icon from '@mui/icons-material/Brightness4';
    import Brightness7Icon from '@mui/icons-material/Brightness7';
    // import NavigateNextIcon from '@mui/icons-material/NavigateNext';
    // import Link from '@mui/material/Link';
    // import code02 from './assets/images/code02.png';
    import './app-page.css';
    import { T_GRAPHIC_CONTEXT, T_PAGE_CONTEXT } from '../fxlib-react/src/utils/FxTypes';
    import { Flipper, Flipped } from "react-flip-toolkit";
    import FxTyped from '../fxlib-react/src/components/FxTyped';
    import FxSnackbar from '../fxlib-react/src/components/FxSnackbar';
    // import FxPageContainer from '../fxlib-react/src/components/FxOutletContainer';
    import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
    import SaveIcon from '@mui/icons-material/Save';
    import PrintIcon from '@mui/icons-material/Print';
    import FxSpeedDial, { T_SPEEDDIAL_ACTION } from '../fxlib-react/src/components/FxSpeedDial';
    import { fxtr, fxtrList, getOtherLanguageNames } from '../fxlib-react/src/services/FxPolyglot.service';
// ---------------------------------------------------------------------------

// DEV thinking:
    // Para resize, duas estrategias:
    // 1. Atualizar posicao dos elementos com CSS Transitions
    // 2. Substituir elemento com CSS Transition por um shadow/gemeo
    //    inserido de fato na posicao correto do ReactDOM.

    // Para a 2:
    // => Promover a transicao;
    // => Ativar elemento gemeo (em layer inferior)
    // => Causar fade-out e hide no transition-element
    // => 

    // Layers: z-index only works with positioned elements:
    // => non-positioned element:   position = static
    // => positioned element:       position = absolute, relative, sticky, fixed, ...
    // Starters of a new Stacking Context (NSC):
    // 1. <HTML>
    // 2. element with position=absolute|relative
    // 3. child of flex container
    // 4. child if grid container
    // 5. element with opacity (<1)
    // 6. element with transform
    // 7. element with isolation=isolate

export function usePageContext() {
  return useOutletContext<T_PAGE_CONTEXT>();
}

// COMPONENT INTERFACE -------------------------------------------------------
    interface IOutletContainerProps {
        id?: string,
        debug?: boolean,
        toggleColorMode?:()=>void,
        // pageId?: string,
        // headerVisibility?: string,
        setHomeScreen?: (active:boolean)=>void,
        changeLanguage?: (lang: string)=>void,
        lang?: string;
    }
// ---------------------------------------------------------------------------

// COMPONENT DEFINITION -------------------------------------------------------
    const FxOutletContainer = (props: IOutletContainerProps) => {
        // console.log('FxOutletContainer: entering: props => ', props);

        // CONSTANTS AND DEFAULTS ------------------------------------------------------------
            const navigate = useNavigate();
            const theme = useTheme();
            const location = useLocation();
            const headerLogoRef = useRef(null);
            const headerAutocompleteRef = useRef(null);
            const firstRenderRef = useRef(true);
            const logoReadyRef = useRef(false);
            const verticalWritingMode = 'vertical-lr'; //verticalWritingMode,

            const defaultGraphicContext:T_GRAPHIC_CONTEXT = {
                name: 'root',
                bgImageLight: undefined,
                bgOverlayLight: undefined,
                bgImageDark: undefined,
                bgOverlayDark: undefined,
            }

            const defaultActions: T_SPEEDDIAL_ACTION[] = [
                { name: 'Copy',   icon: <FileCopyIcon />, tooltipVisibility: 'hover'},
                { name: 'Save',   icon: <SaveIcon />,     tooltipVisibility: 'hover'},
                { name: 'Print',  icon: <PrintIcon />,    tooltipVisibility: 'hover'},
                // { name: 'Share',  icon: <ShareIcon />,    tooltipVisibility: 'hover'},
            ];

        // ---------------------------------------------------------------------------
        
        // PROPERTIES PRE-PROCESSING ---------------------------------------
            const debug_:boolean=!!props.debug?props.debug:false;
            const id_: string=!!props.id?props.id:"fx-outlet-container"
        // ---------------------------------------------------------------------------
        
        // STATE VARIABLES -----------------------------------------------------------
            const [headerRetracted, setHeaderRetracted] = useState(false);
            const [nextPage, setNextPage] = useState('');
            const [dummy, setDummy] = useState(false);
            const [bgImage, setBgImage] = useState('');
            const [bgAnimate, setBgAnimate] = useState('fadeIn 1s');
            const [bgOverlay, setBgOverlay] = useState('');
            const [logoChanged, setLogoChanged] = useState(true);
            // const [searchChanged, setSearchChanged] = useState(false);
            const [layoutChanged, setLayoutChanged] = useState(false);

            // Snackbar state
            const [messageOpen, setMessageOpen] = useState(false);
            const [message, setMessage] = useState<string|undefined>();

            // const [actions, setActions] = useState(defaultActions);
            const [actions, setActions] = useState<T_SPEEDDIAL_ACTION[]>([]);
            const [hidden, setHidden] = useState(true);

            // Texts, multilanguage
            const [msgList, setMsgList] = useState<string[]>([fxtr("welcome-01")]);
            const [autocompleteOptions, setAutocompleteOptions] = useState<string[]>(fxtrList("fx-autocomplete-opt"));
            // console.log('FxOutletContainer: msgList => ', msgList);
        // ---------------------------------------------------------------------------
        
        // CONTROL FUNCTIONS ---------------------------------------------------------
            const renderGraphicContext = (options: T_GRAPHIC_CONTEXT)=>{
                // console.log(`FxOutletContainer: renderGraphicContext: options => `, options);
                // console.log(`FxOutletContainer: renderGraphicContext: theme.palette.mode => [${theme.palette.mode}]`);
                // const animate = !!options.bgAnimate?options?.bgAnimate:'fadeIn 2s';
                // setBgAnimate(animate);
                if (theme.palette.mode == 'dark'){
                    const overlay = !!options.bgOverlayDark?options.bgOverlayDark:'';
                    // console.log(`FxOutletContainer: renderGraphicContext: overlay => [${overlay}]`);
                    fxSetCssVar('--bg-overlay', overlay); // TODO: unificar styling
                    setBgOverlay(overlay);
                    const image = !!options.bgImageDark?options.bgImageDark:'';
                    setBgImage(image);
                } else {
                    const overlay = !!options.bgOverlayLight?options.bgOverlayLight:'';
                    // console.log(`FxOutletContainer: renderGraphicContext: overlay => [${overlay}]`);
                    fxSetCssVar('--bg-overlay', overlay);
                    setBgOverlay(overlay);
                    const image = !!options.bgImageLight?options.bgImageLight:'';
                    setBgImage(image);
                }
            }

            // Reproduce colors in the conventional CSS space (MUI uses JSS)
            // https://stackoverflow.com/questions/66912925/how-can-i-add-mui-theme-color-to-inline-style
            const setCssColorMode = (mode:string|null)=>{
                if (!!mode) {
                    if (mode=='dark'){
                        fxSetCssVar('--mui-color-primary', theme.palette.primary.dark);
                        fxSetCssVar('--mui-color-secondary', theme.palette.secondary.dark);
                        fxSetCssVar('--mui-color-error', theme.palette.error.dark);
                        fxSetCssVar('--project-synopsis-bg', '#2a2a2a');
                        fxSetCssVar('--project-title-text-shadow', '1px 1px 4px black');
                        fxSetCssVar('--techlist-text-shadow', '1px 1px 2px black');
                        fxSetCssVar('--overline-text-shadow', 'none'); 
                    } else {
                        fxSetCssVar('--mui-color-primary', theme.palette.primary.light);
                        fxSetCssVar('--mui-color-secondary', theme.palette.secondary.light);
                        fxSetCssVar('--mui-color-error', theme.palette.error.light);
                        fxSetCssVar('--project-synopsis-bg', 'Gainsboro'); // error: colorManipulator.js:67 Uncaught Error: MUI: Unsupported `Gainsboro` color. The following formats are supported: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color().
                        fxSetCssVar('--project-synopsis-bg', '#dcdcdc');
                        fxSetCssVar('--project-title-text-shadow', '1px 1px 2px white');
                        fxSetCssVar('--techlist-text-shadow', '1px 1px 1px white');    
                        fxSetCssVar('--overline-text-shadow', '1px 1px 2px #808080c2'); 
                    }
                }
                // console.log(`App: setCssColorMode: bgOverlay => [${bgOverlay}]`);
                fxSetCssVar('--bg-overlay', bgOverlay);
            }

            const onSoftwareButtonClick = (event: string)=>{
                // console.log(`FxOutletContainer: onSoftwareButtonClick: event => ${event}`);
                // setPage(event);
            }

            const logoCallback = ()=>{
                // setLogoReady(true);
                logoReadyRef.current = true;
                setDummy(!dummy);
                // console.log('Logo is ready!!')
            }

            const  onLogoClick = ()=>{
                // console.log(`FXPageContainer2: onLogoClick: entering...`)
                // console.log(`location.pathname => ${location.pathname}`);
                // console.log(`location.key => ${location.key}`);

                // if (debug_) execHeaderTransition();
                // else 
                if (location.pathname != '/') {
                    // execHeaderTransition();
                    navigate('/');
                    setActions([]);
                    setHidden(true);
                }
            }

            const onLogoHover = ()=>{
                // 
            }

            const toggleColorMode = ()=>{
                if (!!props.toggleColorMode) props.toggleColorMode();
            }

            const onAutocompleteChange = (selectedValue:string)=>{
                console.log(`FxOutletContainer: onAutocompleteChange: selectedValue => ${selectedValue}`);

                const curRoute = location.pathname;
                // console.log(`FxOutletContainer: onAutocompleteChange: curRoute => ${curRoute}`);
                const curRouteArray = curRoute.split('/');
                const curRelativeRoute = curRouteArray[curRouteArray.length-1];
                // console.log(`FxOutletContainer: onAutocompleteChange: curRelativeRoute => ${curRelativeRoute}`);

                const words = selectedValue.replace(/[^a-zA-Z ]/g, "").split(" ");
                // console.log(words);
                const knownRoutes = ['software', 'portfolio', 'software/portfolio']; // TODO: eliminar rota corrente aqui a no auto complete.
                const selectedRoute = knownRoutes.find(route=>words.includes(route));

                if (!!selectedRoute) {
                    if (selectedRoute == curRelativeRoute) return;

                    console.log(`selectedRoute => `, selectedRoute);

                    setNextPage(selectedRoute);
                    navigate(selectedRoute);
                    toggleLogoChanged();
                    // execHeaderTransition();
                }
            }

            const toggleLogoChanged = () => setLogoChanged(prevState => !prevState);

            const toggleLayoutChanged = () => setLayoutChanged(prevState => !prevState);
            
            const showMessage_ = (message: string)=>{
                console.log(`FxOutletContainer: showMessage_: message => `, message);
                setMessage(message);
                setMessageOpen(true);
            }

            const setActions_ = (actions: T_SPEEDDIAL_ACTION[])=>{
                console.log(`FxOutletContainer: setActions_: actions => `, actions);
                setActions(actions);
                if (actions.length>0) setHidden(false);
            }
            const onLanguageClick = ()=>{
                var lang = props.lang;
                
                // Toggle pt-br <=> en-us (current options only)
                if (!!lang) {
                    if(lang=='pt-br') lang='en-us'
                    else lang='pt-br';
                    if (!!props.changeLanguage) props.changeLanguage(lang);
                }
            }
        // ---------------------------------------------------------------------------

        // This constant has scope dependencies, so must be placed here...
            const pageContext: T_PAGE_CONTEXT = {
                changeGraphicContext: renderGraphicContext,
                // triggerGraphicContext: false,
                // setTriggerGraphicContext: setTriggerGraphicContext,
                // graphicContext: graphicContextRef.current,
                showMessage: showMessage_,
                speedDialActions: setActions_,
                maxHeight: FX_PAGE_MAX_HEIGHT,
                lang: props.lang,
            };
        // ...

        // EVENTS CONTROL -----------------------------------------------------------
            useEffect(() => {
                // console.log(`FxOutletContainer: entering: useEffect : location.pathname => ${location.pathname}`);
                if (location.pathname=="/") {
                    // console.log('FxOutletContainer: location.pathname = /');
                    setHeaderRetracted(false);
                    renderGraphicContext(defaultGraphicContext);
                } else {
                    setHeaderRetracted(true);
                }
            }, [location.pathname]);

            fxSetCssVar('--bg-overlay', bgOverlay);
            setCssColorMode(theme.palette.mode);

            // FX: translations
            useEffect(() => {
                // Update translation of FX-Search typed messages
                setMsgList([fxtr("welcome-01")]);

                // Update translation of search options
                const options = fxtrList("fx-autocomplete-opt");
                setAutocompleteOptions(options);
            // }); // FX: always get translations
            }, [props.lang]);
        // ---------------------------------------------------------------------------



        // SUB COMPONENTS ---------------------------------------------------------
            const colorModeSwitch = ()=>{
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // bgcolor: 'background.default',
                            color: 'text.primary',
                            borderRadius: 1,
                            // p: 3,
                        }}
                    >
                        {/* {theme.palette.mode} mode */}
                        {theme.palette.mode === 'dark' ? fxtr('light-mode'):fxtr('dark-mode')}
                        <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
                            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                    </Box>
                );
            }

            const separator = ()=>{
                return (
                    <li>
                        <div style={{
                                content: "",
                                display: 'block',
                                width: '1px',
                                height: '40px',
                                // width: '40px',
                                // height: '1px',
                                margin: 'auto 10px',
                                backgroundColor: 'grey',
                            }}
                        />
                    </li>
                );
            }

            const LanguageButton = styled(Button)<ButtonProps>(({ theme }) => ({
                // color: theme.palette.getContrastText(purple[500]),
                // backgroundColor: purple[500],
                '&:hover': {
                    // backgroundColor: purple[700],
                    backgroundColor: 'unset',
                    // fontSize: '1rem',
                    boxShadow: 'none',
                },
            }));
            const TypoButton = styled(Typography)<TypographyProps>(({ theme }) => ({
                // color: theme.palette.getContrastText(purple[500]),
                // backgroundColor: purple[500],
                '&:hover': {
                    // backgroundColor: purple[700],
                    // backgroundColor: 'red',
                    fontSize: 'calc(135%)',
                    // boxShadow: 'none',
                },
            }));
        // ---------------------------------------------------------------------------



        // ----------------------------------------------------------------
        // BEGIN: RETURN --------------------------------------------------
        return (
            <div id={id_} 
                style={{
                    position:'fixed', // <== Correct 100% viewport available problem, but only 100vh
                    top: '0px',
                    left: '0px',
                    // position:'absolute', // <== Correct 100% viewport available problem
                    width: '100%', // <== Correct 100% viewport available problem
                    height: '100%', // <== Correct 100% viewport available problem
                    minWidth: '400px',
                    minHeight: '100%',
                    // paddingLeft: '10%', // ==> shrinks menu also (undesirable)
                    // paddingRight: '10%',
                    zIndex: 1,
                    // paddingTop: '50px',
                    // marginTop: '50px',
                    // bottom: '0px',
                }}
            >

                <div className='bg-image-control'
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%', 
                        height: '100%',
                        // backgroundImage: 'url("./assets/images/back01.jpg"), url("./assets/images/img01.jpg")', 
                        // backgroundSize: '200%',
                        // background: 'red',
                        // backgroundImage: `url('./assets/images/code02.png')`,
                        backgroundImage: `url(${bgImage})`,
                        // backgroundSize: 'cover', // stretch, but NOT shrink (when img bigger than screen)
                        backgroundSize: '100% 100%', // stretch/shrink the image
                        backgroundPosition: '50px -10px', // xxxxxx
                        // backgroundPosition: '0px -10px', // xxxxxx
                        // backgroundColor: 'red',
                        // backgroundBlendMode: 'screen',
                        // backgroundBlendMode: 'overlay',
                        backgroundRepeat: 'no-repeat',
                        // transform: "scale(1) rotate(30deg) skew(20deg, 30deg) translate(10px, 20px)",
                        // perspective: '10px',
                        animation: bgAnimate,
                    }}
                >

                    <div className='wrapper-3'>

                        {/* <div
                            // style={{
                            //     position:   'fixed',
                                // top:        '16px',
                                // right:      '16px'
                            // }}
                        >
                            <FxSnackbar 
                                horizontal='right'
                                vertical='top'
                                message='FX rules!!!'
                                open={messageOpen}
                                // open={false}
                            />
                        </div> */}

                        {/* --------------- LOGO --------------- */}
                            <Flipper 
                                // flipKey={logoChanged}
                                flipKey={headerRetracted} 
                                className='logo-wrapper'
                            >
                                <Flipped flipId="logo" stagger>

                                    {/* <div className='wrapper-3'> */}
                                    
                                        <div
                                            // className={logoChanged ? "logo-changed" : "logo-original"}
                                            // className={headerRetracted ? "logo-changed" : "logo-original"}
                                            className={headerRetracted ? "logo-retracted" : "logo-expanded"}
                                            onClick={toggleLogoChanged}
                                        >
                                            <FxLogo 
                                                id='element-header-logo'
                                                debug={debug_} 
                                                vAlign='center'
                                                logoRef={headerLogoRef} // React.MutableRefObject<null>
                                                // position='absolute'
                                                // visibility={undefined}
                                                ready={logoCallback}
                                                onClick={onLogoClick}
                                                onHover={onLogoHover}
                                                cursor='pointer'
                                            />
                                        </div>

                                    {/* </div> */}

                                </Flipped>


                                

                                <Flipped flipId="search">
                                    {/* <div className='wrapper-3'> */}
                                        <div
                                            // className={searchChanged ? "search-changed" : "search-original"}
                                            // className={logoChanged ? "search-changed" : "search-original"}
                                            // className={headerRetracted ? "search-changed" : "search-original"}
                                            className={headerRetracted ? "search-retracted" : "search-expanded"}
                                            // onClick={toggleSearchChanged}
                                        >
                                            <FxAutocomplete 
                                                id='header-autocomplete'
                                                debug={debug_} 
                                                vAlign='top'
                                                onChange={onAutocompleteChange}
                                                options={autocompleteOptions}
                                                clearOnChange={true}
                                                // inputValue={!!autocompleteInputValue?autocompleteInputValue:''}
                                            />
                                        </div>
                                    {/* </div> */}
                                </Flipped>

                                <Flipped flipId="typed">

                                    <div 
                                        // className='typed-original'
                                        // className={logoChanged ? "typed-changed" : "typed-original"}
                                        // className={headerRetracted ? "typed-changed" : "typed-original"}
                                        className={headerRetracted ? "typed-retracted" : "typed-expanded"}
                                    >
                                        <FxTyped 
                                            id='component-typed'
                                            // paddingLeft='10%'
                                            // paddingRight='10%'
                                            // phrases={['Olá, bem-vindo!\nAqui, boas causas levam a efeitos incríveis!!\nDê uma dica do que você procura na linha abaixo.\nVou fazer o meu melhor para trazer o conteúdo certinho, ok?!\n;-)']}
                                            phrases={msgList}
                                            typeSpeed={20}
                                            backSpeed={25}
                                            showCursor={true}
                                            cursorChar='_'
                                        />
                                    </div>
                                </Flipped>

                            </Flipper>
                        {/* ------------------------------------ */}

                        {/* --------------- MENU --------------- */}
                            {/* <div className='menu-3'>MENU</div> */}
                        {/* ------------------------------------ */}

                        {/* --------------- LEFT BAR --------------- */}
                            <div className='left-3'>
                                <div className='control-col-v-left'>
                                    <ul className='v-left' style={{
                                        // display: 'block',
                                        listStyleType: 'disc',
                                        marginBlockStart: '1em',
                                        marginBlockEnd: '1em',
                                        marginInlineStart: '0px',
                                        marginInlineEnd: '0px',
                                        paddingInlineStart: '40px',

                                        display: 'flex',
                                        flexDirection: 'column',
                                        WebkitBoxAlign: 'center',
                                        alignItems: 'center',
                                        margin: '0px',
                                        padding: '0px',
                                        listStyle: 'none',
                                    }}>


                                        <li>
                                            <div style={{
                                                    margin: '10px auto',
                                                    // padding: '10px',
                                                    // font-family: var(--font-mono),
                                                    // font-size: var(--fz-xxs),
                                                    // line-height: var(--fz-lg),
                                                    letterSpacing: '0.1em',
                                                    WebkitWritingMode: verticalWritingMode,
                                                    writingMode: verticalWritingMode,
                                                    // transformOrigin: 'center center',
                                                    // transform: 'rotate(270deg) translate(50%, 5px)',
                                                    // width: 'max-content',
                                                }}
                                            >
                                                <Typography>{fxtr('side-hire')}</Typography>
                                            </div>
                                        </li>

                                        {separator()}

                                        <li>
                                            <div style={{
                                                    margin: '10px auto',
                                                    // padding: '10px',
                                                    // font-family: var(--font-mono),
                                                    // font-size: var(--fz-xxs),
                                                    // line-height: var(--fz-lg),
                                                    letterSpacing: '0.1em',
                                                    WebkitWritingMode: verticalWritingMode,
                                                    writingMode: verticalWritingMode,
                                                    // transformOrigin: 'center center',
                                                    // transform: 'rotate(270deg) translate(50%, 5px)',
                                                    // width: 'max-content',
                                                }}
                                            >
                                                <LanguageButton
                                                    // onClick={() => console.log(`língua`)}
                                                    onClick={onLanguageClick}
                                                    disableRipple
                                                >


                                                    <TypoButton>{getOtherLanguageNames(props.lang)}</TypoButton>


                                                </LanguageButton>
                                            </div>
                                        </li>


                                        {separator()}


                                        {/* <li>
                                            <div style={{
                                                    margin: '10px auto',
                                                    // padding: '10px',
                                                    // font-family: var(--font-mono),
                                                    // font-size: var(--fz-xxs),
                                                    // line-height: var(--fz-lg),
                                                    letterSpacing: '0.1em',
                                                    // WebkitWritingMode: verticalWritingMode,
                                                    // writingMode: verticalWritingMode,
                                                    // transformOrigin: 'center center',
                                                    // transform: 'rotate(270deg) translate(50%, 5px)',
                                                }}
                                            >
                                                <Typography>language</Typography>
                                            </div>
                                        </li>


                                        {separator()} */}


                                        <li>
                                            <div style={{
                                                    margin: '10px auto',
                                                    // padding: '10px',
                                                    // font-family: var(--font-mono),
                                                    // font-size: var(--fz-xxs),
                                                    // line-height: var(--fz-lg),
                                                    letterSpacing: '0.1em',
                                                    WebkitWritingMode: verticalWritingMode,
                                                    writingMode: verticalWritingMode,
                                                    // transformOrigin: 'center center',
                                                    // transform: 'rotate(270deg) translate(50%, 5px)',
                                                    // width: 'max-content',
                                                }}
                                            >
                                                {colorModeSwitch()}
                                            </div>
                                        </li>


                                        {separator()}


                                        {/* <li>
                                            <div style={{
                                                    margin: '10px auto',
                                                    // padding: '10px',
                                                    // font-family: var(--font-mono),
                                                    // font-size: var(--fz-xxs),
                                                    // line-height: var(--fz-lg),
                                                    letterSpacing: '0.1em',
                                                    // WebkitWritingMode: verticalWritingMode,
                                                    // writingMode: verticalWritingMode,
                                                    transformOrigin: 'center center',
                                                    transform: 'rotate(270deg) translate(50%, 5px)',
                                                    width: 'max-content',
                                                }}
                                            >
                                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                                    <Link 
                                                        underline="hover" 
                                                        key="1" 
                                                        color="inherit" 
                                                        href="/" 
                                                        // onClick={handleClick}
                                                    >
                                                        FX
                                                    </Link>,
                                                </Breadcrumbs>
                                            </div>
                                        </li> */}

                                        {/* {separator()}
                                        <li style={{
                                            marginBottom: '20px',
                                        }}>
                                            {svgLogo()}
                                        </li> */}


                                    </ul>
                                </div>
                            </div>
                        {/* ------------------------------------ */}

                        {/* --------------- PAGE CONTENT --------------- */}
                            <div id={`page-container`}
                                className='content-3'
                                style={{
                                    background:     debug_?'blue':'initial', // apenas para debugging
                                    border:         debug_?'dashed red 2px':'initial',
                                    // height:         height_,
                                    // // height:         '60vh',
                                    // width:          width_, 
                                    // justifyContent: cssHAlign,               // horizontal align
                                    // alignItems:     cssVAlign,               // vertical align
                                    // // padding:        '3vh',

                                    // TODO: integrar com padding geral dos layouts
                                    paddingTop:     '2vh',
                                    paddingRight:   '6vw',
                                    paddingLeft:    '4vw',


                                    // FX: consistent with grid layout (wrapper-3 / content-3)
                                    height: '90vh',         // Still a mobile issue (https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser/72245072#72245072)
                                    display: 'grid',
                                    overflowY: 'hidden',
                                    marginBottom: '2vh',
                                }}
                            >
                                <FxSnackbar 
                                    // debug={true}
                                    horizontal='right'
                                    vertical='top'
                                    message={message}
                                    onMessageChange={setMessage}
                                    open={messageOpen}
                                    onOpenChange={setMessageOpen}
                                    duration={5000}
                                />
                                
                                <Outlet context={pageContext}/>
                                
                                <div
                                    style={{
                                        position:   'fixed',
                                        bottom:     '16px',
                                        right:      '16px'
                                    }}
                                >
                                    <FxSpeedDial
                                        id="page-container-speeddial"
                                        // debug={true}
                                        // height="100%"
                                        actions={actions}
                                        // hidden={actions.length>0}
                                        hidden={hidden}
                                    />
                                </div>
                            </div>
                        {/* ------------------------------------ */}

                    </div>

                </div>

            </div>
        )
        // END: RETURN ----------------------------------------------------
        // ----------------------------------------------------------------

    }
// ---------------------------------------------------------------------------

export default FxOutletContainer;
