import React, { useEffect, useState } from 'react';
// import RestService from '../services/RestService';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_FX_VERTICAL_ALIGN, T_IMAGE_METADATA } from '../utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../utils/FxUtils';
import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Replace 'Masonry' for the 'ComponentName', case sensitive
 */


interface IMasonryProps {
    // prop01: string,
    // event01: ()=>void
    id?: string,
    debug?: boolean,
    height?: string|number,
    width?: string|number,
    vAlign?: T_FX_VERTICAL_ALIGN;
    hAlign?: T_FX_HORIZONTAL_ALIGN;
    imageList: T_IMAGE_METADATA[];
    baseUrl: string; // Example: http://localhost
}

const FxMasonry = (props: IMasonryProps)=>{
    // console.log("FxMasonry: entering: props => ", props);

    // PROPERTIES PRE-PROCESSING ---------------------------------------
        const id_: string = !!props.id?props.id:"fx-masonry";
        const debug_: boolean = !!props.debug?props.debug:false;
        // const height_: string|number = !!props.height?props.height:'100%';
        const height_: string|number|undefined = !!props.height?props.height:undefined;
        const width_: string|number = !!props.width?props.width:'100%';
        const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
        const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
        const baseUrl_ = props.baseUrl.endsWith("/")?props.baseUrl:`${props.baseUrl}/`;
    // ---------------------------------------------------------------------------

    const theme = useTheme();
    const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
    // console.log(`FxMansory: isMobile => `, isBigScreen);
    
      return (
        <Box>
            <ImageList variant="masonry" cols={isBigScreen ? 3 : 2} gap={8} sx={{paddingTop: 0}}>
                
                {props.imageList.map((item:T_IMAGE_METADATA) => (
                    <ImageListItem key={item.uuid}>
                        <img
                            srcSet={`${baseUrl_}${item.file}`}
                            src={`${baseUrl_}${item.file}`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
                
            </ImageList>
        </Box>
  );
}

export default FxMasonry;
