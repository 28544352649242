import React, { useEffect } from 'react';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN, T_CSS_HORIZONTAL_ALIGN } from '../utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../utils/FxUtils';
// import RestService from '../services/RestService';
import { TableRow, TableCell, Table, TableBody, TableContainer, Paper } from '@mui/material';

/**
 * Tabela feita para inserir na ChickensPage, CatalogItem
 */

// function Row(props: { row: ReturnType<typeof createData> }) {
function Row(props: { row: { [key: string]: any; } }) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {
            // Object.keys(row).map((key)=><TableCell align="right">{row[key]}</TableCell>)
            Object.keys(row).map(
                (key: string)=>{
                    // if (!hiddenKeys.includes(key.)) 
                        return <TableCell align="left">{row[key]}</TableCell>;
                    //     return <div>{key}</div>;
                    // else return <div>hidden</div>
                }
            )
            
        }
      </TableRow>

    </React.Fragment>
  );
}

interface ITable01Props {
    id?: string,
    children?: React.ReactNode,
    height?: string|number,
    width?: string|number,
    debug?: boolean,
    vAlign?: T_FX_VERTICAL_ALIGN,
    hAlign?: T_FX_HORIZONTAL_ALIGN,
    // event01: ()=>void,
    tableData?: any[],
}

const FxTable01 = (props: ITable01Props)=>{
    console.log("FxTable01: entering: props => ", props);
    const debug_: boolean                       = !!props.debug?props.debug:false;
    const height_: string|number                = !!props.height?props.height:'100%';
    const width_: string|number                 = !!props.width?props.width:'100%';
    const cssVAlign: T_CSS_VERTICAL_ALIGN       = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
    const cssHAlign: T_CSS_HORIZONTAL_ALIGN     = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
    const tableData_: { [key: string]: any; }[]   = !!props.tableData?props.tableData:[{}];

    const createTable = (tableData: { [itemKey: string]: string; }[])=>{
        const reactTable = tableData.map((rowItems: { [itemKey: string]: string; })=>{
            // rowItems example:
            // itemKey: rowItems[itemKey]
            // {
            //     id: 1,
            //     name: "Frank",
            //     breed: "Red",
            //     gender: "male",
            //     age: "3y",
            //     description: "Paixão da Sofia",
            //     distinction: "Orange/red eyes",
            //     cohort: "Harém do Frank",
            // }
            const rowKeys = Object.keys(rowItems);
            const hiddenKeys = ['id', 'description'];
            const validRowKeys = rowKeys.filter(key=>!hiddenKeys.includes(key))
            const listRow = validRowKeys.map((itemKey: string) => {
                if (hiddenKeys.includes(itemKey)) return;
                const value = { 
                    header: itemKey,
                    itemKey: rowItems[itemKey] 
                };
                return <Row key={itemKey} row={value} />
            });
            return listRow;
        })
        return reactTable;
    }

    const table = createTable(tableData_);

    return (
        <div 
            id={props.id}
            style={{
                background:     debug_?'blue':'initial', // apenas para debugging
                border:         debug_?'dashed red 2px':'initial',
                height:         height_,
                width:          width_, 
                justifyContent: cssHAlign,               // horizontal align
                alignItems:     cssVAlign,               // vertical align
            }}
        >
            <TableContainer component={Paper}>
                <Table 
                    aria-label="collapsible table"
                    size='small'
                >

                    {/* <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Dessert (100g serving)</TableCell>
                            <TableCell align="right">Calories</TableCell>
                            <TableCell align="right">Fat&nbsp;(g)</TableCell>
                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            <TableCell align="right">Protein&nbsp;(g)</TableCell>
                        </TableRow>
                    </TableHead> */}


                    <TableBody>
                    {/* {tableData_.map((row: any) => (
                        <Row key={row.id} row={row} />
                    ))} */}



                    {/* {header} */}
                    {table}



                    </TableBody>


                </Table>
            </TableContainer>
            {/* {props.children} */}
        </div>
    )
}

export default FxTable01;
