// IMPORTS -------------------------------------------------------
    import React, { useEffect } from 'react';
    // import RestService from '../services/RestService';
    import { 
        T_FX_VERTICAL_ALIGN, 
        T_FX_HORIZONTAL_ALIGN, 
        T_CSS_HORIZONTAL_ALIGN, 
        T_CSS_VERTICAL_ALIGN 
    } from '../../utils/FxTypes';
    import { 
        fxGetCSSVerticalAlign, 
        fxGetCSSHorizontalAlign 
    } from '../../utils/FxUtils';
import { Grid } from '@mui/material';
// ---------------------------------------------------------------------------

/**
 * Playing around with the MUI V5 Grid V1 to learn about layout responsiveness.
 */

// TYPES (avoid!!! use FxTypes.ts instead!!!------------------------------------------------------
    type T_NEW_TYPE = {
    
    }
// ---------------------------------------------------------------------------

// COMPONENT INTERFACE -------------------------------------------------------
    interface IGrid_TESTProps {
        id?: string,
        children?: React.ReactNode,
        height?: string|number,
        width?: string|number,
        debug?: boolean,
        vAlign?: T_FX_VERTICAL_ALIGN;
        hAlign?: T_FX_HORIZONTAL_ALIGN;
        // event01: ()=>void,
    }
// ---------------------------------------------------------------------------

// COMPONENT DEFINITION -------------------------------------------------------
    const FxGrid_TEST = (props: IGrid_TESTProps)=>{
        console.log("FxGrid_TEST: entering: props => ", props);

        // CONSTANTS AND DEFAULTS ------------------------------------------------------------
            // const constant01 = 1010;
        // ---------------------------------------------------------------------------
        
        // PROPERTIES PRE-PROCESSING ---------------------------------------
            // => INITS, DEFAULTS, VALIDATION AND SANITIZATION
            const debug_: boolean = !!props.debug?props.debug:false;
            // const height_: string|number = !!props.height?props.height:'100%';
            const height_: string|number = !!props.height?props.height:'90vh';
            const width_: string|number = !!props.width?props.width:'100%';
            const cssVAlign: T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
            const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
        // ---------------------------------------------------------------------------

        // STATE VARIABLES -----------------------------------------------------------
            // const [state01, setState01] = useState<string>('');
            // const [someData, setSomeData] = useState<string>('');
        // ---------------------------------------------------------------------------

        // CONTROL FUNCTIONS ---------------------------------------------------------
            // const getSomeData = () => {
                // RestService.getSomeData()
                // .then(response => {
                //     setSomeData(response.data);
                //     console.log(response.data);
                // })
                // .catch(e => {
                //     console.log(e);
                // });
            // };
        // ---------------------------------------------------------------------------

        // EVENTS CONTROL -----------------------------------------------------------
            // useEffect(() => {
            //     console.log("FxGrid_TEST: entering: useEffect");
            // }, []);
        // ---------------------------------------------------------------------------

        // STYLES -------------------------------------------------------------------
        // ---------------------------------------------------------------------------

        // SUB COMPONENTS ---------------------------------------------------------
        // ---------------------------------------------------------------------------

        // RENDER --------------------------------------------------------------------
            return (
                <div 
                    id={props.id}
                    style={{
                        // FxComponent defaults
                        display:        "flex", // if "block", justifyContent will not work!! 
                        background:     debug_?'blue':'initial', // apenas para debugging
                        border:         debug_?'dashed red 2px':'initial',
                        height:         height_,
                        width:          width_, 
                        justifyContent: cssHAlign,               // horizontal align
                        alignItems:     cssVAlign,                   // vertical align
                    }}
                >
                    <Grid container 
                        // spacing={2}
                    >

                        {/* 
                            xs, extra-small: 0px
                            sm, small: 600px
                            md, medium: 900px
                            lg, large: 1200px
                            xl, extra-large: 1536px 
                        */}
                        <Grid item xs={12} sm={12} md={8}
                            style={{
                                background:     debug_?'green':'initial', // apenas para debugging
                                transition: 'all 1s ease-in-out',
                            }}
                        >
                            grid item 01
                        </Grid>
                        {/* <Grid item xs={4}> */}
                        <Grid item xs
                            style={{
                                background:     debug_?'red':'initial', // apenas para debugging
                                transition: 'all 1s ease-in-out',
                            }}
                        >
                            grid item 02
                        </Grid>
                    </Grid>
                </div>
            )
        // ---------------------------------------------------------------------------
    }
// ---------------------------------------------------------------------------

// EXPORTS -------------------------------------------------------
    export default FxGrid_TEST;
// ---------------------------------------------------------------------------
