import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red, green, grey } from '@mui/material/colors';
// import SpecialElite from './fonts/SpecialElite-Regular.ttf';
import "typeface-special-elite";
import '@fontsource/roboto-mono';
// import './theme.css';
// import { alpha } from "@mui/material";
// import paper from './assets/images/vintage-paper01.jpg';


// Theme configuration variables
// Changing the theme configuration variables is the most effective way to match MUI to your needs. 
// The following sections cover the most important theme variables:

// .palette
// .typography
// .spacing
// .breakpoints
// .zIndex
// .transitions
// .components
// You can check out the <default theme section> to view the default theme in full.
// https://mui.com/customization/default-theme/


// Test dark mode.
// REF: https://mui.com/material-ui/customization/dark-mode/
// Cuatom colo-set: when using TypeScript, you would also need to use 
// module augmentation for the theme to accept the above values.

declare module '@mui/material/styles' {
  interface Theme {
    fxcolorset?: {
      fxcolor01: React.CSSProperties['color'];
    };
  }
interface ThemeOptions {
    fxcolorset?: {
      fxcolor01: React.CSSProperties['color'];
    };
  }

  interface Palette {
    fxcolor?: Palette['primary'];
  }
  interface PaletteOptions {
    fxcolor?: PaletteOptions['primary'];
  }
}
export const darkTheme = responsiveFontSizes(createTheme({
    fxcolorset: {
        fxcolor01: '#64ffda',
    },

  palette: {
    mode: 'dark',

    primary: {
        main: '#d1d0d0', //grey.A400,
        light: '#d1d0d0', //'rgba(255, 255, 255, 0.8)', //'#ccd6f6', //grey.A400,
        dark: '#d1d0d0', //'rgba(255, 255, 255, 0.8)', //'#ccd6f6', //grey.A400,
        // contrastText: will be calculated to contrast with palette.primary.main
    },

    secondary: {
    //   main: '#19857b', // teal #
      main: '#64ffda', // --green
      light: '#64ffda',
      dark: '#64ffda',
    },

    error: {
      main: red.A400,
    },

    warning: {
      main: red.A400,
    },

    info: {
      main: red.A400,
    },

    success: {
      main: red.A400,
    },

    fxcolor: {
      main: '#64ffda', // --green
    },

  },
  // TYPOGRAPHY
  typography: {
    fontFamily: [
      'Special Elite',
      '"Roboto Mono", monospace'
    ].join(',')
  },
}));




// A custom theme for this app
// const theme = createTheme({
export const lightTheme = responsiveFontSizes(createTheme({

    // THEME MAIN COLORS
  palette: {
    mode: 'light',
    background: {
    //   default: 'Gainsboro',
      default: '#dcdcdc',
    },
    primary: {
    //   main: '#000000de',
    //   main: 'rgba(0 0 0 87%)',
        main: '#111111', //grey.A400,
        light: '#111111', //grey.A400,
        dark: '#111111', //grey.A400,
        // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#64ffda',
      light: '#64ffda',
      dark: '#64ffda',
    },
    error: {
      main: red.A400,
    },
  },

    // MuiCssBaseline: {
    //   '@global': {
    //     body: {
    //       border: '2px solid blue',
    //       backgroundImage: 'url("/static/wood.png")',
    //     },
    //   },
    // },
    // TYPOGRAPHY
  typography: {
    fontFamily: [
      'Special Elite',
      '"Roboto Mono", monospace'
    ].join(',')
  },


//   components: {
//     MuiCssBaseline: {
//       styleOverrides: {
//     //   `
//     //     @font-face {
//     //       font-family: 'Special Elite';
//     //       src: local('Special Elite'), url('${process.env.PUBLIC_URL}/assets/fonts/SpecialElite-Regular.ttf') format('truetype');
//     //     }
//     //   `,
//         body: {
//             backgroundImage: `url(${paper}),`,
//         }
//       }
//     },
//   },


}));
// theme = responsiveFontSizes(theme);
// lightTheme = responsiveFontSizes(lightTheme);
                    // background: `url('${process.env.PUBLIC_URL}/assets/img/floorplan01.png')`,
        //   font-style: normal;
        //   font-display: swap;
        //   font-weight: 400;

        //   src: local('Special Elite'), local('Raleway-Regular'), url(${SpecialElite}) format('truetype');
        //   unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

// export default theme;
