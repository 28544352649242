import React, { useEffect } from 'react';
import { T_FX_VERTICAL_ALIGN, T_FX_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN, T_CSS_HORIZONTAL_ALIGN } from '../utils/FxTypes';
import { fxGetCSSVerticalAlign, fxGetCSSHorizontalAlign } from '../utils/FxUtils';
// import RestService from '../services/RestService';
// import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/**
 * Descrive this component
 */

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ICatalogItemProps {
    id?: string,
    children?: React.ReactNode,
    height?: string|number,
    width?: string|number,
    debug?: boolean,
    vAlign?: T_FX_VERTICAL_ALIGN,
    hAlign?: T_FX_HORIZONTAL_ALIGN,
    // event01: ()=>void,
    title?: string,
    subtitle?: string,
    imageSrc?: string,
    imageHeight?: number,
    imageAlt?: string,
    avatarAbbreviation?:string,
    synopsis?: string,
    synopsisTitle?: string,
    expandTitle?: string,
    expandContents?: React.ReactNode,
    itemData?: string[],
    // itemData?: {}[],
    // itemData?: any[],
}


const FxCatalogItem = (props: ICatalogItemProps)=>{
    console.log("FxCatalogItem: entering: props => ", props);
    const debug_: boolean                   = !!props.debug?props.debug:false;
    const height_: string|number            = !!props.height?props.height:'100%';
    const width_: string|number             = !!props.width?props.width:'100%';
    const cssVAlign: T_CSS_VERTICAL_ALIGN   = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
    const cssHAlign: T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
    const imageSrc_: string                 = !!props.imageSrc?props.imageSrc: './assets/images/FX_WhiteonBlack_500x500.jpeg';
    const title_: string                    = !!props.title?props.title:"FxCatalogItem Title";
    const subtitle_: string                 = !!props.subtitle?props.subtitle:"FxCatalogItem subtitle";
    const imageHeight_: number              = !!props.imageHeight?props.imageHeight:194;
    const imageAlt_: string                 = !!props.imageAlt?props.imageAlt:"FxCatalogItem Image Alt";
    const avatarAbbreviation_: string       = !!props.avatarAbbreviation?props.avatarAbbreviation:"FX";
    const synopsis_: string                 = !!props.synopsis?props.synopsis:"FxCatalogItem synopsis...";
    const synopsisTitle_: string            = !!props.synopsisTitle?props.synopsisTitle:"FxCatalogItem Synopsis Title";
    const expandTitle_: string              = !!props.expandTitle?props.expandTitle:"FxCatalogItem Expand Title";
    const expandContents_: React.ReactNode  = !!props.expandContents?props.expandContents:<div><p>Expand Contents</p></div>;
    // const image = require(imageSrc_);

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    return (
        <div 
            id={props.id}
            style={{
                background:     debug_?'blue':'initial', // apenas para debugging
                border:         debug_?'dashed red 2px':'initial',
                height:         height_,
                width:          width_, 
                justifyContent: cssHAlign,               // horizontal align
                alignItems:     cssVAlign,               // vertical align
            }}
        >
            <Card 
                id={`FxCatalogItem-container`}
                sx={{ 
                    // maxWidth: 345 
                }}>

                <CardHeader
                    id={`FxCatalogItem-header`}
                    avatar={
                        <Avatar 
                            sx={{ bgcolor: red[500] }} 
                            aria-label="recipe"
                        >
                            {avatarAbbreviation_}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={title_}
                    subheader={subtitle_}
                />

                <CardMedia
                    id={`FxCatalogItem-image`}
                    component="img"
                    height={imageHeight_}
                    // image="/static/images/cards/paella.jpg"
                    // image={paellaImg}
                    image={imageSrc_}
                    alt={imageAlt_}
                />

                <CardContent id={`FxCatalogItem-synopsis`}>

                    <Typography variant="h5" color="text.secondary" display="block">
                        {synopsisTitle_}
                    </Typography>


                    {/* <Typography variant="body2" color="text.secondary" paragraph={true}> */}
                    <Typography variant="body2" color="text.secondary" display="block">
                        {synopsis_}
                    </Typography>
                </CardContent>

                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                    <ShareIcon />
                    </IconButton>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                    <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph>{expandTitle_}</Typography>

                        {/* Expand Contents BEGIN*/}
                            {expandContents_}
                            {/* <Typography paragraph>
                                Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
                                aside for 10 minutes.
                            </Typography>
                            <Typography paragraph>
                                Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
                                medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
                                occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
                                large plate and set aside, leaving chicken and chorizo in the pan. Add
                                pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
                                stirring often until thickened and fragrant, about 10 minutes. Add
                                saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
                            </Typography>
                            <Typography paragraph>
                                Add rice and stir very gently to distribute. Top with artichokes and
                                peppers, and cook without stirring, until most of the liquid is absorbed,
                                15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
                                mussels, tucking them down into the rice, and cook again without
                                stirring, until mussels have opened and rice is just tender, 5 to 7
                                minutes more. (Discard any mussels that don&apos;t open.)
                            </Typography>
                            <Typography>
                                Set aside off of the heat to let rest for 10 minutes, and then serve.
                            </Typography> */}
                        {/* Expand Contents END*/}

                    </CardContent>
                </Collapse>

            </Card>
            {/* {props.children} */}
        </div>
    )
}

export default FxCatalogItem;
