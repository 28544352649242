
// Relative path to fxlib-react code: ../fxlib-react/src
// Relative path to fxlib-react assets: ../../fxlib-react/assets
import { Grid, Box, Button, Container, Paper, styled, Typography, useTheme, debounce } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import FxButton from        '../fxlib-react/src/components/FxButton';
// import RestService from     '../fxlib-react/src/services/RestService';
import { T_CSS_HORIZONTAL_ALIGN, T_CSS_VERTICAL_ALIGN, T_FX_ACCORDION_DATA, T_FX_HORIZONTAL_ALIGN, T_FX_VERTICAL_ALIGN, T_GRAPHIC_CONTEXT } from '../fxlib-react/src/utils/FxTypes';
import { checkVisible, createCustomElement, fxGetCSSHorizontalAlign, fxGetCSSVerticalAlign, str2html } from '../fxlib-react/src/utils/FxUtils';
import FxCanvas from '../fxlib-react/src/components/FxCanvas';
import { useNavigate } from 'react-router-dom';


import FxColumn from '../fxlib-react/src/components/FxGridColumn';
import FxRow from '../fxlib-react/src/components/FxRow';
// If sometime want to use MUI Grid v2: update MUI versions:
// https://stackoverflow.com/questions/76585251/unable-to-use-grid2-with-vite-project
// import Grid from '@mui/material/Unstable_Grid2'

import { CSSTransition } from 'react-transition-group';
import { usePageContext } from './FxOutletContainer';
import portfolioButtonImg from './assets/images/portfolio02.svg';
import bgSoftwareImgBlack from './assets/images/code02.png';
// eslint-disable-next-line
import bgSoftwareImgWhite from './assets/images/code02.png';
import FxPortfolioPage from './FxPortfolioPage';
import FxAccordion from '../fxlib-react/src/components/FxAccordion';
import { fxtr } from '../fxlib-react/src/services/FxPolyglot.service';
import { decode } from 'punycode';
import { Property } from 'csstype';


// scroll
// import useScrollSnap from "../fxlib-react/src/hooks/useScrollSnap";




/**
 *
 */


interface ISoftwareProps {
    id?: string,
    children?: React.ReactNode,
    height?: string,
    width?: string|number,
    debug?: boolean,
    vAlign?: T_FX_VERTICAL_ALIGN,
    hAlign?: T_FX_HORIZONTAL_ALIGN,
    // event01: ()=>void,

    // setGraphicContext?: (options: T_GRAPHIC_CONTEXT)=>void,
    onButtonClick?: (event: string)=>void,
    // lang?: string;
}

    // const ACCORDION_DATA: T_FX_ACCORDION_DATA[] = [
    //     {
    //         summary: fxtr("fx-software-page-about-accordion-summary-01"),
    //         details: '• EXPERIENCE: 25+ years \
    //                   • MULTIPLE ROLES: engineer, software developer and entrepreneur \
    //                   • WIDE RANGE: niches like communications, management, real estate and science'
    //     },
    //     {
    //         summary: 'REAL full-stack',
    //         details: 'You want a SOLUTION, not parts of it. \
    //                     Yes, knowing frontend and backend software is key.\
    //                     But far beyond that, the architecture matters. \
    //                     The parts should integrate with each other smoothly.'
    //     },
    //     {
    //         summary: 'Passion to learn!',
    //         details: "In the REAL world, it's nearly impossible to master all technologies needed for some projects.\
    //                     Well, what about learning fast? \
    //                     Previous experience and being eager to absorb the new do allow me to have that approach."
    //     },
    // ]

// COMPONENT DEFINITION -------------------------------------------------------
    const FxSoftwarePage = (props: ISoftwareProps) => {
        // console.log("FxSoftwarePage: entering: props => ", props);

        // CONSTANTS AND DEFAULTS ------------------------------------------------------------
            const elementBodyRef = React.useRef(null);
            const pageContext = usePageContext();
            const navigate = useNavigate();
            const pageHeight = pageContext.maxHeight;
            const ourRef = useRef(null);
            const currentPageName = useRef('welcome');
            const currentPageOpac = useRef(0.6);
            var graphicContext:T_GRAPHIC_CONTEXT = {
                name: 'software',
                bgImageLight: bgSoftwareImgBlack,
                bgOverlayLight: 'rgba(255,255,255,0.85)',
                bgImageDark: bgSoftwareImgWhite,
                bgOverlayDark: `rgba(0,0,0,${currentPageOpac.current})`,
            };





            // scroll
            // const containerRef = React.createRef<HTMLDivElement>();
            // const [bind, unbind] = useScrollSnap(
            //     containerRef,
            //     { snapDestinationY: "90%" },
            //     () => console.log("snapped")
            // );







        // ---------------------------------------------------------------------------


        // PROPERTIES PRE-PROCESSING ---------------------------------------
            // => INITS, DEFAULTS, VALIDATION AND SANITIZATION
            const id_: string=!!props.id?props.id:"fx-software-page";
            const debug_:boolean=props.debug?props.debug:false;
            // FX: usually the full content height. It's possible to make (not prefered way!) bottom padding adjustment;
            const height_:string|undefined = props.height?props.height:pageContext.maxHeight;
            const width_: string|number = !!props.width?props.width:'100%';
            const cssVAlign:T_CSS_VERTICAL_ALIGN = !!props.vAlign?fxGetCSSVerticalAlign(props.vAlign):'center';
            const cssHAlign:T_CSS_HORIZONTAL_ALIGN = !!props.hAlign?fxGetCSSHorizontalAlign(props.hAlign):'center';
        // ---------------------------------------------------------------------------

        // STATE VARIABLES -----------------------------------------------------------
            const [accordionData, setAccordionData] = useState<T_FX_ACCORDION_DATA[]>([]);
            const [snapScroll, setSnapScroll] = useState<Property.ScrollSnapType>('y mandatory');
        // ---------------------------------------------------------------------------

        // EVENTS CONTROL -----------------------------------------------------------
            useEffect(() => {
                // console.log('FxSoftwarePage: entering: useEffect: pageContext => ', pageContext);

                // FX: for page-dependent background control:
                // if (['portfolio'].includes(currentPageName.current)) {

                // }

                if (!!pageContext) {
                    // const graphicContext:T_GRAPHIC_CONTEXT = {
                    //     name: 'software',
                    //     bgImageLight: bgSoftwareImgBlack,
                    //     bgOverlayLight: 'rgba(255,255,255,0.85)',
                    //     bgImageDark: bgSoftwareImgWhite,
                    //     // bgOverlayDark: 'rgba(0,0,0,0.6)',
                    //     bgOverlayDark: `rgba(0,0,0,${currentPageOpac.current})`,
                    // }
                    pageContext.changeGraphicContext(graphicContext);
                }
            // }, [pageContext]);
            // }, [graphicContext]);
            }, [pageContext, graphicContext]);

            // useEffect(() => {
                //     var nystories = document!.querySelector("p")!.offsetTop;

                //     window.onscroll = function() {
                //         if (window.pageYOffset > 0) {
                //             var opac = (window.pageYOffset / nystories);
                //             console.log(opac);
                //             document.body.style.background = "linear-gradient(rgba(255, 255, 255, " + opac + "), rgba(255, 255, 255, " + opac + ")), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/times-square-perspective.jpg) no-repeat";
                //         }
                //     }
                // }, []);

            // Background opacity/fade by scroll
            // useEffect(() => {

            //     // console.log(`Está na viewport:`, isInViewport(ourRef.current));
            //     // console.log(`Está na viewport:`, checkVisible(ourRef.current));
                
            //     const handleScrollEnd = (e:any)=>{
            //         const welcomePageHeight = document.getElementById("welcome-container")!.clientHeight; // 711
            //         // console.log(`welcomePageHeight => `, welcomePageHeight);
            //         const aboutPageHeight = document.getElementById("about-container")!.clientHeight; // 711
            //         // console.log(`aboutPageHeight => `, aboutPageHeight);
            //         const portfolioPageHeight = document.getElementById("portfolio-container")!.clientHeight; // 1887
            //         // console.log(`portfolioPageHeight => `, portfolioPageHeight);
            //         if (e.target.scrollTop<=welcomePageHeight*.9) console.log(`page = welcome`)
            //         else if (e.target.scrollTop<=(aboutPageHeight+welcomePageHeight)*.9) console.log(`page = about`)
            //         else if (e.target.scrollTop<=(aboutPageHeight+welcomePageHeight+portfolioPageHeight)*.9) {
            //             console.log(`page = portfolio`);

            //             const maxScrollTop = 2598;
            //             const scrollPercent = (e.target.scrollTop/maxScrollTop)>1?1:e.target.scrollTop/maxScrollTop;
            //             currentPageOpac.current = scrollPercent;
            //             // FX: Check min/max opacity from component init 
            //             const minOpac = currentPageOpac.current;
            //             const maxOpac = 1;
            //             // if ((scrollPercent>minOpac)&&(scrollPercent<maxOpac))
            //                 graphicContext.bgOverlayDark = `rgba(0,0,0,${scrollPercent})`;

            //             if (!!pageContext) {
            //                 pageContext.changeGraphicContext(graphicContext);
            //             }
            //         }
            //     };

            //     // @ts-ignore
            //     ourRef.current.addEventListener("scrollend", handleScrollEnd);
            //     return () => {
            //         // @ts-ignore
            //         ourRef.current.removeEventListener("scrollend", handleScrollEnd);
            //     }
            // }, []);
            useLayoutEffect(() => {

                // console.log(`Está na viewport:`, isInViewport(ourRef.current));
                // console.log(`Está na viewport:`, checkVisible(ourRef.current));
                
                const handleScrollEnd = (e:any)=>{
                    // console.log(` => ${snapScroll}`);
                    // if (snapScroll == 'none') setSnapScroll('y mandatory');

                    // console.log(e);
                    const welcomePageHeight = document.getElementById("welcome-container")!.clientHeight; // 711
                    // console.log(`welcomePageHeight => `, welcomePageHeight);
                    const aboutPageHeight = document.getElementById("about-container")!.clientHeight; // 711
                    // console.log(`aboutPageHeight => `, aboutPageHeight);
                    const portfolioPageHeight = document.getElementById("portfolio-container")!.clientHeight; // 1887
                    // console.log(`portfolioPageHeight => `, portfolioPageHeight);
                    // console.log(`e.target.scrollTop => ${e.target.scrollTop}`);
                    if (e.target.scrollTop<=welcomePageHeight*.9) {
                        // console.log(`page = welcome`);
                    }
                    else if (e.target.scrollTop<=(aboutPageHeight+welcomePageHeight)*.9){
                        // console.log(`page = about`);
                    }
                    else if (e.target.scrollTop<=(aboutPageHeight+welcomePageHeight+portfolioPageHeight)*.9) {
                        console.log(`page = portfolio`);


                    // FX: CHUNCHO: religar snap scroll desligado dentro de FxAccordion
                    setSnapScroll('y mandatory');



                        const maxScrollTop = 2598;
                        const scrollPercent = (e.target.scrollTop/maxScrollTop)>1?1:e.target.scrollTop/maxScrollTop;
                        currentPageOpac.current = scrollPercent;
                        // FX: Check min/max opacity from component init 
                        const minOpac = currentPageOpac.current;
                        const maxOpac = 1;
                        // if ((scrollPercent>minOpac)&&(scrollPercent<maxOpac))
                            graphicContext.bgOverlayDark = `rgba(0,0,0,${scrollPercent})`;

                        if (!!pageContext) {
                            pageContext.changeGraphicContext(graphicContext);
                        }
                    }
                };

                // @ts-ignore
                ourRef.current.addEventListener("scroll", handleScrollEnd);
                return () => {
                    // @ts-ignore
                    ourRef.current.removeEventListener("scroll", handleScrollEnd);
                }
            }, []);


            // FX: translations
            useEffect(() => {
                const getAccordionData = ()=>{
                    const x = [
                        {
                            summary: fxtr("fx-software-page-about-accordion-summary-01"),
                            // summary: 'sfbsgbsrgb',
                            details: str2html(fxtr("fx-software-page-about-accordion-details-01")),
                            // details: '• EXPERIENCE: 25+ years \
                            //         • MULTIPLE ROLES: engineer, software developer and entrepreneur \
                            //         • WIDE RANGE: niches like communications, management, real estate and science'
                        },
                        {
                            summary: fxtr("fx-software-page-about-accordion-summary-02"),
                            details: str2html(fxtr("fx-software-page-about-accordion-details-02")),
                        },
                        {
                            summary: fxtr("fx-software-page-about-accordion-summary-03"),
                            details: str2html(fxtr("fx-software-page-about-accordion-details-03")),
                        },
                    ];

                    // console.log(x);

                    return x;
                }
                setAccordionData(getAccordionData());
                // }); // FX: always get translations
                // }, [props.lang]);
            }, [pageContext.lang]);


        // ---------------------------------------------------------------------------

        // CONTROL FUNCTIONS ---------------------------------------------------------
            const onPortfolioClick = (e:any)=>{
                // console.log("Portfolio!!");
                navigate('portfolio');
                // if (!!props.onButtonClick) props.onButtonClick('portfolio');
            }

            // const onScroll_TEMP = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
            const onScroll_TEMP = (e: any) => {
                // console.log(`onScroll_TEMP: e => `, e);
            
                // console.log(`onScroll_TEMP: e.target.scrollTop => `, e.target.scrollTop);       // 1 => 2598
                // console.log(`onScroll_TEMP: e.target.scrollHeight => `, e.target.scrollHeight); // 3354

                // console.log(`onScroll_TEMP: e.target.offsetHeight => `, e.target.offsetHeight); // 711
                // console.log(`onScroll_TEMP: e.target.offsetTop => `, e.target.offsetTop);       // 95

                // console.log(`onScroll_TEMP: e.target.clientHeight => `, e.target.clientHeight); // 711
                // console.log(`onScroll_TEMP: e.target.clientTop => `, e.target.clientTop);       // 0

                // console.log(`Está na viewport: ${e.target.id}: `, checkVisible(e.target));

                // 40vh means 40 % of viewport height. So window.innerHeight * 0.4 == 40vh
                // const welcomePageHeight = document.getElementById("welcome-container")!.clientHeight; // 711
                // // console.log(`welcomePageHeight => `, welcomePageHeight);
                // const aboutPageHeight = document.getElementById("about-container")!.clientHeight; // 711
                // // console.log(`aboutPageHeight => `, aboutPageHeight);
                // const portfolioPageHeight = document.getElementById("portfolio-container")!.clientHeight; // 1887
                // // console.log(`portfolioPageHeight => `, portfolioPageHeight);
                // if (e.target.scrollTop<=welcomePageHeight*.9) console.log(`page = welcome`)
                // else if (e.target.scrollTop<=(aboutPageHeight+welcomePageHeight)*.9) console.log(`page = about`)
                // else if (e.target.scrollTop<=(aboutPageHeight+welcomePageHeight+portfolioPageHeight)*.9) console.log(`page = portfolio`)







                // const maxScrollTop = 2598;
                // const scrollPercent = (e.target.scrollTop/maxScrollTop)>1?1:e.target.scrollTop/maxScrollTop;
                // currentPageOpac.current = scrollPercent;
                // // FX: Check min/max opacity from component init 
                // const minOpac = currentPageOpac.current;
                // const maxOpac = 1;
                // if ((scrollPercent>minOpac)&&(scrollPercent<maxOpac))
                //     graphicContext.bgOverlayDark = `rgba(0,0,0,${scrollPercent})`;

                // if (!!pageContext) {
                //     pageContext.changeGraphicContext(graphicContext);
                // }




            };
        // ---------------------------------------------------------------------------

        // SUB COMPONENTS ---------------------------------------------------------
            // const portfolioButtons = 
            //     <div id="fx-software-buttons"
            //         className="wrapper"
            //         style={{
            //             position: 'absolute',
            //             perspective: '200px',

            //             width: '70%', // estes dois trabalham juntos
            //             left: '30%',

            //             height: '45%', // problema overflow
            //             top: '55%',
            //             // background: 'red',
            //         }}
            //     >
            //         <FxCanvas 
            //             // vAlign='center'
            //             // hAlign='center'
            //             // height='80%' 
            //             width='100%' 
            //             debug={debug_} 
            //             // bgImage='./assets/images/code02.png'
            //         >
            //             <FxButton id='button-portfolio'
            //                 debug={debug_} 
            //                 perspective='45px' 
            //                 rotateX='0deg'
            //                 rotateY='-25deg'
            //                 rotateZ='-6deg'
            //                 skewX='-6deg'
            //                 width='40px' 
            //                 height='20px' 
            //                 x={42} 
            //                 y={5} 
            //                 img={portfolioButtonImg}
            //                 onClick={onPortfolioClick}
            //             />

            //             <FxButton id='button-process'
            //                 debug={debug_} 
            //                 perspective='45px' 
            //                 rotateX='0deg'
            //                 rotateY='-25deg'
            //                 rotateZ='0deg'
            //                 skewX='0deg'
            //                 width='40px' 
            //                 height='20px' 
            //                 x={42} 
            //                 y={28} 
            //                 img={portfolioButtonImg}
            //                 onClick={onPortfolioClick}
            //             />

            //         </FxCanvas>

            //     </div>



    // Function to check target element's position
    const checkTargetPosition = (element: Element) => {
        var viewportHeight = window.innerHeight;
        // get bounding client rect from element
        var rect = element.getBoundingClientRect();
        // grab measurements and percentage conversion
        var fromTop = rect.top;
        var fraction = rect.top/viewportHeight;
        var percentage = fraction * 100;
        console.log('target scroll:', fromTop, 'px from top.', fraction, '/', percentage, '%');
    }

    //     // Listen for scroll event and check position
    //     window.addEventListener('scroll', () => {
    //     checkTargetPosition();
    // });

    const containerStyles:any = {
        // FxComponent defaults
        display:        "flex", // if "block", justifyContent will not work!! 
        background:     debug_?'blue':'initial', // apenas para debugging
        border:         debug_?'dashed red 2px':'initial',
        // height:         height_;
        width:          width_, 
        // justifyContent: cssHAlign,               // horizontal align
        // alignItems:     cssVAlign,                   // vertical align

        // FX: usually the full content height. It's possible to make (not prefered way!) bottom padding adjustment;
        height:         pageHeight,
        // height:         '80vh',
        overflowY: 'scroll',
        position: 'relative',   // there are absolutely positioned elements, but they should flow at this level

        // Page premisse: padding bottom:
        // paddingBottom: '4vh',
        // marginBottom: '4vh',


        // scrollSnapType: 'block mandatory',
        // scrollSnapType: 'y mandatory',
        scrollSnapType: snapScroll,
        // scrollSnapType: 'y proximity',
        // scrollSnapType: 'block proximity',
        // scrollSnapAlign: 'center',
        // scrollSnapStop: 'normal',
    }

        // RENDER --------------------------------------------------------------------
            return (
                <div id={id_} 
                    ref={ourRef}
                    onScroll={onScroll_TEMP}
                    style={containerStyles}
                >

                    {/*  
                        LAYOUT: two items: text / image
                        Display alignment:
                        => small/medium: vertical;
                        => large: horizontal
                    */}
                    <Grid id="software-container"
                        container direction='row'
                        // scroll
                        // ref={containerRef}
                    >
                        <Grid id="welcome-container"
                            item xs={12} sm={12} md={12} lg={12}
                            container direction='row'
                            style={{
                                minHeight: pageHeight,
                                paddingTop: '8vh',
                                scrollSnapAlign: 'start',
                                display: 'block',
                            }}
                        >

                            {/* <div
                                style={{
                                    // minWidth:'100%',
                                    paddingBottom: '5vh',
                                }}
                            > */}
                                {/* FX: str2html inclui um <div>. Typography é um <p>. Nao rola <div> dentro de <p>. */}
                                {/* <Typography id="welcome-title"
                                    fontSize={30}
                                    // textAlign='right'
                                    // fontWeight={}
                                    style={{
                                        paddingBottom: '5vh',
                                        width: '100%',
                                    }}
                                > */}
                                <div 
                                    style={{
                                        paddingBottom: '5vh',
                                        width: '100%',
                                        fontSize: 30,
                                    }}
                                >
                                    {str2html(fxtr("fx-software-page-title"))}
                                </div>
                                {/* </Typography> */}
                            {/* </div> */}





                            {/* <Typography
                                fontSize={20}
                                // paragraph
                                style={{
                                    paddingBottom: '5vh',
                                    width: '100%',
                                }}
                            > */}

                                <div 
                                    style={{
                                        paddingBottom: '2vh',
                                        width: '100%',
                                        // fontSize: 20,
                                    }}
                                >
                                    {str2html(fxtr("fx-software-page-text"))}
                                </div>


                            {/* </Typography> */}





                            {/* <Typography
                                fontSize={20}
                                // paragraph
                                style={{
                                    width: '100%',
                                }}
                            > */}

                                <div 
                                    style={{
                                        // paddingBottom: '5vh',
                                        width: '100%',
                                        fontSize: 20,
                                    }}
                                >
                                    {str2html(fxtr("fx-software-page-text-cta"))}
                                    {/* Aqui na FX, o desafios são a causa e as soluções são o efeito. */}

                                </div>

                            {/* </Typography> */}
                        </Grid>


                        <Grid id="about-container"
                            container direction='row'
                            item xs={12} sm={12} md={12} lg={12}
                            columnSpacing={10}
                            style={{
                                // minHeight: pageHeight,
                                minHeight: '-webkit-fill-available',
                                // paddingTop: '4vh',
                                paddingBottom: '4vh',

                                scrollSnapAlign: 'start',

                                alignItems: 'flex-start'
                            }}
                        >

                            <Grid id="about-title"
                                item xs={12} sm={12} md={12} lg={12}
                            >
                                <Typography //id="about-title"
                                    fontSize={30}
                                    paragraph
                                    // textAlign='right'
                                    // fontWeight={}
                                    style={{
                                        // paddingBottom: '5vh',
                                        display: 'block'        // FX: occupy whole width
                                    }}
                                >
                                    <strong>{fxtr("fx-software-page-about-title")}</strong>
                                </Typography>
                            </Grid>


                                    {/* <Typography id="about-subtitle"
                                        fontSize={20}
                                        // textAlign='right'
                                        // fontWeight={}
                                        paragraph
                                        style={{
                                            paddingBottom: '2vh',
                                            display: 'block'        // FX: occupy whole width
                                        }}
                                    > */}
                                    {/* <div  */}
                                    <Grid id="about-subtitle"
                                        item xs={12} sm={12} md={12} lg={12}
                                    // >
                                        // id="about-text-01"
                                        style={{
                                            paddingBottom: '2vh',
                                            width: '100%',
                                            fontSize: 20,
                                            display: 'block',
                                        }}
                                    >
                                        <strong>{str2html(fxtr("fx-software-page-about-subtitle"))}</strong>
                                    {/* </div> */}
                                    </Grid>
                                    {/* </Typography> */}


                            <Grid id="about-text-container-scrollable"
                                item container
                                xs={12} sm={12} md={12} lg={12}
                                style={{
                                    // height: '80vh',
                                    height: 'max-content',

                                    // overflowY: 'scroll',
                                    overflowY: 'auto', // FX: in case f long texts, to scroll inside sub-page
                                    display: 'inline-block',

                                    // paddingRight: '5vw',
                                    paddingBottom: '5vh', // FX: for mobile, there's some gadget in the bottom of screen that covers the content :-/
                                }}
                            >


                                <Grid id="about-text"
                                    item xs={12} sm={12} md={6} lg={6}
                                    style={{
                                        paddingRight: '5vw',
                                    }}
                                >


                                    {/* <Typography id="about-text-01"
                                        fontSize={15}
                                        // textAlign='right'
                                        // fontWeight={}
                                        // paragraph
                                        style={{
                                            // paddingBottom: '3vh',
                                            display: 'block'        // FX: occupy whole width
                                        }}
                                    > */}
                                    <div id="about-text-01"
                                        style={{
                                            paddingBottom: '2vh',
                                            width: '100%',
                                            fontSize: 15,
                                            display: 'block',
                                        }}
                                    >
                                        {/* {fxtr("fx-software-page-about-text01")} */}
                                        {str2html(fxtr("fx-software-page-about-text01_01"))}
                                    </div>
                                    {/* </Typography> */}

                                    {/* <Typography id="about-text-02"
                                        fontSize={15}
                                        // textAlign='right'
                                        // fontWeight={}
                                        // paragraph
                                        style={{
                                            // paddingBottom: '3vh',
                                            display: 'block'        // FX: occupy whole width
                                        }}
                                    > */}
                                    <div id="about-text-02"
                                        style={{
                                            paddingBottom: '2vh',
                                            width: '100%',
                                            fontSize: 15,
                                            display: 'block',
                                        }}
                                    >
                                        {/* {fxtr("fx-software-page-about-text02")} */}
                                        {str2html(fxtr("fx-software-page-about-text02_01"))}
                                    </div>
                                    {/* </Typography> */}

                                    <div id="about-text-03"
                                        style={{
                                            paddingBottom: '2vh',
                                            width: '100%',
                                            fontSize: 15,
                                            display: 'block',
                                        }}
                                    >
                                        {/* {fxtr("fx-software-page-about-text02")} */}
                                        {str2html(fxtr("fx-software-page-about-text02_02"))}
                                    </div>
                                </Grid>


                                <Grid id="about-accordion"
                                    // container direction='row'
                                    item xs={12} sm={12} md={6} lg={6}
                                    style={{
                                        // paddingTop: '2vh',
                                        marginTop: '4vh',   // FX: TODO, acertar fluidez entre texto e accordion..
                                        // height: '20vh',
                                        // scrollSnapAlign: 'start',
                                    }}
                                >
                                    <FxAccordion 
                                        data={accordionData}
                                        vAlign='top'
                                        // ref={ourRef}
                                        // setSnapScroll={setSnapScroll}
                                        snapScrollActive={(active:boolean)=>active?setSnapScroll('y mandatory'):setSnapScroll('none')}
                                    ></FxAccordion>

                                    {/* <Button id='myTestButton'
                                        onClick={()=>{
                                            // 
                                                const swPageEl = document.querySelector(`#${id_}`);
                                                // const swPageClientTop = swPageEl?.clientTop;
                                                const swPageScrollTop = swPageEl?.scrollTop;
                                                // const swPageScrollHeight = swPageEl?.scrollHeight;
                                                // const swPageBoundingClientRect = swPageEl?.getBoundingClientRect(); // size of an element and its position relative to the viewport
                                                // const swPageClientRects = swPageEl?.getClientRects();
                                                // console.log(`swPageClientTop => ${swPageClientTop}`);
                                                // console.log(`swPageScrollTop => ${swPageScrollTop}`);
                                                // console.log(`swPageBoundingClientRect =>`, swPageBoundingClientRect);
                                                // console.log(`swPageClientRects =>`, swPageClientRects);
                                                // console.log(`swPageScrollHeight =>`, swPageScrollHeight);

                                                // const accordionEl = document.querySelector(`#about-accordion`);
                                                // const accordionClientTop = accordionEl?.clientTop;
                                                // const accordionScrollTop = accordionEl?.scrollTop;
                                                // const accordionScrollHeight = accordionEl?.scrollHeight;
                                                // const accordionBoundingClientRect = accordionEl?.getBoundingClientRect(); // size of an element and its position relative to the viewport
                                                // const accordionClientRects = accordionEl?.getClientRects();
                                                // console.log(`accordionClientTop => ${accordionClientTop}`);
                                                // console.log(`accordionScrollTop => ${accordionScrollTop}`);
                                                // console.log(`accordionBoundingClientRect =>`, accordionBoundingClientRect);
                                                // console.log(`accordionClientRects =>`, accordionClientRects);
                                                // console.log(`accordionScrollHeight =>`, accordionScrollHeight);

                                                // const accordionParentEl = accordionEl?.parentElement;
                                                // const accordionParentScrollTop = accordionParentEl?.scrollTop;
                                                // console.log(`accordionParentScrollTop => ${accordionParentScrollTop}`);


                                            const panel0El = document.querySelector(`#panel0-details`);
                                            const panel0Height = panel0El?.getClientRects()[0].height;
                                            console.log(`panel0Height => ${panel0Height}`);
                                            const panel1El = document.querySelector(`#panel1-details`);
                                            const panel1Height = panel1El?.getClientRects()[0].height;
                                            console.log(`panel1Height => ${panel1Height}`);
                                            const panel2El = document.querySelector(`#panel2-details`);
                                            const panel2Height = panel2El?.getClientRects()[0].height;
                                            console.log(`panel2Height => ${panel2Height}`);

                                            // Button
                                                // const buttonEl = document.querySelector(`#myTestButton`);
                                                // const buttonClientTop = buttonEl?.clientTop;
                                                // const buttonScrollTop = buttonEl?.scrollTop;
                                                // const buttonScrollHeight = panelEl?.scrollHeight;
                                                // const buttonBoundingClientRect = buttonEl?.getBoundingClientRect(); // size of an element and its position relative to the viewport
                                                // // const buttonAttr = buttonEl?.attributes;
                                                // // const buttonComputedStyleMap = buttonEl?.computedStyleMap()
                                                // const buttonClientRects = buttonEl?.getClientRects();

                                                // console.log(`buttonClientTop => ${buttonClientTop}`);
                                                // console.log(`buttonScrollTop => ${buttonScrollTop}`);
                                                // console.log(`buttonScrollHeight =>`, buttonScrollHeight);
                                                // console.log(`buttonBoundingClientRect =>`, buttonBoundingClientRect);
                                                // // console.log(`buttonAttr =>`, buttonAttr);
                                                // // console.log(`buttonComputedStyleMap => ${buttonComputedStyleMap}`);
                                                // console.log(`buttonClientRects =>`, buttonClientRects);

                                            if ((!!swPageScrollTop)&&(!!panel2Height)) swPageEl?.scrollTo(0,swPageScrollTop-panel2Height);

                                            // if (!!accordionEl) checkTargetPosition(accordionEl);

                                        }}
                                    >
                                        TEST
                                    </Button> */}
                                </Grid>

                            </Grid>


                        </Grid>

                        <Grid id="portfolio-container" 
                            // container direction='row'
                            item xs={12} sm={12} md={12} lg={12}
                            style={{
                                scrollSnapAlign: 'start',

                                marginBottom: '40vh',
                                paddingBottom: '4vh',

                                // FX: to set specific area background
                                // background: "linear-gradient(rgba(255, 255, 255, " + opac + "), rgba(255, 255, 255, " + opac + ")), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/times-square-perspective.jpg) no-repeat";
                                // background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))",
                            }}
                        >
                            <Typography //id="about-title"
                                fontSize={30}
                                paragraph
                                // textAlign='right'
                                // fontWeight={}
                                style={{
                                    paddingBottom: '5vh',
                                    display: 'block'        // FX: occupy whole width
                                }}
                            >
                                <strong>PORTFOLIO</strong>
                            </Typography>

                            <div
                                style={{
                                    scrollSnapType: 'none!important',
                                    paddingBottom: '10vh',
                                }}
                            >
                                <FxPortfolioPage/>
                            </div>
                        </Grid>

                    </Grid>
                </div>
            );
        // ---------------------------------------------------------------------------
    };

export default FxSoftwarePage;

                            // <CSSTransition 
                            //     // in={bodyTransition}
                            //     timeout={2000} 
                            //     classNames="body"
                            //     nodeRef={elementBodyRef}
                            //     // onEnter={headerOnEnter}
                            //     // onExit={headerOnExit}
                            //     // onEntering={headerOnEntering}
                            //     // onExiting={headerOnExiting}
                            // >
                            //     <div
                            //         id='software-container-body'
                            //         style={{
                            //             display: 'flex',
                            //             height: '100%',
                            //             width: '100%',
                            //             background:     debug_?'grey':'initial', // apenas para debugging
                            //             justifyContent: 'center',
                            //             alignContent: 'center',
                            //         }}
                            //     >
                            //         <div
                            //             id='element-body'
                            //             ref={elementBodyRef}
                            //             style={{
                            //                 // display: 'flex',
                            //                 height: '100%',
                            //                 width: '100%',
                            //                 background:     debug_?'blue':'initial', // apenas para debugging
                            //                 justifyContent: 'center',
                            //                 alignContent: 'center',
                            //                 // contentVisibility: 'hidden', //https://developer.mozilla.org/en-US/docs/Web/CSS/content-visibility
                            //                 zIndex: 50,
                            //             }}
                            //         >


                            //             <div id="fx-software-text-01"
                            //                 className="wrapper"
                            //                 style={{
                            //                     // position: 'absolute',
                            //                     // perspective: '200px',
                            //                     // perspective: '400px',
                            //                     // width: '45%',
                            //                     width: '50%',
                            //                     // height: '50%',
                            //                     // background: 'red',
                            //                 }}
                            //             >
                            //                 <div className="inner"
                            //                     style={{
                            //                         position: 'relative',
                            //                         transition: '.3s',
                            //                         // transform: 'rotateY(-10deg)',
                            //                         width: '100%',
                            //                         height: '100%',
                            //                         // left: '15%', // used together with perpective
                            //                         // padding: '10%',
                            //                         textAlign: 'end',
                            //                         // background: 'rgba(186, 186, 186, 0.86)',
                            //                         // backgroundImage: 'url(./assets/images/portfolio02.svg)',
                            //                         // backgroundRepeat: 'no-repeat',
                            //                         // backgroundSize: 'cover',
                            //                     }}
                            //                 >
                            //                     <FxGrid
                            //                         height='100%'
                            //                     >
                            //                         <FxColumn>


                            //                             <FxRow
                            //                                 id="software-title-row"
                            //                                 debug={debug_}
                            //                                 item
                            //                                 proportion={20}
                            //                                 hAlign='right'
                            //                             >
                            //                                 {title}
                            //                             </FxRow>

                            //                             <FxRow
                            //                                 id="software-text-row-1"
                            //                                 debug={debug_}
                            //                                 item
                            //                                 proportion={70}
                            //                             >
                            //                                 {text}
                            //                             </FxRow>


                            //                         </FxColumn>
                            //                     </FxGrid>
                            //                 </div>
                            //             </div>

                            //             {/* { portfolioButtons } */}


                            //         </div>
                            //     </div>
                            // </CSSTransition>
